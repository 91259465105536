import { useCallback, useEffect, useState } from "react";
import {
  BoardPage,
  PageCanvas,
  useKeysCombinationHandler,
} from "@beontag/tageditor";
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Printer as PrinterIcon } from "react-bootstrap-icons";

import CloseIcon from "@mui/icons-material/Close";
import { useEditorContext } from "../../../../../context/editorContext/EditorContext";
import { useMessage } from "../../../../../context/alertContext/context";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";

const Print = () => {
  const [boardPage, setBoardPage] = useState<BoardPage>();
  const [boardPagePrint, setBoardPagePrint] = useState<BoardPage>();

  const { numberOfPages, produtoSelecionado } = useEditorContext();
  const { showAlertApp } = useMessage();
  const { t } = useTranslation("editor");
  const print = useCallback(() => {
    showAlertApp(t("Gerando arquivo..."), "info");
    setTimeout(() => {
      if (boardPagePrint) {
        boardPagePrint.render();
        boardPagePrint.readonly = true;
        boardPagePrint.toPdf({ filename: "page" }).then(() => {
          showAlertApp(t("Arquivo gerado"), "success", 5000);
        });
      }
    }, 500);
  }, [boardPagePrint]);

  const printAll = useCallback(() => {
    const pages = Array.from({ length: numberOfPages }, (v, k) => k + 1);

    showAlertApp(t("Gerando arquivo..."), "info");
    setTimeout(() => {
      if (boardPagePrint) {
        boardPagePrint.render();
        boardPagePrint.readonly = true;
        boardPagePrint.toPdf({ filename: "page", pages: pages }).then(() => {
          showAlertApp(t("Arquivo gerado"), "success", 5000);
        });
      }
    }, 500);
  }, [boardPagePrint]);

  useEffect(() => {
    if (boardPage) {
      boardPage.render();
      // Colocamos o modal em readonly para fecharmos a impressão
      setTimeout(() => {
        if (openModal) {
          boardPage.readonly = true;
        }
      }, 500);
    }
  }, [boardPage, boardPage?.readonly]);

  const ctrlPCount = useKeysCombinationHandler(["Control", "p"]);
  useEffect(() => {
    if (ctrlPCount) {
      print();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctrlPCount]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openTable, setOpenTable] = useState<boolean>(false);

  useEffect(() => {
    if (boardPage && !openModal) {
      boardPage.readonly = false;
    }
  }, [openModal]);

  return (
    <>
      <Button
        className="btn btn-header-editor"
        startIcon={<PrinterIcon size={28} />}
        sx={{ flexDirection: "column" }}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {t("Imprimir")}
      </Button>
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        scroll="paper"
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle>
          <IconButton onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <h1 className="editor-login-authentication-text">{t("Prévia")}</h1>

          {produtoSelecionado?.familia == "A5" && (
            <>
              <Alert severity="warning" variant="filled">
                {t(
                  "Antes de imprimir, confira as instruções da sua impressora para folhas A5"
                )}
              </Alert>

              <br />
            </>
          )}

          <div className="canvas-preview">
            {/* Temos dois canvas: Um para a prévia da etiqueta gerada, e a segunda para a que geramos o PDF de impressão */}
            <PageCanvas
              featureUpdate={(f) => setBoardPage(f)}
              showEdges={true}
              readonly
            />

            <PageCanvas
              featureUpdate={(f) => setBoardPagePrint(f)}
              showEdges={false}
              renderless
              readonly
            />
          </div>

          <br />

          <div className="dados-etiqueta-print-modal">
            {" "}
            {t("Dados da etiqueta")}&nbsp;{" "}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenTable(!openTable)}
            >
              {openTable ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>

          <Collapse in={openTable} timeout="auto" unmountOnExit>
            <TableContainer>
              <Table className="categoria-produtos" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" width={"50%"}>
                      {t("Dado")}
                    </TableCell>
                    <TableCell align="center" width={"50%"}>
                      {t("Valor (mm)")}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("Altura da etiqueta")}
                    </TableCell>
                    <TableCell align="center">
                      {produtoSelecionado?.alturaEtiqueta}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("Largura da etiqueta")}
                    </TableCell>
                    <TableCell align="center">
                      {produtoSelecionado?.larguraEtiqueta}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("Margem inferior etiqueta")}
                    </TableCell>
                    <TableCell align="center">
                      {produtoSelecionado?.margemInferiorEtiqueta}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("Margem direita etiqueta")}
                    </TableCell>
                    <TableCell align="center">
                      {produtoSelecionado?.margemDireitaEtiqueta}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("Tipo folha")}
                    </TableCell>
                    <TableCell align="center">
                      {produtoSelecionado?.familia}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("Altura página")}
                    </TableCell>
                    <TableCell align="center">
                      {produtoSelecionado?.alturaPagina}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("Largura página")}
                    </TableCell>
                    <TableCell align="center">
                      {produtoSelecionado?.larguraPagina}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("Margem esquerda pagina")}
                    </TableCell>
                    <TableCell align="center">
                      {produtoSelecionado?.margemEsquerdaPagina}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {t("Margem superior pagina")}
                    </TableCell>
                    <TableCell align="center">
                      {produtoSelecionado?.margemSuperiorPagina}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>

          <br />
        </DialogContent>
        <DialogActions>
          <Button
            className="btn btn-print-page"
            startIcon={<PrinterIcon size={28} />}
            sx={{ flexDirection: "column" }}
            onClick={print}
          >
            {t("Imprimir atual")}
          </Button>
          <Button
            className="btn btn-print-page"
            startIcon={<PrinterIcon size={28} />}
            sx={{ flexDirection: "column" }}
            onClick={printAll}
          >
            {t("Imprimir todas")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Print;
