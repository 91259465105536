import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageButtonIcon } from "../common/icons";
import { Languages } from "../../i18n/i18nextOptions";

export default function LanguageSelector() {

	const { i18n } = useTranslation();
	const [lang, setLang] = useState<string>(i18n.language);

	const handleChangeLanguage = (language: Languages) => {
		setLang(language)
		i18n.changeLanguage(language)
	}

	return (
		<div className="search-language-switcher-container">
			<LanguageButtonIcon />
			{Object.values(Languages).map((language, count) => {
				return (
					<label key={language}>
						{count !== 0 ? '| ' : ''}
						<span
							className={`language-switcher-text ${language === lang ? 'active' : ''}`}
							onClick={() => handleChangeLanguage(language)}>
							{language}
							{/* TODO: Remoção de nobreaking space */}
							&nbsp;
						</span>
					</label>
				);
			})}

		</div>
	)
}
