import { Grid, InputAdornment, InputLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import { SearchButtonIcon } from "../../../../components/common/icons";
import EditorMenuContainer from "../editorMenuContainer";

import { useEditorContext } from "../../../../context/editorContext/EditorContext";
import { ITema } from "../../../../interfaces/editor/Tema";
import { ITemplate } from "../../../../interfaces/editor/Templates";

import { useBoardContext } from "@beontag/tageditor";

import ModalConfirmDelete from "./modalConfirmDelete";
import { useUserContext } from "../../../../context/userContext/userContext";
import DadosProdutoSelecionadoEditor from "./dadosProdutoSelecionadoEditor";
import GridTemplatesEditor from "./gridTemplatesEditor";
import AccordionTemasTemplateEditor from "./accordionTemasTemplateEditor";
import { Roles } from "../../../../interfaces/editor/rolesEnum";
import { useMessage } from "../../../../context/alertContext/context";
import { useTranslation } from "react-i18next";

function TemplateSelection(props: { handleSelectedTemplate: Function }) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { templates, produtoSelecionado, setTemplateEdicao, templateEdicao } =
    useEditorContext();
  const [temas, setTemas] = useState<ITema[]>(templates?.temas);
  const [templateList, setTemplateList] = useState<ITemplate[]>(
    templates?.templates
  );
  const [selectedCategory, setSelectedCategory] = useState<any>();

  const [openModalConfirmDelete, setOpenModalConfirmDelete] =
    useState<boolean>(false);

  const { isUserInRole } = useUserContext();
  const { showAlertApp } = useMessage();
  const { t } = useTranslation("editor");
  const userCanEditTemplate = isUserInRole(Roles.TemplateEditar);
  const userCanDeleteTemplate = isUserInRole(Roles.TemplateExcluir);

  const handleSelectCategory = (tema: ITema) => {
    setSelectedCategory(tema.idTema);
    const newList = templates?.templates.filter(
      (x: ITemplate) => x.idTema == tema.idTema
    );
    setTemplateList(newList);
  };

  const handleSetFilter = (e: any) => {
    setSearchTerm(e.target.value);
    if (e.target.value.length > 0) {
      setTemplateList(
        templates?.templates.filter((x: ITemplate) =>
          x.nomept.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    } else {
      setTemplateList(templates?.templates);
    }
  };

  const handleEditTemplate = (e: any, template: ITemplate) => {
    if (!userCanEditTemplate) {
      showAlertApp(
        t("Ops, parece que você não tem permissão para fazer isso"),
        "warning",
        5000
      );
    }
    // Evitamos a chamada do evento de seleção do template
    e.stopPropagation();
    setTemplateEdicao(template);

    props.handleSelectedTemplate(template.json);
  };

  const handleDelete = (e: any, template: ITemplate) => {
    // Evitamos a chamada do evento de seleção do template
    e.stopPropagation();
    setTemplateEdicao(template);
    setOpenModalConfirmDelete(true);
  };

  useEffect(() => {
    setTemplateList(templates?.templates);
  }, [templates.templates]);

  return (
    <>
      <EditorMenuContainer>
        <Grid container className="editor-menu-pesquisa-template">
          <Grid item xs={12}>
            <InputLabel className="produto-filter-input-label">
              {t("Pesquise por palavra-chave:")}
            </InputLabel>

            <TextField
              placeholder="Flores"
              type="text"
              fullWidth
              value={searchTerm}
              onChange={(e) => handleSetFilter(e)}
              variant="filled"
              className="search-field-faq"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchButtonIcon onClick={() => undefined} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </EditorMenuContainer>

      <div className="editor-menu-template-selection">
        <AccordionTemasTemplateEditor
          handleSelectCategory={handleSelectCategory}
          temas={templates?.temas}
          selectedCategory={selectedCategory}
        />

        <GridTemplatesEditor
          handleSelectedTemplate={props.handleSelectedTemplate}
          handleDelete={handleDelete}
          handleEditTemplate={handleEditTemplate}
          templateList={templateList}
          userCanEditOrDeleteTemplate={{
            userCanDeleteTemplate,
            userCanEditTemplate,
          }}
        />

        <DadosProdutoSelecionadoEditor
          produtoSelecionado={produtoSelecionado}
        />
      </div>
      {userCanDeleteTemplate && (
        <ModalConfirmDelete
          openModal={openModalConfirmDelete}
          setOpenModal={setOpenModalConfirmDelete}
          template={templateEdicao}
          userCanDeleteTemplate={userCanDeleteTemplate}
        />
      )}
    </>
  );
}

export default TemplateSelection;
