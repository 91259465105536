import EtiquetaSelection from "./etiquetaSelection";
import TagEditor from "./tagEditor";
import TemplateSelection from "./templateSelection";

function Steps(props: {
	setActiveStep: Function,
	activeStep: number,
	produtoSelecionado: string,
	handleProdutoSelecionado: Function,
	handleSelectTemplate: Function,
	setOpenModalMinhasEtiquetas: Function,
	setProdutoSelecionado: Function,
}) {

	return (
		<>
			{props.activeStep === 1 &&
				<EtiquetaSelection
					handleProdutoSelecionado={props.handleProdutoSelecionado}
					setActiveStep={props.setActiveStep}
					setProdutoSelecionado={props.setProdutoSelecionado}
					setOpenModalMinhasEtiquetas={props.setOpenModalMinhasEtiquetas}
				/>
			}

			{props.produtoSelecionado !== '' && props.activeStep === 2 &&
				<TemplateSelection
					handleSelectedTemplate={props.handleSelectTemplate}
				/>
			}
			
			{props.produtoSelecionado !== '' && props.activeStep === 3 &&
				<TagEditor
					setOpenModalMinhasEtiquetas={props.setOpenModalMinhasEtiquetas}
					setActiveStep={props.setActiveStep}
				/>
			}
		</>
	)
}


export default Steps;