import { Button } from "@mui/material";

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'

import chamada_assistente_busca from '../../assets/chamada_assistente_busca.webp';
import chamada_assistente_busca_mobile from '../../assets/chamada_assistente_busca_mobile.webp';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ConhecaAssistenteBuscaPimaco() {

    const theme = createTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

	const { t } = useTranslation('homepage')

    return (
        <section className="experimente-assistente-busca-container">
            <div className="experimente-assistente-busca-image-container">
                <img alt="" src={matches ? chamada_assistente_busca_mobile : chamada_assistente_busca} />

                <div className="custom-shape-divider-experimente-assistente-busca">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
            </div>
            
            <div className="experimente-asssistente-busca-text-container">

                <h1>{t("Dúvidas na escolha dos nossos produtos?")}</h1>
                <p>{t("Conte com uma experiência guiada e encontre o produto ideal para você.")}</p>

                <Link to="/ferramenta_busca" className="btn-link">
                    <Button
                        variant="outlined"
                        className="btn btn-outlined-primary"
                    >
                        {t("Experimentar assistente de busca")}
                    </Button>
                </Link>
                
                
            </div>

        </section>
    )
}