import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { IFaqCategory } from "../../interfaces/faqCategory";

export default function FaqCategoryChooser(props: { categories: IFaqCategory[], handleSelectCategory: Function, selectedCategory: number }) {

    const { t } = useTranslation('faq')

    return (
        <div className="faq-category-chooser-container">
            <Grid container className="grid-faq-category-chooser">
                {props.categories ? props.categories.map((item, index) => {
                    return (
                        <Grid item xs={6} key={item.tituloCategoria}>
                            <div
                                className={`faq-category-chooser-item-container ${props.selectedCategory == index ? 'active-faq-category-chooser-item' : ''}`}
                                onClick={() => props.handleSelectCategory(index)
                                }>

                                <h4>{t(item.tituloCategoria)}</h4>
                            </div>
                        </Grid>
                    )
                }) : ''}
            </Grid>


        </div>
    )
}