import { Check } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import { DropzoneState } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { UploadButtonIcon } from '../common/icons';
import { Trans, useTranslation } from 'react-i18next';

function EnviarCurriculo(props: {
        arquivoEstrutura: DropzoneState,
        fileName: string,
        errors: any,
        handleInputChange: (e: any) => void,
        handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
    }) {

    const { arquivoEstrutura, fileName, errors, handleInputChange, handleCheckboxChange } = props;

	const { t } = useTranslation('contato')

    return (
        <>
            <Grid item xs={12}>
                <h1 className="h1-form-title">{t("Currículo")}</h1>
                <h3 className="h3-form-subtitle">
                    {t("Faça o upload do seu currículo ou nos envie o link do seu perfil no LinkedIn.")}
                </h3>

                {/*Upload do currículo - Dropzone */}
                <div className={`dropzone-curriculo ${errors.curriculoLinkedIn ? 'dropzone-curriculo-erro' : ''}`}>

                    <div
                        {...arquivoEstrutura.getRootProps()}
                        className={
                            arquivoEstrutura.acceptedFiles.length > 0 ?
                            'dropzone-selected' :
                            'dropzone'
                        }
                    >

                        <input {...arquivoEstrutura.getInputProps()} />

                        {arquivoEstrutura.acceptedFiles.length > 0 ? <Check className="check" /> : <UploadButtonIcon />}

                        <div>{t("Arraste seu currículo ou clique aqui para procurar um arquivo.")}</div>

                        <div>{t("Máximo 10 mb - formato .docx, .doc, .pdf ou .odf")}</div>

                        {fileName !== "" ? (
                            <div>{fileName}</div>
                        ) : ''}

                    </div>
                </div>

                {/* Perfil do LinkedIn */}
                <TextField
                    fullWidth
                    id="linkedin"
                    type="text"
                    name="linkedin"
                    aria-label="Perfil do LinkedIn:"
                    variant="filled"
                    error={errors.curriculoLinkedIn}
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" disablePointerEvents>
                                <span className='form-label'>
                                    {t("Perfil do LinkedIn: ")}
                                </span>

                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            {/*Botões para aceitar os termos do site e política de privacidade*/}
            <div className='forms-accept-buttons'>
                <FormGroup>
                    <FormControlLabel
                        label={
                            <Trans t={t} i18nKey="Declaro que li e aceito os termos e condições.">
                                Declaro que li e aceito os termos e condições.
                            </Trans>
                        }
                        control={
                            <Checkbox
                                required
                                name='checkboxTermos'
                                onChange={handleCheckboxChange}
                                checkedIcon={<CloseIcon />}
                            />
                        }      
                    />
                </FormGroup>

                <FormGroup>
                    <FormControlLabel
                    label={
                        <Trans t={t} i18nKey="Declaro aceitar os termos de consentimento para tratamento dos dados.">
                            Declaro aceitar os termos de consentimento para tratamento dos dados.
                        </Trans>
                    }
                        control={
                            <Checkbox
                                required
                                name='checkboxTermos'
                                onChange={handleCheckboxChange}
                                checkedIcon={<CloseIcon />}
                            />
                        }
                    />
                </FormGroup>
            </div>

        </>
    )

}

export default EnviarCurriculo;