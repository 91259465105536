import React from 'react';
import logo from './logo.svg';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-accessible-accordion/dist/fancy-example.css';
import "react-multi-carousel/lib/styles.css";
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import './styles/main.min.css'

import createCache from '@emotion/cache';

import NotificationContextProvider from './context/alertContext/context';
import { CacheProvider } from '@emotion/react';
import Container from '@mui/material/Container';
import PimacoRoutes from './routes';

// Criamos um cache do emotion para que nossos estilos customizados do MUI sejam aplicados corretamente
const cache = createCache({
	key: 'css',
	prepend: true,
});

function App() {
	return (
		<CacheProvider value={cache}>

			<NotificationContextProvider>
				<Container maxWidth={false} disableGutters={true}>
					<PimacoRoutes />
				</Container>
			</NotificationContextProvider>

		</CacheProvider>
	);
}

export default App;
