import {
  AddCurvedTextFeature,
  AddCurvedTextFeatures,
  AddTextFeature,
  AddTextFeatures,
} from "@beontag/tageditor";
import { Button } from "@mui/material";
import { useState } from "react";
import TextFormatIcon from "@mui/icons-material/TextFormat";

import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { ArrowButtonIcon } from "../../../../../components/common/icons";
import { useTranslation } from "react-i18next";

export default function Teste2(props: { setMenuAnchorEl: Function }) {
  const [textFeature, setTextFeature] = useState<AddTextFeatures>();
  const [curvedTextFeature, setCurvedTextFeature] =
    useState<AddCurvedTextFeatures>();

  function onClick(e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    textFeature?.add();
    props.setMenuAnchorEl(e.target);
  }

  function onClickCurvedText(e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    curvedTextFeature?.add();
    props.setMenuAnchorEl(e.target);
  }
  const { t } = useTranslation("editor");
  return (
    <>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            {t("Texto")}
            <ArrowButtonIcon onClick={() => undefined} />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <AddTextFeature featureUpdate={(f) => setTextFeature(f)}>
            <div className="editor-tb-button-container">
              <Button
                className="btn btn-outlined-primary"
                startIcon={<TextFormatIcon />}
                variant="outlined"
                component="label"
                // @ts-ignore
                onClick={(e) => onClick(e)}
              >
                {" "}
                {t("Texto")}
              </Button>
            </div>
          </AddTextFeature>
          <AddCurvedTextFeature featureUpdate={(f) => setCurvedTextFeature(f)}>
            <div className="editor-tb-button-container">
              <Button
                className="btn btn-outlined-primary"
                startIcon={<TextFormatIcon />}
                variant="outlined"
                component="label"
                // @ts-ignore
                onClick={(e) => onClickCurvedText(e)}
              >
                {" "}
                {t("Texto curvo")}
              </Button>
            </div>
          </AddCurvedTextFeature>
        </AccordionItemPanel>
      </AccordionItem>
    </>
  );
}
