import { IProdutoFilterPimaco } from "../../../interfaces/produto/produtoFilterPimaco";
import IProdutoCard from '../../../interfaces/produto/produtoCardPimaco';
import { useTranslation } from 'react-i18next';

import { useEffect, useRef, useState } from "react";
import ProdutoFilterPimaco from "../../../components/produto/produtoFilter";
import ProdutoCard from "../../../components/produto/produto-card/produtoCard";

import sProduto from '../../../services/produtoService'
import ConhecaAssistenteBuscaPimaco from "../../../components/landing-page/conheca-assistente-busca-pimaco";
import IProdutoCardPimaco from "../../../interfaces/produto/produtoCardPimaco";
import ProdutoList from "../../../components/produto/produto-list";

import { cores } from '../../../components/produto/produtoFilter'
import { useSearchParams } from "react-router-dom";

export default function PesquisaPimaco() {

	const { i18n } = useTranslation();

	const [searchParams, setSearchParams] = useSearchParams();
	const [categoria, setCategoria] = useState<string>('');
	const [produtos, setProdutos] = useState<IProdutoCard[]>([]);
	const [destaque, setDestaque] = useState<string>('');
	const [filter, setFilter] = useState<{[key: string]: string}>({});
	const [searchTerm, setSearchTerm] = useState<string>('');
	// const [filteredCores, setFilteredCores] = useState<{ value: string, label: string}[]>([])

	const [pesquisa, setPesquisa] = useState<string>(searchTerm)


	useEffect(() => {

		const searchTerm = searchParams.get('pesquisa');
		const categoria = searchParams.get('categoria');
		const destaque = searchParams.get('destaque');
		let locale = i18n.language;

		let coresDB: string[] = []
		const filters = Object.fromEntries(searchParams)

		if (searchTerm) {
			setSearchTerm(searchTerm)

			sProduto.pesquisaProduto(locale, searchTerm, filters).then((resp) => {
				setProdutos(resp.data.produtos)
				coresDB = resp.data.cores;

			}).catch((err) => {

			})

		} else if (destaque) {
			setDestaque(destaque)

			sProduto.destaqueProduto(locale, destaque, filters).then((resp) => {
				setProdutos(resp.data.produtos)
				coresDB = resp.data.cores;

			}).catch((err) => {

			})

		} else {
			sProduto.assistenteBusca(locale, filters).then((resp) => {
				setProdutos(resp.data.produtos)
				coresDB = resp.data.cores;
			}).catch((err) => {
				console.error(err)
			})
		}

		// setFilteredCores(cores.filter(x => coresDB.includes(x.value)))
		setFilter(filters)
		categoria && setCategoria(categoria)
	}, [])

	const initialFilterBuilder = () => {

		const dados = filter;
		if (!("forma" in dados)) {
			Object.assign(dados, {
				codigo: "",
				forma: "",
				largura: "",
				altura: "",
				cor: "",
				pesquisa: "",
			})
		}
		return dados
	}

	const [dadosFiltro, setDadosFiltro] = useState<{ [key: string]: string }>(initialFilterBuilder())

	const clearFiltroValue = (filter: string) => {
		setDadosFiltro({ ...dadosFiltro, [filter]: '' })
	}

	const { t } = useTranslation('categoria')

	const handleFiltroChange = (e: any) => {
		setDadosFiltro({ ...dadosFiltro, [e.target.name]: e.target.value });
	}

	// useEffect(() => {
	// 	setIsLoading(transition[0] !== 'idle')

	// 	if (transition.state == 'idle') {
	// 		window.scroll({
	// 			top: top,
	// 			left: 0,
	// 			behavior: 'smooth'

	// 		})
	// 	}
	// }, [transition])

	return (
		<>
			<div className='page-content'>

				{!destaque &&
					<ProdutoFilterPimaco
						dados={dadosFiltro}
						handleChange={handleFiltroChange}
						categoria={categoria}
						clearFiltroValue={clearFiltroValue}
						cores={cores}
					>
						<>
							{searchTerm &&
								<input type="hidden" value={pesquisa} name="pesquisa" />
							}
						</>

					</ProdutoFilterPimaco>
				}

				<ProdutoList produtos={produtos} />

			</div>
			<ConhecaAssistenteBuscaPimaco />
		</>
	)
}