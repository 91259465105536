import React, { useCallback, useState } from "react";
import { useBoardContext } from "@beontag/tageditor";
import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import {
  FileEarmark,
  CloudDownload,
  FilePlus,
  XOctagon,
} from "react-bootstrap-icons";
import { useEditorContext } from "../../../../../context/editorContext/EditorContext";
import { useTranslation } from "react-i18next";

const NewClear = (props: { setActiveStep: Function }) => {
  const { board } = useBoardContext();
  const { setEtiquetaUsuario, limparProdutoSelecionado } = useEditorContext();
  const { t } = useTranslation("editor");
  const clearAll = () => {
    const objs = board?.getObjects();
    objs?.forEach((obj) => {
      if (obj.type !== "Workspace") board?.remove(obj);
    });
    setEtiquetaUsuario(undefined);
  };

  const handleStartOver = () => {
    setEtiquetaUsuario(undefined);
    limparProdutoSelecionado();
    props.setActiveStep(1);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className="btn btn-header-editor"
        startIcon={<FilePlus size={28} />}
        sx={{ flexDirection: "column" }}
        onClick={handleClick}
      >
        {t("Novo")}
      </Button>
      <Menu
        elevation={1}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ onMouseLeave: handleClose }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={clearAll}>
          <ListItemIcon>
            <FileEarmark size={20} />
          </ListItemIcon>
          {t("Limpar")}
        </MenuItem>
        <MenuItem onClick={() => handleStartOver()}>
          <ListItemIcon>
            <XOctagon size="20" />
          </ListItemIcon>
          {t("Escolher novo produto")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default NewClear;
