import { BoardContextProvider } from "@beontag/tageditor";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useEditorContext } from "../../context/editorContext/EditorContext";
import { useUserContext } from "../../context/userContext/userContext";
import IProdutoCardPimaco from "../../interfaces/produto/produtoCardPimaco";
import Authentication from "./authentication/authentication";
import Header from "./header";
import Steps from "./steps";
import ModalMinhasEtiquetas from "./steps/modalMinhasEtiquetas";

interface IEditorDimensoes {
    widthCm: number,
    heightCm: number,
    margimBottomCm: number,
    margimRightCm: number,
    borderRadiusCm: number | undefined
}

export default function EditorComponent(props: { setOpenPWAModal: Function }) {

    const {
        selecionarProduto,
        produtoSelecionado,
        setEtiqueta,
        setEtiquetaUsuario
    } = useEditorContext();

    const {
        usuarioLogado,
        logoff,
    } = useUserContext();


    const [loggedInUser, setLoggedInUser] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(1);
    const [produtoSelecionadoLocal, setProdutoSelecionado] = useState<string>('');
    const [openModalMinhasEtiquetas, setOpenModalMinhasEtiquetas] = useState<boolean>(false);

    const handleChangeActiveStep = (step: number) => {
        if (produtoSelecionadoLocal != '') {
            if (activeStep == 3) {
                // Lógica antes de sair da edição da etiqueta
            }
            setActiveStep(step)
        }
    }

    useEffect(() => {
        setLoggedInUser(usuarioLogado)
    }, [usuarioLogado])

    const handleProdutoSelecionado = (produto: IProdutoCardPimaco) => {
        setEtiquetaUsuario(undefined)
        setProdutoSelecionado(produto.sku)
        selecionarProduto(produto)
        setActiveStep(2)
    }

    const handleSelectTemplate = (jsonTemplate?: string) => {
        // Recebe um template caso selecione algum no passo 2. Não receberá caso selecione um template em branco
        if (jsonTemplate) setEtiqueta(jsonTemplate)
        //setTemplateEdicao({ idTema: 0, idTemplate: 0, json: '', nome: '' })
        setActiveStep(3)
    }

    let tags: IEditorDimensoes[] = [];
    let tagRepeater: number[] | undefined = undefined;

    if (produtoSelecionado?.dimensoes && produtoSelecionado.dimensoes.length > 0) {
        tags = produtoSelecionado.dimensoes.map((dimensao) => {
            let tag = {
                widthCm: dimensao.larguraEtiqueta / 10,
                heightCm: dimensao.alturaEtiqueta / 10,
                margimBottomCm: dimensao.margemInferiorEtiqueta / 10,
                margimRightCm: dimensao.margemDireitaEtiqueta / 10,
                borderRadiusCm: dimensao.curvaBordaEtiqueta ?? 0.1
            }
            return tag
        })
        tagRepeater = [
            0,
            1,
            2,
            2,
            1,
            0
        ]
    } else {
        if (produtoSelecionado) {
            tags.push({
                widthCm: (produtoSelecionado?.larguraEtiqueta ?? 25.4) / 10,
                heightCm: (produtoSelecionado?.alturaEtiqueta ?? 66.8) / 10,
                margimRightCm: (produtoSelecionado?.margemDireitaEtiqueta ?? 2.5) / 10,
                margimBottomCm: (produtoSelecionado?.margemInferiorEtiqueta ?? 0) / 10,
                borderRadiusCm: produtoSelecionado.curvaBordaEtiqueta ?? 0.1
            })
        } else {
            tags.push({
                heightCm: 25.4 / 10,
                widthCm: 66.8 / 10,
                margimRightCm: 2.5 / 10,
                margimBottomCm: 0.0 / 10,
                borderRadiusCm: 0.1
            })
        }
    }


    const boardContextProps = {
        page: {
            widthCm: (produtoSelecionado?.larguraPagina ?? 210) / 10,
            heightCm: (produtoSelecionado?.alturaPagina ?? 297) / 10,
            paddingTopCm: (produtoSelecionado?.margemSuperiorPagina ?? 13.0) / 10,
            paddingLeftCm: (produtoSelecionado?.margemEsquerdaPagina ?? 5.3) / 10,
            tagRepeater,
            rows: produtoSelecionado?.linhasPagina,
            columns: produtoSelecionado?.colunasPagina
        },
        tags,
        editor: {
            initialRatio: 40
        }
    };


    return (
        <>
            {loggedInUser &&
                <>
                    <BoardContextProvider {...boardContextProps}>


                        <Header
                            userLogged={usuarioLogado}
                            setUserLogged={logoff}
                            activeStep={activeStep}
                            handleChangeActiveStep={handleChangeActiveStep}
                            setOpenPWAModal={props.setOpenPWAModal}
                            setOpenModalMinhasEtiquetas={setOpenModalMinhasEtiquetas}
                            setActiveStep={setActiveStep}
                        />

                        <Box className="editor-content" key="editor-content-box-logado">
                            <Steps
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                                produtoSelecionado={produtoSelecionadoLocal}
                                handleProdutoSelecionado={handleProdutoSelecionado}
                                handleSelectTemplate={handleSelectTemplate}
                                setOpenModalMinhasEtiquetas={setOpenModalMinhasEtiquetas}
                                key="steps-editor"
                                setProdutoSelecionado={setProdutoSelecionado}
                            />
                        </Box>
                        <ModalMinhasEtiquetas
                            open={openModalMinhasEtiquetas}
                            handleCloseModal={() => setOpenModalMinhasEtiquetas(false)}
                            setActiveStep={setActiveStep}
                            setProdutoSelecionado={setProdutoSelecionado}
                        />
                    </BoardContextProvider>
                </>
            }

            {!loggedInUser &&
                <Box className="editor-content" key="editor-content-box-deslogado">
                    <Authentication userLogged={usuarioLogado} key="authentication-editor" />
                </Box>
            }

        </>
    )
}