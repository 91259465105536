import { useCallback, useState } from "react";
import { useBoardContext } from "@beontag/tageditor";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Arrow90degUp,
  Arrow90degRight,
  Arrow90degLeft,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const Rotate = () => {
  const { board, boardHistory } = useBoardContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation("editor");
  const rotate = useCallback(
    (angle: number) => {
      if (board && boardHistory) {
        const selection = board.activeSelection ?? board.activeObjects[0];

        selection.rotate((selection.angle ?? 0) + angle);

        //board.fireModified(selection);

        board.requestRenderAll();
      }
    },
    [board, boardHistory]
  );

  return (
    <>
      <Button
        className="btn"
        startIcon={<Arrow90degUp size={16} />}
        endIcon={<KeyboardArrowDown />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={!board?.activeObjects.length}
      >
        {t("Rotação")}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          className: "menu-dropdown",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <MenuItem onClick={() => rotate(90)}>
          <ListItemIcon>
            <Arrow90degRight size={16} />
          </ListItemIcon>

          <ListItemText> {t("90º Direita")} </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => rotate(-90)}>
          <ListItemIcon>
            <Arrow90degLeft size={16} />
          </ListItemIcon>

          <ListItemText> {t("90º Esquerda")} </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Rotate;
