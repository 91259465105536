import Slider from 'react-slick'
import { IPimacoTimelineItem } from '../../interfaces/timeline/timelineItem'
import TimelineItem from './timelineItem'

import React, { useRef } from 'react'
import NextPrevButtonGroup from '../common/nextPrevButtonGroup'

import hist_pimaco_1956 from '../../assets/quem_somos/hist-pimaco-1956.webp'
import hist_pimaco_1957 from '../../assets/quem_somos/hist-pimaco-1957.webp'
import hist_pimaco_1966 from '../../assets/quem_somos/hist-pimaco-1966.webp'
import hist_pimaco_1972 from '../../assets/quem_somos/hist-pimaco-1972.webp'
import hist_pimaco_1987 from '../../assets/quem_somos/hist-pimaco-1987.webp'
import hist_pimaco_1993 from '../../assets/quem_somos/hist-pimaco-1993.webp'
import hist_pimaco_2004 from '../../assets/quem_somos/hist-pimaco-2004.webp'
import hist_pimaco_2006 from '../../assets/quem_somos/hist-pimaco-2006.webp'
import hist_pimaco_2007 from '../../assets/quem_somos/hist-pimaco-2007.webp'
import hist_pimaco_2013 from '../../assets/quem_somos/hist-pimaco-2013.webp'
import hist_pimaco_2021 from '../../assets/quem_somos/hist-pimaco-2021.webp'

export default function TimelineSlider() {

    const customSlider = useRef()
    const settings = {
        className: "timeline-slider",
        centerMode: true,
        infinite: false,
        centerPadding: "250px",
        slidesToShow: 1, 
        arrows: false,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            }
        ]
    }

    const goToNext = () => {
        // @ts-ignore
        customSlider.current.slickNext()
    }

    const goToPrev = () => {
        // @ts-ignore
        customSlider.current.slickPrev()
    }

    const TimelineItens:IPimacoTimelineItem[] = [
        {
            img: hist_pimaco_1956,
            title: '1956',
            text: '1º de agosto: Jovens empreendedores, Wilde Pinheiro, Orlando Malheiros e João Martins Coelho fundam a Pimaco. O primeiro equipamento da Pimaco, a máquina flexográfica Mark Andy para impressão em duas cores.'
        },
        {
            img: hist_pimaco_1957,
            title: '1957',
            text: 'Pinheiro e Malheiros deixam a empresa. Domingos Baganha Enes Rua se associa à João Martins Coelho.'
        },
        {
            img: hist_pimaco_1966,
            title: '1966',
            text: 'A Pimaco desenvolve um aparelho que, adaptado à maquina impressora, cortava as primeiras etiquetas.'
        },
        {
            img: hist_pimaco_1972,
            title: '1972',
            text: 'A Pimaco importa a primeira máquina especial para fabricar etiquetas auto-adesivas do fabricante suíço Gallus.'
        },
        {
            img: hist_pimaco_1987,
            title: '1987',
            text: 'A Pimaco investe na ampliação do parque industrial, em novas tecnologias e em equipamentos de última geração.'
        },
        {
            img: hist_pimaco_2004,
            title: '2004',
            text: 'A Pimaco adota o GMP e recebe o certificado ISSO 9001/2000'
        },
        {
            img: hist_pimaco_2006,
            title: '2006',
            text: 'A Pimaco lança etiquetas feitas com papel reciclado e em 1º de agosto, completou 50 anos. Em setembro a Pimaco passa a fazer parte do grupo BIC.'
        },
        {
            img: hist_pimaco_2007,
            title: '2007',
            text: 'Pimaco exporta seus produtos para a América Latina e Europa.'
        },
        {
            img: hist_pimaco_2013,
            title: '2013',
            text: 'Nova identidade visual Pimaco, novo logo, novas embalagens mais simples e fáceis de entender.'
        },
        {
            img: hist_pimaco_2021,
            title: '2021',
            text: 'Pimaco é adquirida pela Beontag.'
        },
    ]


    return (
        <div className='timeline-slider-container'>
            {/* @ts-ignore */}
            <Slider {...settings} ref={slider => (customSlider.current = slider)}>
                {TimelineItens.map((item, index) => {
                    return (
                        <TimelineItem item={item} key={index} />
                    )}
                )}
            </Slider>
            
            <NextPrevButtonGroup
                goToNext={goToNext}
                goToPrev={goToPrev}
            />

        </div>
    )
}