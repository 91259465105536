import { IStep } from "../../../interfaces/assistenteStep";

import {
    Impressao,
    Escrever,
    ProntoUso
} from './steps_tipo_interacao'

import {
    Retangular,
    Circular
} from './steps_formatos'

export const CartasEnvelopes:IStep = {
    stepId: 10,
    text: 'Cartas e envelopes',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em cartas e envelopes',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'CartasEnvelopes',
}

export const PastasDocumentos:IStep = {
    stepId: 11,
    text: 'Pastas e documentos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em pastas e documentos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'PastasDocumentos'
}

export const Malotes:IStep = {
    stepId: 12,
    text: 'Malotes',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em malotes',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Malotes'
}

export const Gavetas:IStep = {
    stepId: 13,
    text: 'Gavetas',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em gavetas',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Gavetas'
}

export const IdentificarObjetos:IStep = {
    stepId: 14,
    text: 'Identificar objetos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para identificar objetos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'IdentificarObjetos'
}

export const CDsDVDs:IStep = {
    stepId: 15,
    text: 'CDs e DVDs',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em CDs e DVDs',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'CDsDVDs'
}

export const LacrarEmbalagem:IStep = {
    stepId: 16,
    text: 'Lacrar embalagens',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para lacrar embalagens',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'LacrarEmbalagem'
}

export const Caixas:IStep = {
    stepId: 17,
    text: 'Caixas',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em caixas',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Caixas'
}

export const CodigoBarraQRCode:IStep = {
    stepId: 18,
    text: 'Códigos de barra ou QR Code',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para códigos de barra ou QR codes',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'CodigoBarraQRCode'
}

export const Rotulos:IStep = {
    stepId: 19,
    text: 'Rotulos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para rótulos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: 'Uso',
    value: 'Rotulos'
}

export const CategorizarProdutos:IStep = {
    stepId: 20,
    text: 'Categorizar produtos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para organizar produtos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'CategorizarProdutos'
}

export const Inventario:IStep = {
    stepId: 21,
    text: 'Inventário',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em inventário',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Inventario'
}

export const Precificar:IStep = {
    stepId: 22,
    text: 'Precificar',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para precificar',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Precificar'
}

export const PlacaSinalizacao:IStep = {
    stepId: 23,
    text: 'Placas de sinalização',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para sinalização',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: 'Uso',
    value: 'PlacaSinalizacao'
}

export const ImpressaoRapida:IStep = {
    stepId: 24,
    text: 'Impressão rápida',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para impressão rápida',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: 'Uso',
    value: 'ImpressaoRapida'
}

export const ImpressaoMatricial:IStep = {
    stepId: 25,
    text: 'Impressão matricial',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para impressão matricial',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: 'Uso',
    value: 'ImpressaoMatricial'
}

export const CartaoVisita:IStep = {
    stepId: 26,
    text: 'Cartão de visita',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para cartões de visita',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'CartaoVisita'
}

export const OrgEscritorioDocumentos:IStep = {
    stepId: 27,
    text: 'Organizar escritório e documentos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'organizar escritório e documentos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: '',
    value: ''
}

export const Produtos:IStep = {
    stepId: 28,
    text: 'Produtos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'organizar produtos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: '',
    value: ''
}

export function stepBuilderCorporativo() {

    CartasEnvelopes.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [Retangular, Circular], false) : Impressao
    )

    PastasDocumentos.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [Retangular, Circular], false) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    Malotes.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [], true) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    Gavetas.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
    )

    IdentificarObjetos.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    CDsDVDs.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [], true) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
    )

    if (OrgEscritorioDocumentos.nextStep.length == 0) {
        OrgEscritorioDocumentos.nextStep.push(
            CartasEnvelopes,
            PastasDocumentos,
            Malotes,
            Gavetas,
            IdentificarObjetos,
            CDsDVDs
        );
    }

    LacrarEmbalagem.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
    )

    Caixas.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    CodigoBarraQRCode.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [], true) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
    )

    CategorizarProdutos.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    Inventario.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
    )

    Precificar.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
    )

    CartaoVisita.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [], true) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
    )

    if (Produtos.nextStep.length == 0) {
        Produtos.nextStep.push(
            LacrarEmbalagem,
            Caixas,
            CodigoBarraQRCode,
            Rotulos,
            CategorizarProdutos,
            Inventario,
            Precificar,
            PlacaSinalizacao,
            ImpressaoRapida,
            ImpressaoMatricial,
            CartaoVisita
        );
    }

    return [OrgEscritorioDocumentos, Produtos];
}