import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function ProdutoCardInfo(props: {name:string, format: string, code:string, productId: number, sku: string }) {

    const { t } = useTranslation('produtos')

    return (
        <div className='produto-card-info-container'>
            <p className='triple-line'>{props.name}</p>
            <p>{t("Formato")}: {props.format}</p>
            <p className='double-line'>COD: {props.code}</p>

            <Link to={`/produto/${props.sku}`} className="btn-link">
                <Button
                    variant='outlined'
                    className="btn btn-outlined-primary"
                >
                    {t("Saiba mais")}
                </Button>
            </Link>
            
        </div>
    )
}