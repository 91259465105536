import DownloadBox from "../../../../components/downloads/download-box";

import {
  GabaritoCDpplyIcon,
  GabaritoCasaArtesanatoIcon,
  GabaritoInkjetLaserIcon,
  GabaritoPimatabIcon,
  GabaritoFotografiaIcon,
  GabaritoCartaoVisitaIcon,
  GabaritoEscritorioEscolarIcon,
  SearchButtonIcon
} from "../../../../components/common/icons";
import AprendaLerEtiquetasPimaco from "../../../../components/leitura-etiquetas/aprenda-ler-etiquetas";

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import background_image from '../../../../assets/desktop_downloads.webp';
import background_image_mobile from '../../../../assets/desktop_downloads_mobile.webp';

import { Accordion, AccordionDetails, AccordionSummary, InputAdornment, TextField } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useEffect, useState } from "react";

import gabaritoService from "../../../../services/gabaritoService";
import { IGabarito } from "../../../../interfaces/gabarito";

const icons = {
    'CDpply': <GabaritoCDpplyIcon />,
    'Inkjet Laser': <GabaritoInkjetLaserIcon />,
    'Casa e Artesanato': <GabaritoCasaArtesanatoIcon />,
    'Fotografia': <GabaritoFotografiaIcon />,
    'Cartão de Visita': <GabaritoCartaoVisitaIcon />,
    'Escritório e Escolar': <GabaritoEscritorioEscolarIcon />,
    'Pimatab': <GabaritoPimatabIcon />,
    'SLP': <GabaritoInkjetLaserIcon />,
}

export default function Gabaritos() {

    const theme = createTheme();
    const { t } = useTranslation('downloads');
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const [gabaritosComplete, setGabaritosComplete] = useState<IGabarito[]>([])

    useEffect(() => {
        gabaritoService.getGabaritos().then((resp) => {
            // @ts-ignore
            const index = resp.data.findIndex(({nome}) => nome === 'Inkjet Laser');
            if(index !== -1) {
                //if the matching element is found, 
                const updatedData = [...resp.data];
                //then remove that element and use `unshift`
                updatedData.unshift(...updatedData.splice(index, 1));
                setGabaritosComplete(updatedData);
                setGabaritos(updatedData)
            } else {
                setGabaritosComplete(resp.data);
                setGabaritos(resp.data)
            }
        }).catch((err) => {
            console.error(err)
        })
    }, [])

    const [gabaritos, setGabaritos] = useState<IGabarito[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        if (searchTerm) {
            const filtered = gabaritos?.map((gabaritoGroup) => {
                const filteredGabarito = gabaritoGroup.gabaritos.filter((gabaritoItem) => {
                    const lowercaseSearchTerm = searchTerm.toLowerCase()
                    const lowercaseNomeGabarito = gabaritoItem.nome.toLowerCase()

                    return lowercaseNomeGabarito.includes(lowercaseSearchTerm)
                })

                return { nome: gabaritoGroup.nome, gabaritos: filteredGabarito }
            })

            setGabaritos(filtered)
        } else {
            setGabaritos(gabaritosComplete)
        }
    }, [searchTerm])

    const [expanded, setExpanded] = useState<string | false>('');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
		window.scroll({
            // @ts-ignore
            
			// eslint-disable-next-line no-restricted-globals
			top: top,
			left: 0,
            behavior: 'smooth'	
		})
	}, [])

    return (
        <>
            <div className="faca-mais-com-etiquetas-container">     
            <div className="faca-mais-com-etiquetas-text-container">

                <h1>{t("Gabaritos de impressão")}</h1>
                <p className="page-paragraph">{t("Aqui, você encontra o gabarito da sua etiqueta para facilitar a impressão")}</p>                

            </div>

            <img src={matches ? background_image : background_image_mobile} />
        </div>
        <div className="page-content gabaritos-container">

            <div className="downloads-paragraph">
                {/* @ts-ignore */}
                <p className="page-paragraph">{t("Baixe o gabarito que corresponde a sua etiqueta e use o Software de Impressão Pimaco para personalização")}</p>

                <TextField
                // @ts-ignore
                    placeholder={t("Pesquise um código...")}
                    value={searchTerm}
                    onChange = {(e) => setSearchTerm(e.target.value)}
                    variant="filled"
                    className='search-field-faq'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchButtonIcon onClick={() => undefined}/>       
                            </InputAdornment>
                            
                        )
                    }}
                />
            </div>

            {gabaritos ? gabaritos.map((gabaritoGroup, index) => {
                if (gabaritoGroup.gabaritos.length !== 0) {
                    return (
                        <Accordion
                            className="accordion-faq-duvidas"
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                            elevation={0}
                            square
                            key={gabaritoGroup.nome}
                        >
                            <AccordionSummary
                                expandIcon={expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />}
                                >
                                    <h2>{t(gabaritoGroup.nome)}</h2>
                                </AccordionSummary>
                                <AccordionDetails
                                    className='accordion-faq-duvidas-details'
                                    >
                                        <div className="downloads-group">
                                            {gabaritoGroup.gabaritos.map((gabaritoItem) => {
                                                return (
                                                    <DownloadBox
                                                        code=""
                                                        titulo={gabaritoItem.nome}
                                                        downloadLink={gabaritoItem.url}
                                                        downloadButtonText="Baixar"
                                                        // @ts-ignore
                                                        iconImage={icons[gabaritoGroup.nome]}
                                                        key={gabaritoItem.nome}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </AccordionDetails>
                        </Accordion>
                    )
                } else {
                    return <></>
                    }
            }
            ) : '' }

        </div>

        <AprendaLerEtiquetasPimaco />
        </>
    )
}