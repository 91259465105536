import { Outlet } from "react-router-dom";
import HeaderPimaco from "../../components/header-footer/header";
import { ICategoria } from "../../interfaces/produto/categoria";
import FloatingButton from "../../components/common/floatingButton";
import FooterPimaco from "../../components/header-footer/footer";
import { useEffect, useState } from "react";
import '../../styles/pimaco/main.css'
import sProduto from '../../services/produtoService'
import { useTranslation } from "react-i18next";

export default function PimacoSite() {
	const [hasOpenMenu, setHasOpenMenu] = useState<boolean>(false);
	const [categorias, setCategorias] = useState<ICategoria[]>([])
	const { i18n } = useTranslation();

	useEffect(() => {

		const locale = i18n.language;

		sProduto.obtemCategorias(locale, false).then((resp) => {
			setCategorias(resp.data.categoriasDB)
		}).catch((err) => {
			console.error(err)
		});
	}, [i18n.language])

	return (
		<>
			<HeaderPimaco
				setHasOpenMenu={setHasOpenMenu}
				categorias={categorias}
			/>

			<div className={hasOpenMenu ? 'overlay-active' : ''}>
				<div className="container">
					<Outlet />
				</div>
			</div>

			<FooterPimaco categorias={categorias} />
			{/* <FloatingButton setHasOpenMenu={setHasOpenMenu} /> */}
		</>
	);
}