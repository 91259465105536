import { ICategoria, IFlatCategoria } from "../../../../interfaces/produto/categoria";
import { ISubCategoria } from "../../../../interfaces/produto/subCategoria";
import { useEffect, useState } from "react";
import { IFormEditorFiltroProdutos } from "../../../../interfaces/editor/form-filtro-produtos";

import EditorMenuContainer from "../editorMenuContainer";

import IProdutoCardPimaco from "../../../../interfaces/produto/produtoCardPimaco";

import sProduto from '../../../../services/produtoService'
import FormFiltroEtiquetaSelection from "./formFiltroEtiquetaSelection";
import AccordionCategoriasProdutosEditor from "./accordionCategoriasProdutosEditor";
import TabelaProdutosEditor from "./tabelaProdutosEditor";
import { useEditorContext } from "../../../../context/editorContext/EditorContext";

function EtiquetaSelection(props: {
    handleProdutoSelecionado: Function,
    setActiveStep: Function,
    setProdutoSelecionado: Function,
    setOpenModalMinhasEtiquetas: Function
}) {

    const {
        categoriasSite,
        categoriasEditor,
        produtosEditor
    } = useEditorContext();

    const categoriasFlatMap: IFlatCategoria[] = categoriasEditor.flatMap((categoria) => {
        if (categoria.filtros[0].nome == 'undefined') {
            categoria.filtros[0].nome = categoria.nome
        }
        return categoria.filtros
    }) as IFlatCategoria[]

    // TODO: fix
    // @ts-ignore
    const subcategorias = categoriasSite.filter(categoria => categoria.subCategorias).reduce((prev: ISubCategoria[], curr) => prev.concat(curr.subCategorias), []);
    const [subCategoriaSelecionada, setSubCategoriaSelecionada] = useState<ISubCategoria>(subcategorias[0]);
    const [activeFilter, setActiveFilter] = useState<string>('etiq');
    const [produtos, setProdutos] = useState<IProdutoCardPimaco[]>(produtosEditor);
    const [isLoadingProdutos, setIsLoadingProdutos] = useState<boolean>(false);

    const [form, setForm] = useState<IFormEditorFiltroProdutos>({
        nomeCodigo: '',
        altura: '',
        largura: '',
        formato: '',
        quantidadePorFolha: ''
    });

    const handleChangeCategory = async (categoryAlias: string, uso?: string) => {
        setIsLoadingProdutos(true)
        await sProduto.listProduto('pt', {}, categoryAlias, true).then((resp) => {
            setProdutos(resp.data.produtos)
            setActiveFilter(categoryAlias)
            setIsLoadingProdutos(false)
        }).catch((err) => {
            setIsLoadingProdutos(false)
        })

    }

    useEffect(() => {
        clearFilters();
    }, [subCategoriaSelecionada])


    const clearFilters = () => {
        setForm({
            nomeCodigo: '',
            altura: '',
            largura: '',
            formato: '',
            quantidadePorFolha: ''
        });
    }

    const handleInputChange = (e: any) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleProductFiltering = () => {
        let produtosToFilter = produtos ?? [];

        if (form.nomeCodigo.trim().length > 0) {
            produtosToFilter = produtosToFilter.filter(prod =>
                prod.codigo.toLowerCase().includes(form.nomeCodigo.toLowerCase()) || prod.nome?.toLowerCase().includes(form.nomeCodigo.toLowerCase())
            );
        }

        if (form.largura.trim().length > 0) {
            produtosToFilter = produtos.filter(prod => {
                const dimensoes = prod.dimensao.toUpperCase().split('X') ?? [];
                // @ts-ignore
                // TODO: fix
                return dimensoes.length > 0 && dimensoes.shift().indexOf(form.largura) > -1;
            });
        }

        if (form.altura.trim().length > 0) {
            produtosToFilter = produtos.filter(prod => {
                const dimensoes = prod.dimensao.toUpperCase().split('X') ?? [];
                // TODO: fix
                // @ts-ignore
                return dimensoes && dimensoes.length > 0 && dimensoes.pop().indexOf(form.altura) > -1;
            });
        }

        if (form.formato.trim().length > 0) {
            produtosToFilter = produtosToFilter.filter(prod => prod.forma.toUpperCase() === form.formato.toUpperCase());
        }

        if (form.quantidadePorFolha != '') {
            produtosToFilter = produtosToFilter.filter(prod => prod.etiquetasPorFolha == parseInt(form.quantidadePorFolha));
        }

        return produtosToFilter;
    }

    return (
        <>
            <EditorMenuContainer>
                <>
                    <div
                        className="editor-menu-filters-spacing"
                        aria-hidden="true"
                        key="editor-menu-filters-spacing"
                    ></div>

                    <FormFiltroEtiquetaSelection
                        form={form}
                        handleInputChange={handleInputChange}
                        setForm={setForm}
                        setActiveStep={props.setActiveStep}
                        setProdutoSelecionado={props.setProdutoSelecionado}
                        setOpenModalMinhasEtiquetas={props.setOpenModalMinhasEtiquetas}
                    />
                </>

            </EditorMenuContainer>

            <div className="editor-menu-etiqueta-selection">

                <AccordionCategoriasProdutosEditor
                    setProdutos={setProdutos}
                    categorias={categoriasFlatMap}
                    handleChangeCategory={handleChangeCategory}
                    activeFilter={activeFilter}
                    produtosEditor={produtosEditor}
                />

                <TabelaProdutosEditor
                    produtos={produtos}
                    isLoadingProdutos={isLoadingProdutos}
                    handleProductFiltering={handleProductFiltering}
                    handleProdutoSelecionado={props.handleProdutoSelecionado}
                />

            </div>
        </>
    );
}

export default EtiquetaSelection;