import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import como_ler_etiquetas from '../../assets/como_ler_as_etiquetas.webp'

export default function AprendaLerEtiquetasPimaco() {

    const { t } = useTranslation('downloads')

    return (
        <div className="escolha-melhor-etiqueta-container">
            <div className="escolha-melhor-etiqueta-content">
                <div className="escolha-melhor-etiqueta-text-container">

                    <h1>{t("Aprenda a ler nossas etiquetas")}</h1>
                    <p className="page-paragraph">{t("Precisa de ajuda para encontrar sua etiqueta? Nós te ajudamos!")}</p>

                    <Link to="/leitura_etiquetas" className="btn-link">
                        <Button
                            variant="outlined"
                            className="btn btn-outlined-primary"
                        >
                            {t("Saiba mais")}
                        </Button>
                    </Link>
                    
                </div>
                
                <div className="escolha-melhor-etiqueta-image-container">
                    <img src={como_ler_etiquetas} alt="" />
                </div>
            </div>
            
        </div>
    )
}