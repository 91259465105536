import { IStep } from "../../../interfaces/assistenteStep";

export const Retangular:IStep = {
    stepId: 4,
    text: 'Retangular',
    stepDescriptionText: 'Qual o formato da etiqueta: ',
    selectedText: '',
    parent: undefined,
    nextStep: [],
    finalStep: true,
    name: 'Forma',
    value: 'Retangular'
}

export const Circular:IStep = {
    stepId: 5,
    text: 'Circular',
    stepDescriptionText: 'Qual o formato da etiqueta: ',
    selectedText: '',
    parent: undefined,
    nextStep: [],
    finalStep: true,
    name: 'Forma',
    value: 'Circular'
}

export const Estrela:IStep = {
    stepId: 6,
    text: 'Estrela',
    stepDescriptionText: 'Qual o formato da etiqueta: ',
    selectedText: '',
    parent: undefined,
    nextStep: [],
    finalStep: true,
    name: 'Forma',
    value: 'Estrela'
}

export const Coracao:IStep = {
    stepId: 7,
    text: 'Coração',
    stepDescriptionText: 'Qual o formato da etiqueta: ',
    selectedText: '',
    parent: undefined,
    nextStep: [],
    finalStep: true,
    name: 'Forma',
    value: 'Coracao'
}

export const Quadrada:IStep = {
    stepId: 8,
    text: 'Quadrada',
    stepDescriptionText: 'Qual o formato da etiqueta: ',
    selectedText: '',
    parent: undefined,
    nextStep: [],
    finalStep: true,
    name: 'Forma',
    value: 'Quadrado'
}

export const Diversos:IStep = {
    stepId: 9,
    text: 'Diversos',
    stepDescriptionText: 'Qual o formato da etiqueta: ',
    selectedText: '',
    parent: undefined,
    nextStep: [],
    finalStep: true,
    name: 'Forma',
    value: 'Diversos'
}