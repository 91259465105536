import { useTranslation } from "react-i18next"

export default function NextPrevButtonGroup(props: { goToPrev:Function, goToNext:Function }) {

	const { t } = useTranslation('homepage')

    return (
        <div className='page-content button-slider-container'>
                <div
                    className='btn-slider-container btn-slider-prev'
                    onClick={() => props.goToPrev()}
                >
                    <p className="page-paragraph">{t("ANTERIOR")}</p>
                </div>

                <div
                    className='btn-slider-container btn-slider-next'
                    onClick={() => props.goToNext()}
                >
                    <p className="page-paragraph">{t("PRÓXIMO")}</p>
                </div>

            </div>
    )
}