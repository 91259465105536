import { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import sUsuario from "../../../services/usuarioService";
import { handleFormErrors } from "../../../helpers/formErrorHandler";

import { useMessage } from "../../../context/alertContext/context";
import ShowPasswordInputAdornment from "./showPasswordInputAdornment";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../context/userContext/userContext";

const ModalDeleteAccount = (props: {
  open: boolean;
  handleCloseModal: Function;
}) => {

  const { userData: { email }, logoff } = useUserContext();
  const [inputPasswordVisible, setInputPasswordVisible] =
    useState<boolean>(false);

  const { showAlertApp } = useMessage();
  const [timerClock, setTimerClock] = useState<number>(15);

  useEffect(() => {

    if (props.open) {
      const timer = setInterval(() => {
        if (timerClock > 0) {
          setTimerClock((clock) => clock - 1)
        }
      }, 1000)

      return () => {
        clearInterval(timer)
      }

    } else {
      setTimerClock(15)
    }

  }, [timerClock, props.open])


  const [formData, setFormData] = useState({
    email: email,
    password: "",
  });

  const [errors, setErrors] = useState({
    password: false,
  });

  const handleInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDeleteAccount = async (e: any) => {
    e.preventDefault();
    if (handleFormErrors(formData, errors, setErrors)) {
      return;
    }

    if (timerClock !== 0 || errors.password || formData.password.trim() === '') {
      return;
    }

    await sUsuario
      .deleteAccount(formData)
      .then((resp) => {
        showAlertApp(t('Conta excluída com sucesso, você será deslogado'), 'success', 5000)
        setTimeout(() => {
          logoff()
        }, 4000)
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          showAlertApp(
            t("Senha inválida, tente novamente"),
            'error',
            5000
          )
          setFormData({ ...formData, password: "" })
          return;
        } else {
          showAlertApp(
            t("Ocorreu um erro ao excluir a conta, tente novamente mais tarde ou entre em contato com o suporte"),
            'error',
            5000
          )
        }
      })

    // await sUsuario
    //   .updatePassword(formData)
    //   .then((resp) => {
    //     showAlertApp(t("Senha atualizada com sucesso!"), "success", 5000);
    //     // Redirecionar para tela de login, mensagem
    //   })
    //   .catch((err) => {
    //     showAlertApp(
    //       t("Erro ao atualizar senha, tente novamente mais tarde"),
    //       "error",
    //       5000
    //     );
    //   });
  };
  const { t } = useTranslation("editor");
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.handleCloseModal()
          setTimerClock(15)
        }}
        scroll="paper"
        maxWidth={"md"}
      >
        <DialogTitle>
          <IconButton onClick={() => props.handleCloseModal()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            rowSpacing={2}
            className="editor-login-authentication modal-grid"
          >
            <Grid item xs={12}>
              <h1 className="editor-login-authentication-text">
                {t("Apagar conta")}
              </h1>

              <p>{t("Ao apagar sua conta, você não terá mais acesso ao Software de Impressão Pimaco. Além disso, todas os seus designs customizados salvos na nuvem serão apagados. Essa ação é IRREVERSÍVEL.")}</p>
              <p>{t("Para confirmar essa ação, digite sua senha: ")}</p>
            </Grid>

            <Grid item xs={12}>
              <form onSubmit={handleDeleteAccount}>

              <TextField
                id="password"
                type={inputPasswordVisible ? "text" : "password"}
                name="password"
                aria-label="Senha antiga:"
                variant="filled"
                value={formData.password}
                onChange={handleInputChange}
                onPaste={(e) => {
                  e.preventDefault();
                }}
                error={errors.password}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      disablePointerEvents
                      className="editor-login-authentication-fields-adornment"
                    >
                      <span className="form-label">{t("Senha")}</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <ShowPasswordInputAdornment
                      inputVisible={inputPasswordVisible}
                      setInputVisible={setInputPasswordVisible}
                    />
                  ),
                }}
              />
              </form>
            </Grid>

            <Grid item xs={12} className="modal-userdata-buttons">
              <Button
                type="submit"
                variant="contained"
                className="form-contato-submit-button btn btn-contained-danger"
                disableElevation
                disabled={timerClock !== 0 || errors.password || formData.password.trim() === ''}
                onClick={handleDeleteAccount}
              >
                {timerClock !== 0 ? t(`Aguarde ${timerClock} segundos...`) : t("Excluir conta")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalDeleteAccount;
