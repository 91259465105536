import { PropertyContext, useBoardContext } from "@beontag/tageditor";
import { IconButton, Popper } from "@mui/material";
import * as properties from "./properties";
import Draggable from 'react-draggable';
import { useState } from "react";
import { ArrowsMove, XLg } from 'react-bootstrap-icons'

function PropertyBar(props: { handleOpen: null | HTMLElement }) {

    return (
        <div>
            <Draggable cancel=".not-draggable, .MuiInputBase-root">
                <Popper open={props.handleOpen != null} className="property-bar-container">    
                    <PropertyContext componentCollection={properties} />

                </Popper>
            </Draggable>
        </div>
    );
}

export default PropertyBar;