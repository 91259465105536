import { useBoardContext, usePropertyContext } from "@beontag/tageditor";
import { Grid, IconButton, TextField } from "@mui/material";

import { ArrowsMove, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const Polyline: React.FunctionComponent = (props) => {
  const { properties, setProperty } = usePropertyContext<fabric.Polyline>();

  const handleOnChange = (key: string, rawValue: string) => {
    setProperty({ [key]: rawValue });
  };

  const handleOnChangeNumber = (key: string, rawValue: string) => {
    const value = parseInt(rawValue);
    setProperty({ [key]: value });
  };

  const { board } = useBoardContext();

  const closeWindow = () => {
    board?.setActiveObjects();
  };

  const { t } = useTranslation("editor");

  return (
    <>
      <div className="property-bar-title">
        {t("Propriedades do objeto")}
        <ArrowsMove size={20} className="icon left" />
        <IconButton
          onClick={() => closeWindow()}
          className="icon right not-draggable"
        >
          <XLg size={20} color="white" />
        </IconButton>
      </div>
      <Grid container className="tb-main-grid">
        <Grid item md={3}>
          <div className="property-bar-text-itens">
            <label htmlFor="cor-do-objeto" className="cursor-pointer">
              {t("Cor do Objeto")}
            </label>
            <input
              id="cor-do-objeto"
              className="property-bar-text-color-input"
              type="color"
              value={properties.fill?.toString() || "#000"}
              onChange={(e) => handleOnChange("fill", e.currentTarget.value)}
            ></input>
          </div>
          <div className="property-bar-text-itens">
            <label htmlFor="cor-da-borda" className="cursor-pointer">
              {t("Cor da Borda")}
            </label>
            <input
              id="cor-da-borda"
              className="property-bar-text-color-input"
              type="color"
              value={properties.stroke?.toString() || "#000"}
              onChange={(e) => handleOnChange("stroke", e.currentTarget.value)}
            ></input>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="property-bar-text-itens">
            <label htmlFor="opacidade-do-objeto" className="cursor-pointer">
              {t("Opacidade")}
            </label>
            <TextField
              id="opacidade-do-objeto"
              className="property-bar-text-color-input"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
              value={properties.opacity || 1}
              onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
              variant="filled"
            />
          </div>
          <div className="property-bar-text-itens">
            <span>{t("Largura da borda")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 9, step: 1 } }}
              value={properties.strokeWidth?.toString() || "1"}
              onChange={(e) =>
                handleOnChangeNumber("strokeWidth", e.currentTarget.value)
              }
              variant="filled"
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Polyline;
