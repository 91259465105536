import api from "../api";
import { IProdutoFilterPimaco } from "../interfaces/produto/produtoFilterPimaco";

const listProduto = async function(locale: string, filters: { [key: string] : string | boolean }, categoria?:string, isEditor?: boolean) {

    let params = filters;
    if (isEditor) {
        params.ProdutosEditor = isEditor;
    };

    return api.get(`/categorias/${categoria}/produtos`, {
        params,
        headers: {
            "Accept-Language": locale
        }
    });
}

const obtemProduto = async function(locale: string, codigo?:string) {
    return api.get(`/produtos/${codigo}`, {
        headers: {
            "Accept-Language": locale
        }
    })
}

const obtemCategorias = async function(locale:string, products: boolean, isEditor?: boolean) {

    let params: {[key: string] : string | boolean } = {};
    params['ComProduto'] = products;
    if (isEditor) {
        params['ProdutosEditor'] = isEditor;
    }
    return api.get(`/categorias`, {
        params,
        headers: {
            "Accept-Language": locale
        }
    })
}


const pesquisaProduto = async function(locale:string, termo:string, filters?: { [key: string]: string }) {

    // @ts-ignore
    filters['pesquisa'] = termo;
    let params = filters;

    return api.get("/produtos/pesquisa", {
        params,
        headers: {
            "Accept-Language": locale
        }
    })
}

const destaqueProduto = async function(locale:string, termo:string, filters?: { [key: string]: string }) {

    // @ts-ignore
    filters['destaque'] = termo;
    let params = filters;

    return api.get("/produtos/pesquisa", {
        params,
        headers: {
            "Accept-Language": locale
        }
    })
}

const assistenteBusca = async function (locale: string, filters: { [key: string] : string }) {
    return api.get('/produtos/assistente', {
        params: filters,
        headers: {
            "Accept-Language": locale
        }
    })
}

const produtoService = {
    listProduto,
    obtemProduto,
    obtemCategorias,
    pesquisaProduto,
    assistenteBusca,
    destaqueProduto
}

export default produtoService;