import { useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, TextField } from '@mui/material';
import InputMask from "react-input-mask";
import { IFormFaleConosco } from '../../interfaces/form/form-fale-conosco';

import CloseIcon from '@mui/icons-material/Close';
import FormSwitcherButtons from './form-switcher-buttons';
import contatoService from '../../services/contatoService';

import ContentHeader from '../common/content-header';
import { Trans, useTranslation } from 'react-i18next';
import { useConfigContext } from '../../context/configContext';
import { useMessage } from '../../context/alertContext/context';


function FaleConosco(props: { modifyActiveComponent: Function, activeComponent: string, image: string, setOldComponent: Function }) {

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const { showAlertApp } = useMessage();

	const config = useConfigContext();

	const [validEmail, setValidEmail] = useState<boolean>(true);
	const [validTelefone, setValidTelefone] = useState<boolean>(true);

	const [form, setForm] = useState<IFormFaleConosco>({
		nome: '',
		email: '',
		telefone: '',
		pais: '',
		estado: '',
		//cidade: '',
		mensagem: '',
		//checkboxTermos: false,
		checkboxContatoTelefone: false,
		checkboxContatoEmail: false,
		checkboxContatoWhatsapp: false,
	});

	const [errors, setErrors] = useState({
		nome: false,
		pais: false,
		estado: false,
		mensagem: false,
		assunto: false,
	})

	function validation(fieldName: string, value: string) {
		if (fieldName == 'email') {

			//Responsável por verificar se o e-mail é válido
			var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

			if (value.match(validRegex)) {
				setValidEmail(true)
				return true;

			} else {
				setValidEmail(false)
				return false;
			}

		} else if (fieldName == 'telefone') {

			//Responsável por verificar se o telefone é válido
			var validRegex = /(?:\(([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;

			if (value.match(validRegex)) {
				setValidTelefone(true)
				return true;

			} else {
				setValidTelefone(false)
				return false;
			}
		}
	}

	function validateFields() {
		let errorExists = false;

		setErrors({
			assunto: false,
			nome: false,
			estado: false,
			mensagem: false,
			pais: false,
		})

		if (!form.nome) {
			setErrors(prevErrors => ({ ...prevErrors, nome: true }))
			errorExists = true;
		}

		if (!form.pais) {
			setErrors(prevErrors => ({ ...prevErrors, pais: true }))
			errorExists = true;
		}

		if (!form.estado) {
			setErrors(prevErrors => ({ ...prevErrors, estado: true }))
			errorExists = true;
		}

		if (!form.mensagem) {
			setErrors(prevErrors => ({ ...prevErrors, pais: true }))
			errorExists = true;
		}

		return errorExists;
	}


	const handleInputChange = (e: any) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const handleCheckboxChange = (e: any) => {
		setForm({ ...form, [e.target.name]: e.target.checked });
	};

	const validateCheckboxes = () => {
		if (form.checkboxContatoEmail || form.checkboxContatoTelefone || form.checkboxContatoWhatsapp) {
			return true
		}
		return false
	}

	async function eventoSubmit(e: any) {
		e.preventDefault()
		let validationError = validateFields()
		let validationCheckboxes = validateCheckboxes()
		if (!validationCheckboxes) {
			showAlertApp("Selecione uma forma de contato", 'warning', 5000)
			return
		}
		if (!validationError && validationCheckboxes) {
			setIsSubmitting(true)
			await contatoService.submitFaleConosco(form, config.apiUrl).then((resp) => {
				props.setOldComponent("fale-conosco")
				setIsSubmitting(false)
				props.modifyActiveComponent('submitted')
			}).catch((err) => {
				showAlertApp("Ocorreu um erro, tente novamente mais tarde", 'error', 5000)
				setIsSubmitting(false)
			})
		}
	}

	const { t } = useTranslation('contato')

	return (

		<>

			<ContentHeader
				image={props.image}
			>
				<h1 className='content-header-fale-conosco'>{t("Contato")}</h1>
			</ContentHeader>

			<div className='form-contato-container'>
				<div className='page-content'>

					{/*Parágrafo principal*/}
					<p className='form-contato-initial-paragraph'>{t("Para dúvidas e sugestões, entre em contato conosco preenchendo o formulário abaixo!")}</p>
				</div>

				<FormSwitcherButtons modifyActiveComponent={props.modifyActiveComponent} activeComponent={props.activeComponent} />

				<div className='form-container'>
					<form onSubmit={(e) => eventoSubmit(e)}>

						{/*Início dos inputs do formulário*/}
						<Grid container spacing={2}>

							{/*Nome*/}
							<Grid item xs={12}>
								<h1 className="h1-form-title">{t("Informações")}</h1>
								<TextField
									fullWidth
									id="nome"
									type="text"
									name="nome"
									aria-label="Nome:"
									variant="filled"
									onChange={handleInputChange}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start" disablePointerEvents>
												<span className='form-label'>
													{t("Nome: ")}
												</span>

											</InputAdornment>
										),
									}}
								/>
							</Grid>

							{/*E-mail e telefone*/}
							<Grid item container spacing={1}>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										id="email"
										type="email"
										name="email"
										aria-label="E-mail:"
										variant="filled"
										onChange={handleInputChange}
										error={!validEmail} onBlur={(e) => validation("email", form.email)}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start" disablePointerEvents>
													<span className='form-label'>
														{t("E-mail: ")}
													</span>

												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<InputMask
										mask={"(99) 99999-9999"}
										onBlur={(e) => validation("telefone", form.telefone)}
										onChange={handleInputChange}
									>
										{/* @ts-ignore */}
										{() => (
											
											<TextField
												fullWidth
												id="telefone"
												type="tel"
												name="telefone"
												aria-label="Telefone:"
												variant="filled"
												error={!validTelefone}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start" disablePointerEvents>
															<span className='form-label'>
																{t("Telefone: ")}
															</span>

														</InputAdornment>
													),
												}}
											/>
										)}
									</InputMask>
								</Grid>
							</Grid>


							<Grid item container spacing={1}>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										id="pais"
										type="text"
										name="pais"
										aria-label="País:"
										variant="filled"
										onChange={handleInputChange}
										error={errors.pais}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start" disablePointerEvents>
													<span className='form-label'>
														{t("País: ")}
													</span>

												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										id="estado"
										type="text"
										name="estado"
										aria-label="Estado:"
										variant="filled"
										onChange={handleInputChange}
										error={errors.estado}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start" disablePointerEvents>
													<span className='form-label'>
														{t("Estado: ")}
													</span>

												</InputAdornment>
											),
										}}
									/>
								</Grid>
							</Grid>


							{/*Mensagem*/}
							<Grid item xs={12} className="fale-conosco-mensagem-adornment">
								<TextField
									fullWidth
									id="mensagem"
									type="text"
									name="mensagem"
									aria-label="Mensagem:"
									variant="filled"
									multiline rows={6}
									onChange={handleInputChange}
									InputProps={{
										startAdornment: (
											<InputAdornment
												className="fale-conosco-mensagem-adornment"
												position='start'
												disablePointerEvents>

												<div>{t("Mensagem: ")}</div>

											</InputAdornment>
										),
									}}
								/>
							</Grid>

							{/*Final dos inputs do formulário*/}
						</Grid>


						{/*Botões para entrar em contato*/}
						<FormGroup className='form-contato-options'>
							<p className='form-contato-options_paragraph'>{t("Podemos entrar em contato com você por: ")}</p>
							<FormControlLabel
								control={
									<Checkbox
										value='telefone'
										name='checkboxContatoTelefone'
										onChange={handleCheckboxChange}
										checkedIcon={<CloseIcon />}
									/>
								}
								label={
									<Trans t={t} i18nKey="Telefone">
										Telefone
									</Trans>
								}
							/>
							<FormControlLabel
								control={
									<Checkbox
										value='email'
										name='checkboxContatoEmail'
										onChange={handleCheckboxChange}
										checkedIcon={<CloseIcon />}
									/>
								}
								label={
									<Trans t={t} i18nKey="E-mail">
										E-mail
									</Trans>
								}
							/>
							<FormControlLabel
								control={
									<Checkbox
										value='whatsapp'
										name='checkboxContatoWhatsapp'
										onChange={handleCheckboxChange}
										checkedIcon={<CloseIcon />}
										color="info"
									/>
								}
								label={
									<Trans t={t} i18nKey="WhatsApp">
										WhatsApp
									</Trans>
								}
							/>
						</FormGroup>


						{/*Botões para aceitar os termos do site e política de privacidade*/}
						<FormGroup>
							<FormControlLabel
								label={
									<Trans t={t} i18nKey="Declaro aceitar os termos do site e a política de privacidade.">
										Declaro aceitar os termos do site e a política de privacidade.
									</Trans>
								}
								className='form-contato-termos_paragraph'
								control={
									<Checkbox
										name='checkboxTermos'
										onChange={handleCheckboxChange}
										checkedIcon={<CloseIcon />}
										color="info"
										required
									/>
								}

							/>
						</FormGroup>

						{/* Botão para aceitar o termo de consentimento */}
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										name='checkboxTermos'
										onChange={handleCheckboxChange}
										checkedIcon={<CloseIcon />}
										color="info"
										required
									/>
								}
								label={
									<Trans t={t} i18nKey="Declaro aceitar os termos de consentimento para tratamento dos dados.">
										Declaro aceitar os termos de consentimento para tratamento dos dados.
									</Trans>
								}
							/>
						</FormGroup>

						{/*Submit button*/}
						<Button
							type='submit'
							variant="contained"
							className='form-contato-submit-button btn btn-contained-secondary'
							disableElevation
							//onClick={(e) => eventoSubmit(e)}
							disabled={isSubmitting}
						>
							{t("Enviar")}
						</Button>


					</form>
				</div>
			</div>

		</>

	);
}
export default FaleConosco;