import React, { useCallback, useState } from "react";
import { useBoardContext } from "@beontag/tageditor";
import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { FileEarmark, CloudDownload } from "react-bootstrap-icons";
import { useEditorContext } from "../../../../../context/editorContext/EditorContext";
import { useMessage } from "../../../../../context/alertContext/context";
import IProdutoCardPimaco from "../../../../../interfaces/produto/produtoCardPimaco";
import { useTranslation } from "react-i18next";

interface IExtra {
  tagData: {
    sku: string;
  };
}

const Open = (props: {
  setOpenModalMinhasEtiquetas: Function;
  onLoadEvent?: Function;
  vertical: boolean;
  openOnHover: boolean;
}) => {
  const { boardFile } = useBoardContext();
  const { fileExtension, selecionarProduto, produtosEditor } =
    useEditorContext();

  const { showAlertApp } = useMessage();
  const { t } = useTranslation("editor");
  const onLoadEvent = (ev: ProgressEvent<FileReader>) => {
    const json = ev?.target?.result as string;
    boardFile
      ?.import(json)
      .then((data) => {
        if (data.extra) {
          const produtoSelecionado = produtosEditor.find(
            (x) => x.sku == (data.extra! as IExtra).tagData.sku
          );
          if (produtoSelecionado) selecionarProduto(produtoSelecionado);
        } else {
          showAlertApp("Produto inválido", "warning", 5000);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const upload = useCallback(() => {
    const inputEl = document.createElement("input");
    inputEl.accept = fileExtension;
    inputEl.type = "file";
    inputEl.hidden = true;
    inputEl.onchange = (e) => {
      const element = e.target as HTMLInputElement;
      if (element?.files?.length) {
        const reader = new FileReader();
        reader.onload = (ev) => {
          if (props.onLoadEvent) {
            props.onLoadEvent(ev);
          } else {
            onLoadEvent(ev);
          }
        };
        reader.readAsText(element.files[0]);
      }
    };
    document.body.appendChild(inputEl); // required for firefox
    inputEl.click();
    inputEl.remove();
  }, [boardFile]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className="btn btn-header-editor"
        startIcon={<FileEarmark size={28} />}
        sx={{ flexDirection: props.vertical ? "column" : "row" }}
        onClick={handleClick}
        onMouseOver={props.openOnHover ? handleClick : undefined}
      >
        {t("Abrir")}
      </Button>
      <Menu
        elevation={1}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ onMouseLeave: handleClose }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={upload}>
          <ListItemIcon>
            <FileEarmark size={20} />
          </ListItemIcon>
          {t("Abrir do computador")}
        </MenuItem>
        <MenuItem onClick={() => props.setOpenModalMinhasEtiquetas(true)}>
          <ListItemIcon>
            <CloudDownload size="20" />
          </ListItemIcon>
          {t("Abrir da nuvem")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Open;
