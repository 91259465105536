import { useState, useCallback } from "react";
import bgImage from "../../../assets/bg_login_lateral.webp";
import CadastroSoftware from "./cadastroSoftware";
import CriarNovaSenha from "./criarNovaSenha";
import EsqueciSenha from "./esqueciSenha";
import Login from "./login";
import { useTranslation } from "react-i18next";

function Authentication(props: {
  starterComponent?: string;
  userLogged: boolean;
}) {
  const [activeComponent, setActiveComponent] = useState(
    props.starterComponent || "login"
  );

  const modifyActiveComponent = useCallback(
    (newActiveComponent: string) => {
      setActiveComponent(newActiveComponent);
    },
    [setActiveComponent]
  );

  return (
    <>
      <div className="login-container" key="login-container">
        {!props.userLogged && (
          <>
            {activeComponent === "login" && (
              <Login modifyActiveComponent={modifyActiveComponent} />
            )}
            {activeComponent === "esqueci-senha" && (
              <EsqueciSenha modifyActiveComponent={modifyActiveComponent} />
            )}
            {activeComponent === "criar-nova-senha" && (
              <CriarNovaSenha modifyActiveComponent={modifyActiveComponent} />
            )}
            {activeComponent === "cadastro-software" && (
              <CadastroSoftware modifyActiveComponent={modifyActiveComponent} />
            )}

            <div className="editor-image" key="editor-image">
              <img className="editor-login-image-img" src={bgImage} alt="" />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Authentication;
