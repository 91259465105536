import { Button } from "@mui/material"
import { IDownloadBox } from "../../interfaces/downloadBox"

export default function DownloadBox(dados: IDownloadBox) {

    return (

        <div className="pimaco-download-box-container">

            {dados.iconImage}

            <div className="pimaco-download-box-info-container">

                <div className="pimaco-download-box-text-container">

                    {dados.code ? <p className="pimaco-download-box-code">{dados.code}</p> : '' }
                    <p className="pimaco-download-box-titulo">{dados.titulo}</p>
                    
                    <a
                        href={dados.downloadLink}
                        className="btn-link"
                        download
                    >
                        <Button
                            disableElevation
                            variant="outlined"
                            className="btn btn-outlined-primary"
                            fullWidth
                        >
                            {dados.downloadButtonText}
                        </Button>
                    </a>
                    

                </div>

            </div>

        </div>
    )
}