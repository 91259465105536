import { useEffect } from "react";
import {
  Fonts,
  useBoardContext,
  useKeysCombinationHandler,
  usePropertyContext,
} from "@beontag/tageditor";
import { fabric } from "fabric";
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import {
  TypeBold,
  TypeItalic,
  TypeStrikethrough,
  TypeUnderline,
  TextLeft,
  TextCenter,
  TextRight,
  Justify,
} from "react-bootstrap-icons";
import OverlineIcon from "@mui/icons-material/FormatOverline";
import { ArrowsMove, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const DataFieldText: React.FunctionComponent = () => {
  const { properties, setProperty } =
    usePropertyContext<fabric.DataFieldText>();

  const keysPressCountBold = useKeysCombinationHandler(["Control", "b"]);
  useEffect(() => {
    if (keysPressCountBold) {
      fontWeightToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysPressCountBold]);

  const keysPressCountItalic = useKeysCombinationHandler(["Control", "i"]);
  useEffect(() => {
    if (keysPressCountItalic) {
      fontStyleToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysPressCountItalic]);

  const fontWeightToggle = () => {
    setProperty({
      fontWeight: properties.fontWeight === "normal" ? "bold" : "normal",
    });
  };

  const fontStyleToggle = () => {
    setProperty({
      fontStyle: properties.fontStyle === "normal" ? "italic" : "normal",
    });
  };

  const handleOnChange = (
    key: string,
    rawValue: string | boolean,
    type?: "int"
  ) => {
    let value: string | boolean | number = rawValue;

    if (type === "int" && typeof rawValue === "string") {
      value = Number.parseInt(rawValue);
    }

    setProperty({ [key]: value });
  };

  const handleOnChangeBooleanProp = (key: string, rawValue: boolean) => {
    setProperty({ [key]: rawValue });
  };

  const { board } = useBoardContext();

  const closeWindow = () => {
    board?.setActiveObjects();
  };

  const { t } = useTranslation("editor");

  return (
    <>
      <div className="property-bar-title">
        {t("Propriedades do objeto")}
        <ArrowsMove size={20} className="icon left" />
        <IconButton
          onClick={() => closeWindow()}
          className="icon right not-draggable"
        >
          <XLg size={20} color="white" />
        </IconButton>
      </div>

      <Grid container className="tb-main-grid">
        <Grid item md={3}>
          <div className="property-bar-text-itens">
            <span>{t("Nome do campo")}</span>
            <Select
              value={properties.fieldName || ""}
              onChange={(e) => handleOnChange("fieldName", e.target.value)}
              variant="filled"
              className="barcode-legenda-select"
            >
              {properties.dataSource?.dataSource?.keys?.map(
                (fieldNameOption, index) => {
                  return (
                    <MenuItem value={fieldNameOption} key={index}>
                      {fieldNameOption}{" "}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </div>
          <div className="property-bar-text-itens">
            <span>{t("Fonte")}</span>
            <Select
              value={properties.fontFamily || "Times New Roman"}
              onChange={(e) => handleOnChange("fontFamily", e.target.value)}
              variant="filled"
              className="barcode-legenda-select"
            >
              {Fonts.getList().map((font, index) => {
                return (
                  <MenuItem value={font} key={index}>
                    {" "}
                    {font}{" "}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="property-bar-text-itens">
            <label htmlFor="cor-do-objeto" className="cursor-pointer">
              {t("Cor do Objeto")}
            </label>
            <input
              id="cor-do-objeto"
              className="property-bar-text-color-input"
              type="color"
              value={properties.fill?.toString() || "#000"}
              onChange={(e) => handleOnChange("fill", e.currentTarget.value)}
            ></input>
          </div>
          <div className="property-bar-text-itens">
            <label htmlFor="cor-da-borda" className="cursor-pointer">
              {t("Cor da Borda")}
            </label>
            <input
              id="cor-da-borda"
              className="property-bar-text-color-input"
              type="color"
              value={properties.stroke?.toString() || "#000"}
              onChange={(e) => handleOnChange("stroke", e.currentTarget.value)}
            ></input>
          </div>
          <div className="property-bar-text-itens">
            <span>{t("Largura da borda")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 9, step: 1 } }}
              value={properties.strokeWidth?.toString() || 0}
              onChange={(e) =>
                handleOnChange("strokeWidth", e.currentTarget.value, "int")
              }
              variant="filled"
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className="property-bar-text-itens">
            <label htmlFor="tamanho-da-fonte" className="cursor-pointer">
              {t("Tamanho da fonte")}
            </label>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { min: 0, step: 1 } }}
              value={properties.fontSize || 15}
              onChange={(e) =>
                handleOnChange("fontSize", e.currentTarget.value)
              }
              variant="filled"
            />
          </div>

          <div className="property-bar-text-itens">
            <label htmlFor="tamanho-da-fonte" className="cursor-pointer">
              {t("Transparencia")}
            </label>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
              value={properties.opacity || 1}
              onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
              variant="filled"
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          {/* Text alignment. Possible values: "left", "center", "right", "justify", "justify-left", "justify-center" or "justify-right". */}
          <Stack
            direction="row"
            className="property-bar-text-options-container"
          >
            <IconButton
              aria-label="Negrito"
              size="large"
              onClick={(_) => handleOnChange("textAlign", "left")}
              className={`property-bar-text-options-button ${
                properties.textAlign == "left" ? "active" : ""
              }`}
            >
              <TextLeft />
            </IconButton>

            <IconButton
              aria-label="Itálico"
              size="large"
              onClick={(_) => handleOnChange("textAlign", "center")}
              className={`property-bar-text-options-button ${
                properties.textAlign == "center" ? "active" : ""
              }`}
            >
              <TextCenter />
            </IconButton>

            <IconButton
              aria-label="Sublinhado"
              size="large"
              onClick={(_) => handleOnChange("textAlign", "right")}
              className={`property-bar-text-options-button ${
                properties.textAlign == "right" ? "active" : ""
              }`}
            >
              <TextRight />
            </IconButton>

            <IconButton
              aria-label="Sobrelinha"
              size="large"
              onClick={(_) => handleOnChange("textAlign", "justify")}
              className={`property-bar-text-options-button ${
                properties.textAlign == "justify" ? "active" : ""
              }`}
            >
              <Justify />
            </IconButton>

            {/* <IconButton
							aria-label="Tachado"
							size="large"
							onClick={() => {
								if (properties.linethrough) {
									handleOnChangeBooleanProp("linethrough", false);
								} else {
									handleOnChangeBooleanProp("linethrough", true)
								}
							}}
							className={`property-bar-text-options-button ${properties.linethrough ? 'active' : ''}`}>
							<TypeStrikethrough />
						</IconButton> */}
          </Stack>
          <Stack
            direction="row"
            className="property-bar-text-options-container"
          >
            <IconButton
              aria-label="Negrito"
              size="large"
              onClick={fontWeightToggle}
              className={`property-bar-text-options-button ${
                properties.fontWeight == "bold" ? "active" : ""
              }`}
            >
              <TypeBold />
            </IconButton>

            <IconButton
              aria-label="Itálico"
              size="large"
              onClick={fontStyleToggle}
              className={`property-bar-text-options-button ${
                properties.fontStyle == "italic" ? "active" : ""
              }`}
            >
              <TypeItalic />
            </IconButton>

            <IconButton
              aria-label="Sublinhado"
              size="large"
              onClick={() => {
                if (properties.underline) {
                  handleOnChangeBooleanProp("underline", false);
                } else {
                  handleOnChangeBooleanProp("underline", true);
                }
              }}
              className={`property-bar-text-options-button ${
                properties.underline ? "active" : ""
              }`}
            >
              <TypeUnderline />
            </IconButton>

            <IconButton
              aria-label="Sobrelinha"
              size="large"
              onClick={() => {
                if (properties.overline) {
                  handleOnChangeBooleanProp("overline", false);
                } else {
                  handleOnChangeBooleanProp("overline", true);
                }
              }}
              className={`property-bar-text-options-button ${
                properties.overline ? "active" : ""
              }`}
            >
              <OverlineIcon />
            </IconButton>

            <IconButton
              aria-label="Tachado"
              size="large"
              onClick={() => {
                if (properties.linethrough) {
                  handleOnChangeBooleanProp("linethrough", false);
                } else {
                  handleOnChangeBooleanProp("linethrough", true);
                }
              }}
              className={`property-bar-text-options-button ${
                properties.linethrough ? "active" : ""
              }`}
            >
              <TypeStrikethrough />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default DataFieldText;
