import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import icone from '../../assets/icons/duvidas_frequentes_home.webp'

export default function VisiteDuvidasFrequentesPimaco () {

	const { t } = useTranslation('homepage')

    return (
        <div className="visite-duvidas-container">
            <div className="visite-duvidas-image-container">
                <img alt="" src={icone}/>
            </div>
            <div className="visite-duvidas-text-container">
                <h1>{t("Visite nossa seção de dúvidas frequentes")}</h1>
                
                <Link
                    to="/faq"
                    className="btn-link"
                >           
                    <Button
                        variant="outlined"
                        className="btn btn-outlined-primary"
                    >
                        {t("Acessar")}
                    </Button>
                </Link>
            </div>
        </div>
    )
}