import { IconButton, Paper, Tooltip } from "@mui/material";
import { InfoCircle } from "react-bootstrap-icons";

import React from "react";
import { useEditorContext } from "../../../../../context/editorContext/EditorContext";
import { useTranslation } from "react-i18next";

function DadosEtiqueta() {
  const { produtoSelecionado } = useEditorContext();
  const { t } = useTranslation("editor");
  return (
    <>
      {produtoSelecionado && (
        <div className="statusbar-container info-container">
          <Tooltip
            classes={{ tooltip: "produto-info-tooltip" }}
            placement="top-start"
            title={
              <React.Fragment>
                <Paper elevation={2}>
                  <aside className="editor-menu-prod-selecionado-info-container tooltip">
                    <div className="editor-menu-prod-selecionado-content">
                      <img
                        src={produtoSelecionado?.imagens[0]}
                        title="Imagem do produto"
                      />
                      <div className="editor-menu-prod-selecionado-data">
                        <b>{t("Produto selecionado:")}</b>
                        <p>{produtoSelecionado?.nome}</p>
                        <p>
                          <b>{produtoSelecionado?.codigo}</b>
                        </p>
                        <p>{produtoSelecionado?.forma}</p>
                        <p>
                          <b>{produtoSelecionado?.folhas}</b>
                        </p>
                      </div>
                    </div>
                  </aside>
                </Paper>
              </React.Fragment>
            }
          >
            <IconButton
              onClick={() => undefined}
              size="small"
              className={`cursor-button`}
            >
              <InfoCircle />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default DadosEtiqueta;
