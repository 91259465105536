import { AddBarCodeFeature, AddBarCodeFeatures } from "@beontag/tageditor";
import { Button } from "@mui/material";
import { Image, QrCode } from "@mui/icons-material";
import { useCallback, useState } from "react";

import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { ArrowButtonIcon } from "../../../../../components/common/icons";
import { useTranslation } from "react-i18next";

export default function QRBarcode(props: { setMenuAnchorEl: Function }) {
  const [barCodeFeature, setBarCodeFeature] = useState<AddBarCodeFeatures>();

  const addQrCode = useCallback(
    (e: any) => {
      barCodeFeature?.add({
        barCodeType: "qrcode",
        barCodeText: "Valor do QRCode",
      });
      props.setMenuAnchorEl(e.target);
    },
    [barCodeFeature]
  );
  const { t } = useTranslation("editor");
  return (
    <>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            {t("QR e Código de Barras")}
            <ArrowButtonIcon onClick={() => undefined} />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <AddBarCodeFeature featureUpdate={(f) => setBarCodeFeature(f)}>
            <div className="editor-tb-button-container">
              <Button
                className="btn btn-outlined-primary"
                startIcon={<QrCode />}
                variant="outlined"
                component="label"
                onClick={(e) => addQrCode(e)}
              >
                {" "}
                {t("QR e Código de Barras")}
              </Button>
            </div>
          </AddBarCodeFeature>
        </AccordionItemPanel>
      </AccordionItem>
    </>
  );
}
