import api from '../api'
import { IEtiquetaUsuario } from "../interfaces/editor/etiquetaUsuario";

const getEtiqueta = async function(id: number) {

    return api.get(`usuarioEtiquetas/${id}`);
}

const listEtiquetasUsuario = async function() {

    return api.get('usuarioEtiquetas');
}

const saveEtiqueta = async function(userData: { [key:string]: string}) {

    return api.post('usuarioEtiquetas', userData)
}

const updateEtiqueta = async function (etiqueta: IEtiquetaUsuario) {

    return api.put(`usuarioEtiquetas/${etiqueta.idUsuarioEtiqueta}`, etiqueta)

}

const deleteEtiqueta = async function (id: number) {

    return api.delete(`usuarioEtiquetas/${id}`)
}


const etiquetasUsuarioService = {
    getEtiqueta,
    listEtiquetasUsuario,
    saveEtiqueta,
    updateEtiqueta,
    deleteEtiqueta
}

export default etiquetasUsuarioService;