import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function FormSwitcherButtons(props: { modifyActiveComponent: Function, activeComponent: string }) {

	const { t } = useTranslation('contato')

    return (
        <div className='contato-button-switcher-container'>     
            <Button
                variant={props.activeComponent == 'fale-conosco' ? 'contained' : 'outlined'}
                className={`btn btn-${props.activeComponent == 'fale-conosco' ? 'contained-thertiary' : 'outlined-primary'}`}
                onClick={() => props.modifyActiveComponent('fale-conosco')}
                disableElevation
            >
                {t("Fale conosco")}
            </Button>

            <Button
                variant={props.activeComponent == 'trabalhe-conosco' ? 'contained' : 'outlined'}
                className={`btn btn-${props.activeComponent == 'trabalhe-conosco' ? 'contained-thertiary' : 'outlined-primary'}`}
                onClick={() => props.modifyActiveComponent('trabalhe-conosco')}
                disableElevation
            >
                {t("Trabalhe conosco")}
            </Button>
        </div>
    )
}