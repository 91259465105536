import impressao from '../../../assets/banners_categorias/Impressao.webp';

export default function CategoriaHeaderBase(props: {
    image: string,
    position?: string
}) {

    return (
        <>
            <div className="categoria-header-base-container">
                <div className="categoria-header-base-image-container">
                    <img src={props.image != '' ? props.image : impressao} className={`categoria-header-base-image ${props.position}`} rel="preload" fetchpriority='high' />
                </div>

            </div>
        </>
    )
}