import api from '../api'
import { IFormFaleConosco } from "../interfaces/form/form-fale-conosco";
import { IFormTrabalheConosco } from "../interfaces/form/form-trabalhe-conosco";

const submitFaleConosco = async function(dados:IFormFaleConosco, baseURL: string) {
    var formData = new FormData();

    if (dados.nome !== null) {
        formData.append("Nome", dados.nome);
    }

    if (dados.email !== null) {
        formData.append("Email", dados.email);
    }

    if (dados.telefone !== null) {
        formData.append("Telefone", dados.telefone);
    }

    if (dados.pais !== null) {
        formData.append("Pais", dados.pais.toString());
    }

    if (dados.estado !== null) {
        formData.append("Estado", dados.estado.toString());
    }

    if (dados.mensagem !== null) {
        formData.append("Mensagem", dados.mensagem);
    }

    if (dados.checkboxContatoTelefone !== null && dados.checkboxContatoEmail !== null && dados.checkboxContatoWhatsapp != null) {
        let value: number = 0;
        if (dados.checkboxContatoEmail)
            value = value + 1;
        if (dados.checkboxContatoTelefone)
            value = value + 2;
        if (dados.checkboxContatoWhatsapp)
            value = value + 4;
        formData.append("Contato", value.toString());
    }

    return api.post(`${baseURL}/contato`, formData);
}


const submitTrabalheConosco = async function(dados:IFormTrabalheConosco, baseURL: string) {
    var formData = new FormData();

    //Dados pessoais
    if (dados.nome !== null) {
        formData.append("Nome", dados.nome);
    }

    if (dados.email !== null) {
        formData.append("Email", dados.email);
    }

    if (dados.telefone !== null) {
        formData.append("Telefone", dados.telefone);
    }

    if (dados.endereco !== null) {
        formData.append("Endereco", dados.endereco);
    }

    if (dados.numero !== null) {
        formData.append("Numero", dados.numero.toString());
    }

    if (dados.cep !== null) {
        formData.append("CEP", dados.cep.toString());
    }

    if (dados.estado !== null) {
        formData.append("Estado", dados.estado.toString());
    }

    if (dados.cidade !== null) {
        formData.append("Cidade", dados.cidade.toString());
    }

    if (dados.curriculo !== undefined) {
        formData.append("Curriculo", dados.curriculo);
    }

    if (dados.linkedin !== null) {
        formData.append("PerfilLinkedIn", dados.linkedin);
    }

    return api.post(`${baseURL}/contato`, formData);

}


const contatoService = {
    submitFaleConosco,
    submitTrabalheConosco,
};

export default contatoService;