import { useTranslation } from "react-i18next";
import ContentHeader from "../common/content-header";

export default function TelaAgradecimento(props: { image: string, title: string }) {

	const { t } = useTranslation('contato')

    return (

        <>
            <ContentHeader
                image={props.image}
            >
                <h1 className={`content-header-${props.title == "Contato" ? 'fale-conosco' : 'trabalhe-conosco'}`}>{props.title}</h1>
            </ContentHeader>

            <div className="tela-agradecimento-container">
                <div className="page-content">
                    <h1 className="tela-agradecimento-titulo">
                        {t("Enviado!")}
                    </h1>

                    <p className="tela-agradecimento-text">
                        {t("Entraremos em contato o quanto antes.")}
                    </p>
                </div>
            </div>
        </>
    )
}