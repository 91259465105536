import escolar from '../../../assets/banners_categorias/Escolar.webp';
import escritorio from '../../../assets/banners_categorias/Corporativo.webp';
import impressao from '../../../assets/banners_categorias/Impressao.webp';
import domestico from '../../../assets/banners_categorias/Domestico.webp';
import CartoesParaNegocio from '../../../assets/banners_categorias/CartoesParaNegocio.webp';
import EtiquetasEmRolo from '../../../assets/banners_categorias/Etiquetas_em_Rolo.webp';
import PapelFotografico from '../../../assets/banners_categorias/PapelFotografico.webp';
import Sinalizacao from '../../../assets/banners_categorias/Sinalizacao.webp';
import SLP from '../../../assets/banners_categorias/SLP.webp';
import TransferCamisetas from '../../../assets/banners_categorias/TransferCamisetas.webp';
import BlocosAdesivos from '../../../assets/banners_categorias/BlocosAdesivos.webp';
import FilmesAdesivos from '../../../assets/banners_categorias/Filmes_Adesivos.webp'

import escolar_mobile from '../../../assets/banners_categorias/mobile/Escolar_Mobile.webp'
import escritorio_mobile from '../../../assets/banners_categorias/mobile/Corporativo_Mobile.webp'
import impressao_mobile from '../../../assets/banners_categorias/mobile/Impressao_Mobile.webp'
import domestico_mobile from '../../../assets/banners_categorias/mobile/Domestico_Mobile.webp'
import CartoesParaNegocio_mobile from '../../../assets/banners_categorias/mobile/CartoesParaNegocio_Mobile.webp'
import EtiquetasEmRolo_mobile from '../../../assets/banners_categorias/mobile/Etiquetas_em_Rolo_Mobile.webp' 
import PapelFotografico_mobile from '../../../assets/banners_categorias/mobile/PapelFotografico_Mobile.webp'
import Sinalizacao_mobile from '../../../assets/banners_categorias/mobile/Sinalizacao_Mobile.webp'
import SLP_mobile from '../../../assets/banners_categorias/mobile/SLP_Mobile.webp'
import TransferCamisetas_mobile from '../../../assets/banners_categorias/mobile/TransferCamisetas_Mobile.webp'
import BlocosAdesivos_mobile from '../../../assets/banners_categorias/mobile/BlocosAdesivos_Mobile.webp'
import FilmesAdesivos_Mobile from '../../../assets/banners_categorias/mobile/Filmes_Adesivos_Mobile.webp'

interface IDevice {
    desktop: { [key: string]: string},
    mobile: { [key: string]: string},
}

interface IBannerCategoriaImage {
    id: number,
    image: IDevice,
    position: string,
    aliases: string[],
    uso?: string,
}

export const categorias_data: IBannerCategoriaImage[] = [
    {
        // Escolar
        id: 1,
        image: {
            desktop: {
                pt: escolar,
                en: escolar,
                es: escolar,
            },
            mobile: {
                pt: escolar_mobile,
                en: escolar_mobile,
                es: escolar_mobile,
            },
        },
        position: "right",
        aliases: ['etiqmatesc', 'etiqestesc', 'etiqcoresc', 'etiqredesc', 'etiqrefesc', 'etiqmaresc', 'etiqretesc', 'fiadtraesc'],
        uso: 'Escolar'
    },
    {
        // Cartões para seu negócio
        id: 2,
        image: {
            desktop: {
                pt: CartoesParaNegocio,
                en: CartoesParaNegocio,
                es: CartoesParaNegocio,
            },
            mobile: {
                pt: CartoesParaNegocio_mobile,
                en: CartoesParaNegocio_mobile,
                es: CartoesParaNegocio_mobile,
            },
        },
        position: "bottom right",
        aliases: ['cartneg', 'cartnegvis']
    },
    {
        // Domestico
        id: 3,
        image: {
            desktop: {
                pt: domestico,
                en: domestico,
                es: domestico,
            },
            mobile: {
                pt: domestico_mobile,
                en: domestico_mobile,
                es: domestico_mobile,
            },
        },
        position: "left",
        aliases: ['etiqcondom', 'etiqreddom', 'etiqpredom', 'etiqestdom', 'etiqcordom', 'etiqorgdom', 'etiqtradom', 'etiqfludom', 'etiqdecdom', 'etiqretdom', 'etiqsindom'],
        uso: 'Domestico'
    },
    {
        // Escritório/Corporativo
        id: 4,
        image: {
            desktop: {
                pt: escritorio,
                en: escritorio,
                es: escritorio,
            },
            mobile: {
                pt: escritorio_mobile,
                en: escritorio_mobile,
                es: escritorio_mobile,
            },
        },
        position: "right",
        aliases: ['etiqredcor', 'etiqrotcor', 'etiqprecor', 'etiqrolcor', 'etiqlaccor', 'etiqconcor', 'etiqrapcor', 'etiqmatcor', 'etiqinkcor', 'etiqurgcor', 'etiqdeccor', 'etiqcddcor', 'etiqtracor', 'etiqretcor', 'etiqestcor', 'etiqsincor', 'etiqlaccor'],
        uso: 'Corporativo'
    },
    {
        // Blocos adesivos
        id: 5,
        image: {
            desktop: {
                pt: BlocosAdesivos,
                en: BlocosAdesivos,
                es: BlocosAdesivos,
            },
            mobile: {
                pt: BlocosAdesivos_mobile,
                en: BlocosAdesivos_mobile,
                es: BlocosAdesivos_mobile,
            },
        },
        position: "left",
        aliases: ['bloade', 'bloadebloc', 'bloademarc']
    },
    {
        // Impressão
        id: 6,
        image: {
            desktop: {
                pt: impressao,
                en: impressao,
                es: impressao,
            },
            mobile: {
                pt: impressao_mobile,
                en: impressao_mobile,
                es: impressao_mobile,
            },
        },
        position: "left",
        aliases: ['etiqinkimp', 'etiqcddimp', 'etiqrotimp', 'etiqmatimp', 'etiqrapimp', 'etiqconimp', 'etiqtraimp', 'etiqfluimp', 'etiq']
    },
    {
        // Fotografia
        id: 7,
        image: {
            desktop: {
                pt: PapelFotografico,
                en: PapelFotografico,
                es: PapelFotografico,
            },
            mobile: {
                pt: PapelFotografico_mobile,
                en: PapelFotografico_mobile,
                es: PapelFotografico_mobile,
            },
        },
        position: "left",
        aliases: ['papfot', 'papfotmatt', 'papforglos']
    },
    {
        // Sinalização
        id: 8,
        image: {
            desktop: {
                pt: Sinalizacao,
                en: Sinalizacao,
                es: Sinalizacao,
            },
            mobile: {
                pt: Sinalizacao_mobile,
                en: Sinalizacao_mobile,
                es: Sinalizacao_mobile,
            },
        },
        position: "left",
        aliases: ['sinali', 'sinaliplac']    
    },
    {
        // SLP
        id: 9,
        image: {
            desktop: {
                pt: SLP,
                en: SLP,
                es: SLP,
            },
            mobile: {
                pt: SLP_mobile,
                en: SLP_mobile,
                es: SLP_mobile,
            },
        },
        position: "top right",
        aliases: ['imprslp', 'imprslpslp', 'imprslpeti'] 
    },
    {
        // Transfer para camiseta
        id: 10,
        image: {
            desktop: {
                pt: TransferCamisetas,
                en: TransferCamisetas,
                es: TransferCamisetas,
            },
            mobile: {
                pt: TransferCamisetas_mobile,
                en: TransferCamisetas_mobile,
                es: TransferCamisetas_mobile,
            },
        },
        position: "left",
        aliases: ['tracam', 'tracamtran']
    },
    {
        // Etiquetas em rolo
        id: 11,
        image: {
            desktop: {
                pt: EtiquetasEmRolo,
                en: EtiquetasEmRolo,
                es: EtiquetasEmRolo,
            },
            mobile: {
                pt: EtiquetasEmRolo_mobile,
                en: EtiquetasEmRolo_mobile,
                es: EtiquetasEmRolo_mobile,
            },
        },
        position: "left",
        aliases: ['etiqrolcor']
    },
    {
        // Etiquetas em rolo
        id: 12,
        image: {
            desktop: {
                pt: FilmesAdesivos,
                en: FilmesAdesivos,
                es: FilmesAdesivos,
            },
            mobile: {
                pt: FilmesAdesivos_Mobile,
                en: FilmesAdesivos_Mobile,
                es: FilmesAdesivos_Mobile,
            },
        },
        position: "top left",
        aliases: ['filade', 'fiadtracor', 'fiadtraesc']
    },
]