import ProdutoCard from "./produto-card/produtoCard";

import Slider from 'react-slick'
import NextPrevButtonGroup from "../common/nextPrevButtonGroup";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import IProdutoCardPimaco from "../../interfaces/produto/produtoCardPimaco";

export default function RelatedProducts(props: { produtosRelacionados: IProdutoCardPimaco[] }) {

    const settings = {
        infinite: true,
        slidesToShow: props.produtosRelacionados.length >= 4 ? 4 : props.produtosRelacionados.length, 
        arrows: false,
        responsive: [
            {
                breakpoint: 1566,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    }

    const customSlider = useRef()

    const goToNext = () => {
        // @ts-ignore
        customSlider.current.slickNext()
    }

    const goToPrev = () => {
        // @ts-ignore
        customSlider.current.slickPrev()
    }


    const { t, i18n, ready } = useTranslation('produtos')

    return (
        <section className="related-products-container">
            <div className="related-products-content-container">
            <h1>{t("Conheça outros produtos: ")}</h1>
            
            <Slider
                {...settings}
                // @ts-ignore
                ref={slider => (customSlider.current = slider)}
            >
                {props.produtosRelacionados ? props.produtosRelacionados.map((produto) => {
                    return (
                        <div className={`produto-card-container`}>
                            <ProdutoCard produto={produto} key={produto.idProduto} />
                        </div>
                    )
                }) : ''}
            </Slider>

            <NextPrevButtonGroup
                goToNext={goToNext}
                goToPrev={goToPrev}
            />
            </div>
        </section>
    )
}