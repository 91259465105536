import { Button, Grid, InputLabel, TextField } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";

import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { ArrowButtonIcon } from "../../../../../components/common/icons";
import {
  AddSequentialTextFeature,
  AddSequentialTextFeatures,
} from "@beontag/tageditor";
import { useTranslation } from "react-i18next";

export default function Numeration(props: { setMenuAnchorEl: Function }) {
  const [sequentialTextFeatureUserData, setSequentialTextFeatureUserData] =
    useState({
      sequenceStarts: 1,
      sequenceEnds: 1000,
      sequenceIncrement: 1,
      sequenceMask: "",
      sequencePrefix: "",
      sequenceSuffix: "",
    });

  const handleInputChange = (e: any) => {
    setSequentialTextFeatureUserData({
      ...sequentialTextFeatureUserData,
      [e.target.name]: e.target.value,
    });
  };

  const [sequentialTextFeature, setSequentialTextFeature] =
    useState<AddSequentialTextFeatures>();

  const handleAddFeature = (e: any) => {
    sequentialTextFeature?.add(sequentialTextFeatureUserData);
    props.setMenuAnchorEl(e.target);
  };
  const { t } = useTranslation("editor");
  return (
    <>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            {t("Numeração")}
            <ArrowButtonIcon onClick={() => undefined} />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <InputLabel
                id="area-input-label"
                className="produto-filter-input-label"
              >
                {t("Início")}
              </InputLabel>
              <TextField
                id="inicio"
                value={sequentialTextFeatureUserData.sequenceStarts}
                type="number"
                name="sequenceStarts"
                aria-label="Início:"
                variant="filled"
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel
                id="area-input-label"
                className="produto-filter-input-label"
              >
                {t("Fim")}
              </InputLabel>
              <TextField
                id="fim"
                value={sequentialTextFeatureUserData.sequenceEnds}
                type="number"
                name="sequenceEnds"
                aria-label="Fim:"
                variant="filled"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                id="area-input-label"
                className="produto-filter-input-label"
              >
                {t("Incremento")}
              </InputLabel>
              <TextField
                id="fim"
                type="number"
                value={sequentialTextFeatureUserData.sequenceIncrement}
                name="sequenceIncrement"
                aria-label="Incremento:"
                variant="filled"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                id="area-input-label"
                className="produto-filter-input-label"
              >
                {t("Prefixo")}
              </InputLabel>
              <TextField
                id="fim"
                type="text"
                value={sequentialTextFeatureUserData.sequencePrefix}
                name="sequencePrefix"
                aria-label="Prefixo:"
                variant="filled"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                id="area-input-label"
                className="produto-filter-input-label"
              >
                {t("Sufixo")}
              </InputLabel>
              <TextField
                id="fim"
                type="text"
                value={sequentialTextFeatureUserData.sequenceSuffix}
                name="sequenceSuffix"
                aria-label="Sufixo:"
                variant="filled"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <div className="editor-tb-button-container">
            <AddSequentialTextFeature
              featureUpdate={(f) => setSequentialTextFeature(f)}
            >
              <Button
                variant="outlined"
                className="btn btn-outlined-primary"
                onClick={handleAddFeature}
              >
                {t("INSERIR")}
              </Button>
            </AddSequentialTextFeature>
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    </>
  );
}
