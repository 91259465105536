import { useEffect, useState } from "react"

import VisiteDuvidasFrequentesLeituraEtiquetaPimaco from "../../../components/leitura-etiquetas/visite-duvidas-frequentes-leitura-etiquetas";
import EscolhaMelhorEtiquetaPimaco from "../../../components/landing-page/escolha-melhor-etiqueta-pimaco";

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import tamanhos_folhas from '../../../assets/leitura_etiquetas/tamanho_das_folhas.webp'
import A4 from '../../../assets/leitura_etiquetas/A4.webp'
import A5 from '../../../assets/leitura_etiquetas/A5.webp'
import carta from '../../../assets/leitura_etiquetas/carta.webp'

import embalagem_frente from '../../../assets/leitura_etiquetas/leitura-frente.webp'
import embalagem_tras from '../../../assets/leitura_etiquetas/leitura-tras.webp'

import embalagem_frente_en from '../../../assets/leitura_etiquetas/leitura-frente-en.webp'
import embalagem_tras_en from '../../../assets/leitura_etiquetas/leitura-tras-en.webp'

import embalagem_frente_es from '../../../assets/leitura_etiquetas/leitura-frente-es.webp'
import embalagem_tras_es from '../../../assets/leitura_etiquetas/leitura-tras-es.webp'

import { Grid } from "@mui/material";

const images: { [key: string]: any } = {
    pt: {
        frente: embalagem_frente,
        tras: embalagem_tras
    },
    en: {
        frente: embalagem_frente_en,
        tras: embalagem_tras_en
    },
    es: {
        frente: embalagem_frente_es,
        tras: embalagem_tras_es
    }
}

export default function LeituraEtiquetasPimaco() {

    const [modal, showModal] = useState<boolean>(false);

    const { i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.language);

    const theme = createTheme();
    const { t } = useTranslation("produtos");
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
		window.scroll({
            // @ts-ignore
			// eslint-disable-next-line no-restricted-globals
			top: top,
			left: 0,
            behavior: 'smooth'	
		})
	}, [])

    return (
        <>
            <EscolhaMelhorEtiquetaPimaco hideButton />

            <div className="leitura-etiquetas-description-container">
                <div className="leitura-etiquetas-description-content">
                    <h1>{t("Etiquetas inkjet e laser")}</h1>

                    <p className="page-paragraph">
                        {t("Etiquetas multiuso que são usadas para organizar, identificar, controlar e sinalizar. Podem ser usadas em impressoras a jato de tinta e laser e possuem 3 tamanhos: ")}
                    </p>

                    {matches ?
                        <>
                            <img src={tamanhos_folhas} />
                        </>
                        :
                        <>
                            <img src={A4} />
                            <img src={A5} />
                            <img src={carta} />
                        </>

                    }
                </div>

            </div>


            <div className="leitura-etiquetas-info-embalagem-container">
                <h1>{t("Informação na embalagem")}</h1>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <div className="leitura-etiquetas-info-embalagem-item">
                            <h3>{t("Frente")}</h3>
                            <img src={images[i18n.language]?.frente || embalagem_frente} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="leitura-etiquetas-info-embalagem-item">
                            <h3>{t("Trás")}</h3>
                            <img src={images[i18n.language]?.tras || embalagem_tras} />
                        </div>
                    </Grid>
                </Grid>
            </div>

            <VisiteDuvidasFrequentesLeituraEtiquetaPimaco />
        </>
    )
}