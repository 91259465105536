import { useTranslation } from "react-i18next";
import { IPimacoTimelineItem } from "../../interfaces/timeline/timelineItem";

export default function TimelineItem (props: {item:IPimacoTimelineItem}) {

    const { item } = props;

    const { t } = useTranslation('institucional')

    return ( 
        <div className="timeline-item-container">
            <div className="timeline-item-image-container">
                <img className="timeline-item-image" src={item.img} />
            </div>
            
            <div className="timeline-item-text-container">
                <h1>{t(item.title)}</h1>
                <p className="page-paragraph">{t(item.text)}</p>
            </div>
        </div>
    )
}