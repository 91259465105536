import i18n  from "i18next"
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'pt',
        supportedLngs: ['pt', 'en', 'es'],
        lowerCaseLng: true,
        ns: "common",
        defaultNS: "common",
        fallbackNS: "common",
        nsSeparator: '|',

        react: {
            useSuspense: false,
        },

        detection: {
            order: ['cookie', 'localStorage', 'querystring','path'],
            caches: ['cookie', 'localStorage'],
            lookupQuerystring: 'lng',
            lookupFromPathIndex: 1
        },

        //debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        } 
    });

export default i18n;