import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import como_ler_etiquetas from '../../assets/como_ler_as_etiquetas.webp'

export default function EscolhaMelhorEtiquetaPimaco(props: { hideButton?: boolean }) {

	const { t } = useTranslation('homepage')

    return (
        <section className="escolha-melhor-etiqueta-container">
            <div className="escolha-melhor-etiqueta-content">
                <div className="escolha-melhor-etiqueta-text-container">

                    <h1>{t("Você sabe qual a melhor etiqueta para você?")}</h1>
                    <p className="page-paragraph">{t("Buscando como identificar a etiqueta ideal? Nós te ajudamos!")}</p>

                    {!props.hideButton && 
                        <Link to="/leitura_etiquetas" className="btn-link">
                            <Button
                                variant="outlined"
                                className="btn btn-outlined-primary"
                            >
                                {t("Saiba mais")}
                            </Button>
                        </Link>
                    }
                </div>
                
                <div className="escolha-melhor-etiqueta-image-container">
                    <img alt="" src={como_ler_etiquetas} />
                </div>
            </div>
            
        </section>
    )
}