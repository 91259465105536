import { useTranslation } from 'react-i18next';
import { ICategoria } from '../../interfaces/produto/categoria';
import { Link } from 'react-router-dom';

export default function NavbarFooter(props: { showMore: boolean, categorias: ICategoria[], setShowMore: Function }) {

    const { t } = useTranslation('homepage');

    return (
        <>
            <nav className="navbar-footer">
                <ul>
                    <li className='product-li'>
                        <div className='list-item-item'>
                            <span
                                onClick={() => props.setShowMore(true)}
                            >
                                {t("PRODUTOS")}
                            </span>
                            
                            <ul className={`footer-navbar-ul ${props.showMore ? 'show' : ''}`}>
                                {props.categorias?.map((categoria) => {
                                    return (
                                        <li key={categoria.idCategoria}>
                                            <Link to={`/categoria/${categoria.alias}`} title={categoria.nome}>
                                                {categoria.nome}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>


                    </li>
                    <li>
                        <div className='list-item-item'>
                            {/* @ts-ignore */}
                            <Link to='/assistente_impressao' className='btn-link' title={t("SOFTWARE DE IMPRESSÃO")}>
                                {t("SOFTWARE DE IMPRESSÃO")}
                            </Link>
                        </div>
                    </li>
                    <li>
                        <div className='list-item-item'>
                            <span onClick={() => props.setShowMore(true)}>
                                {t("DOWNLOADS")}
                            </span>
                            <ul className={`footer-navbar-ul ${props.showMore ? 'show' : ''}`}>
                                <li>
                                    {/* @ts-ignore */}
                                    <Link to="/downloads/parametros" className='btn-link' title={t("PARÂMETROS")}>
                                        {t("PARÂMETROS")}
                                    </Link>
                                </li>
                                <li>
                                    {/* @ts-ignore */}
                                    <Link to="/downloads/gabaritos" className='btn-link' title={t("GABARITOS")}>
                                        {t("GABARITOS")}
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className='list-item-item'>
                            <span onClick={() => props.setShowMore(true)}>
                                {t("INSTITUCIONAL")}
                            </span>
                            <ul className={`footer-navbar-ul ${props.showMore ? 'show' : ''}`}>
                                <li>
                                    <Link to="/quemsomos" className='btn-link'>
                                        {t("QUEM SOMOS")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/politica_privacidade" className='btn-link'>
                                        {t("POLÍTICA DE PRIVACIDADE")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/politica_qualidade" className='btn-link'>
                                        {t("POLÍTICA DE QUALIDADE")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className='list-item-item'>
                            <Link to="/faq" className='btn-link'>
                                {t("DÚVIDAS FREQUENTES")}
                            </Link>
                        </div>
                    </li>
                    <li>
                        <div className='list-item-item'>
                            <Link to="/contato" className='btn-link'>
                                {t("CONTATO")}
                            </Link>
                        </div>
                    </li>
                </ul>
            </nav>

        </>
    )
}