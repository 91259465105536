import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import iconePimaco from "../../../assets/logo-ETQVRSEX.webp";

import CloseIcon from "@mui/icons-material/Close";

import sUsuario from "../../../services/usuarioService";
import { handleFormErrors } from "../../../helpers/formErrorHandler";

import { useMessage } from "../../../context/alertContext/context";
import { Trans, useTranslation } from "react-i18next";

function CadastroSoftware(props: { modifyActiveComponent: Function }) {
  const { showAlertApp } = useMessage();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form, setForm] = useState({
    nome: "",
    email: "",
    emailConfirmation: "",
    endereco: "",
    estado: "",
    cidade: "",
    checkboxTermosPrivacidade: false,
    checkboxTermosUsoDados: false,
  });

  const [errors, setErrors] = useState({
    nome: false,
    email: false,
    emailConfirmation: false,
    endereco: false,
    estado: false,
    cidade: false,
    checkboxTermos: false,
  });

  const handleInputChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.checked });
  };
  const { t, i18n } = useTranslation("editor");
  const handleCreateAccount = (e: any) => {
    e.preventDefault();

    if (handleFormErrors(form, errors, setErrors)) {
      return;
    }
    if (!form.checkboxTermosUsoDados || !form.checkboxTermosPrivacidade) {

      setErrors({ ...errors, checkboxTermos: true });
      showAlertApp(
        t("Por favor, aceite os termos para continuar"),
        "error",
        5000
      );
      return;
    }
    if (form.email !== form.emailConfirmation) {
      setErrors({ ...errors, emailConfirmation: true });
      showAlertApp(
        t("Email e confirmação de email diferentes, confirme os dados"),
        "error",
        5000
      );
      return;
    }

    setIsLoading(true);

    sUsuario
      .createAccount(form)
      .then((resp) => {
        setIsLoading(false);
        showAlertApp(
          t(
            "Conta criada com sucesso, acesse seu email para obter sua senha temporária"
          ),
          "success",
          5000
        );
        props.modifyActiveComponent("login");
      })
      .catch((err) => {
        setIsLoading(false);
        showAlertApp(
          t(
            "Houve um erro ao criar a conta, verifique seus dados e tente novamente"
          ),
          "error",
          5000
        );
      });
  };

  return (
    <div className="editor-login">
      <div className="editor-login-container">
        <div className="editor-login-authentication-image">
          <img
            src={iconePimaco}
            alt=""
            className="editor-login-authentication-image-img"
          />
        </div>

        <Grid container rowSpacing={2} className="editor-login-authentication">
          <Grid item xs={12}>
            <h1 className="editor-login-authentication-text">
              {t("Cadastro de usuário")}
            </h1>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="nome"
              type="text"
              name="nome"
              aria-label="Nome:"
              value={form.nome}
              onChange={handleInputChange}
              error={errors.nome}
              variant="filled"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    disablePointerEvents
                    className="editor-login-authentication-fields-adornment"
                  >
                    <span className="form-label">{t("Nome:")}</span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="email"
              type="email"
              name="email"
              aria-label="E-mail:"
              variant="filled"
              value={form.email}
              onChange={handleInputChange}
              error={errors.email}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    disablePointerEvents
                    className="editor-login-authentication-fields-adornment"
                  >
                    <span className="form-label">{t("E-mail")}:</span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              className="editor-login-authentication-fields"
              id="emailConfirmation"
              type="email"
              name="emailConfirmation"
              aria-label="E-mail:"
              value={form.emailConfirmation}
              onChange={handleInputChange}
              error={errors.emailConfirmation}
              variant="filled"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    disablePointerEvents
                    className="editor-login-authentication-fields-adornment"
                  >
                    <span className="form-label">
                      {t("Confirme seu E-mail:")}
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              className="editor-login-authentication-fields editor-request-access-endereco"
              name="endereco"
              variant="filled"
              value={form.endereco}
              onChange={handleInputChange}
              error={errors.endereco}
              multiline
              fullWidth
              rows={3}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    disablePointerEvents
                    className="editor-request-access-endereco-adornment"
                  >
                    {t("Endereço (Rua, Número e Bairro):")}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={6} sx={{ paddingRight: "16px" }}>
              <FormControl fullWidth>
                <Select
                  className="editor-request-access-grid-itens"
                  type="text"
                  name="estado"
                  variant="filled"
                  onChange={handleInputChange}
                  value={form.estado}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      disablePointerEvents
                      className="editor-login-authentication-fields-adornment"
                    >
                      <span className="form-label">{t("Estado:")}</span>
                    </InputAdornment>
                  }
                >
                  <MenuItem value="Acre">Acre</MenuItem>
                  <MenuItem value="Alagoas">Alagoas</MenuItem>
                  <MenuItem value="Amapa">Amapá</MenuItem>
                  <MenuItem value="Amazonas">Amazonas</MenuItem>
                  <MenuItem value="Bahia">Bahia</MenuItem>
                  <MenuItem value="Ceara">Ceará</MenuItem>
                  <MenuItem value="DistritoFederal">Distrito Federal</MenuItem>
                  <MenuItem value="EspiritoSanto">Espírito Santo</MenuItem>
                  <MenuItem value="Goias">Goiás</MenuItem>
                  <MenuItem value="Maranhao">Maranhão</MenuItem>
                  <MenuItem value="MatoGrosso">Mato Grosso</MenuItem>
                  <MenuItem value="MatoGrossoSul">Mato Grosso do Sul</MenuItem>
                  <MenuItem value="MinasGerais">Minas Gerais</MenuItem>
                  <MenuItem value="Para">Pará</MenuItem>
                  <MenuItem value="Paraiba">Paraíba</MenuItem>
                  <MenuItem value="Parana">Paraná</MenuItem>
                  <MenuItem value="Pernambuco">Pernambuco</MenuItem>
                  <MenuItem value="Piaui">Piauí</MenuItem>
                  <MenuItem value="RioJaneiro">Rio de Janeiro</MenuItem>
                  <MenuItem value="RioGrandeNorte">Rio Grande do Norte</MenuItem>
                  <MenuItem value="RioGrandeSul">Rio Grande do Sul</MenuItem>
                  <MenuItem value="Rondonia">Rondônia</MenuItem>
                  <MenuItem value="Roraima">Roraima</MenuItem>
                  <MenuItem value="SantaCatarina">Santa Catarina</MenuItem>
                  <MenuItem value="SaoPaulo">São Paulo</MenuItem>
                  <MenuItem value="Sergipe">Sergipe</MenuItem>
                  <MenuItem value="Tocantins">Tocantins</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="cidade"
                type="text"
                name="cidade"
                aria-label="cidade:"
                value={form.cidade}
                onChange={handleInputChange}
                error={errors.cidade}
                variant="filled"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      disablePointerEvents
                      className="editor-login-authentication-fields-adornment"
                    >
                      <span className="form-label">{t("Cidade:")}</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div className="editor-login-cadastro-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    name="checkboxTermosPrivacidade"
                    checkedIcon={<CloseIcon />}
                    value={form.checkboxTermosPrivacidade}
                    onChange={handleCheckboxChange}
                  />
                }
                label={""}
              />
              <p className="fc-final-checkbox-label">
                <Trans
                  t={t}
                  i18nKey="Declaro aceitar os termos termos do site e a política de privacidade"
                >

                  <p>
                    Declaro aceitar os
                    <a
                      href={`/documents/parametros/Eula/DataPolicy_${i18n.language}.pdf`}
                      download
                      className="btn-link download-text"
                    ><strong>termos do site e a política de privacidade</strong></a>.
                  </p>

                </Trans>
              </p>
            </div>

            <div className="editor-login-cadastro-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    name="checkboxTermosUsoDados"
                    checkedIcon={<CloseIcon />}
                    value={form.checkboxTermosUsoDados}
                    onChange={handleCheckboxChange}
                  />
                }
                label={""}
              />

              <p className="fc-final-checkbox-label">
                <Trans
                  t={t}
                  i18nKey="Declaro aceitar os termos de consentimento para tratamento dos dados"
                >
                  <p>
                    Declaro aceitar os
                    <a
                      href={`/documents/parametros/Eula/Consent_Data.pdf`}
                      download
                      className="btn-link download-text"
                    >
                      <strong>termos de consentimento</strong>
                    </a>{" "}
                    para tratamento dos dados
                  </p>

                </Trans>
              </p>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              className="form-contato-submit-button btn btn-contained-primary"
              disableElevation
              onClick={handleCreateAccount}
            >
              {t("Cadastrar")}
            </Button>

            <br />
            <br />
            <a
              href={`/documents/parametros/Eula/Termos e Condições Gerais de Uso de Software Pimaco_${i18n.language}.pdf`}
              className="editor-login-paragraphs"
              download
            >
              {t("Download do contrato de licença")}
            </a>
            <br />
            <p
              role="button"
              className="editor-login-paragraphs"
              onKeyDown={() => props.modifyActiveComponent("login")}
              onClick={() => props.modifyActiveComponent("login")}
            >
              {t("Retornar para a tela de autenticação")}
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default CadastroSoftware;
