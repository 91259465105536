import { useBoardContext } from "@beontag/tageditor";
import { Box, Grid } from "@mui/material";
import { ITemplate } from "../../../../interfaces/editor/Templates";
import TemplateView from "./templateView";

export const emptyTag =
  '{"editor":{"version":"0.0.56"},"tags":{"currentTag":1,"shared":{"byId":{"49eac4d0-0f51-4d95-8d75-5048e8f65b04":{"type":"Workspace","version":"5.2.4","originX":"center","originY":"center","left":971,"top":567.5,"width":252.09,"height":96,"x-type":"workspace","x-id":"49eac4d0-0f51-4d95-8d75-5048e8f65b04","x-index":0, "rx": 2, "ry": 2}}},"byVersion":{}}}';

export default function GridTemplatesEditor(props: {
  handleSelectedTemplate: Function;
  handleDelete: Function;
  handleEditTemplate: Function;
  templateList: ITemplate[];
  userCanEditOrDeleteTemplate: {
    userCanDeleteTemplate: boolean;
    userCanEditTemplate: boolean;
  };
}) {
  const {
    handleSelectedTemplate,
    handleDelete,
    handleEditTemplate,
    templateList,
    userCanEditOrDeleteTemplate,
  } = props;

  const { boardConfig } = useBoardContext();

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item md={4} key={0}>
          <TemplateView
            handleSelectedTemplate={props.handleSelectedTemplate}
            handleDelete={() => undefined}
            handleEditTemplate={() => undefined}
            emptyJson={emptyTag}
            template={{
              idTemplate: 0,
              nomept: "",
              nomeen: "",
              nomees: "",
              json: "",
              idTema: 0,
            }}
            boardConfig={boardConfig}
            userCanEditOrDeleteTemplate={userCanEditOrDeleteTemplate}
          />
        </Grid>
        {templateList?.map((template, index) => {
          return (
            <Grid item md={4} key={template.idTemplate}>
              <TemplateView
                emptyJson={emptyTag}
                handleSelectedTemplate={handleSelectedTemplate}
                handleDelete={handleDelete}
                handleEditTemplate={handleEditTemplate}
                template={template}
                boardConfig={boardConfig}
                userCanEditOrDeleteTemplate={userCanEditOrDeleteTemplate}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
