import { TemplatePreviewCanvas } from "@beontag/tageditor";
import { IconButton } from "@mui/material";
import { ITemplate } from "../../../../interfaces/editor/Templates";
import { Pencil, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function TemplateView(props: {
  handleSelectedTemplate: Function;
  handleEditTemplate: Function;
  handleDelete: Function;
  template: ITemplate;
  emptyJson: string;
  boardConfig: any;
  userCanEditOrDeleteTemplate: {
    userCanDeleteTemplate: boolean;
    userCanEditTemplate: boolean;
  };
}) {
  const { t } = useTranslation("editor");

  return (
    <div
      className="editor-menu-template-item"
      onClick={() =>
        props.handleSelectedTemplate(props.template.json || props.emptyJson)
      }
      //onPointerUp={() => props.handleSelectedTemplate(props.template.json || undefined)}
    >
      <span className="editor-menu-template-item-data-container">
        <p className="editor-menu-template-item-name">
          {props.template &&
            (props.template.nomept ||
              props.template.nomeen ||
              props.template.nomees ||
              t("Etiqueta em branco"))}
        </p>
      </span>
      {props.template.json &&
        (props.userCanEditOrDeleteTemplate.userCanDeleteTemplate ||
          props.userCanEditOrDeleteTemplate.userCanEditTemplate) && (
          <div className="editor-menu-template-item-buttons-admin">
            <IconButton
              onClick={(e) => props.handleEditTemplate(e, props.template)}
            >
              <Pencil size="18" />
            </IconButton>
            <IconButton onClick={(e) => props.handleDelete(e, props.template)}>
              <Trash size="18" />
            </IconButton>
          </div>
        )}

      <TemplatePreviewCanvas
        json={props.template.json || props.emptyJson}
        workspaceEdgeSize={2}
        boardConfig={props.boardConfig}
      />
    </div>
  );
}
