import {
	Button,
	FormHelperText,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { XLg } from "react-bootstrap-icons";

const formas = [
	{
		value: "Circular",
		label: "Circular",
	},
	{
		value: "Retangular",
		label: "Retangular",
	},
	{
		value: "Coracao",
		label: "Coração",
	},
	{
		value: "Estrela",
		label: "Estrela",
	},
	{
		value: "Gravata",
		label: "Gravata",
	},
	{
		value: "Diversos",
		label: "Diversos",
	},
	{
		value: "NaoAplicado",
		label: "Não aplicado",
	},
];

export const cores = [
	{
		value: "Branco",
		label: "Branco",
	},
	{
		value: "Preto",
		label: "Preto",
	},
	{
		value: "Amarelo",
		label: "Amarelo",
	},
	{
		value: "Azul",
		label: "Azul",
	},
	{
		value: "Vermelho",
		label: "Vermelho",
	},
	{
		value: "Verde",
		label: "Verde",
	},
	{
		value: "Laranja",
		label: "Laranja",
	},
	{
		value: "Marrom",
		label: "Marrom",
	},
	{
		value: "Pink",
		label: "Pink",
	},
	{
		value: "Roxo",
		label: "Roxo",
	},
	{
		value: "Prata",
		label: "Prata",
	},
	{
		value: "Ouro",
		label: "Ouro",
	},
	{
		value: "Colorido",
		label: "Colorido",
	},
	{
		value: "Neon",
		label: "Neon",
	},
	{
		value: "Fluorescente",
		label: "Fluorescente",
	},
	{
		value: "Multicor",
		label: "Multicor",
	},
	{
		value: "Transparente",
		label: "Transparente",
	},
];

const familia = [
	{
		value: "A4",
		label: "A4",
	},
	{
		value: "Carta",
		label: "Carta",
	},
	{
		value: "A5",
		label: "A5",
	},
];

/**
 * Componente com filtros de produtos na tela de categorias/pesquisa
 * @param props
 * @returns Box filtros
 */
export default function ProdutoFilterPimaco(props: {
	dados: { [key: string]: string };
	handleChange: Function;
	categoria: string;
	children?: ReactElement;
	clearFiltroValue: Function;
	cores: { value: string; label: string }[];
}) {
	const { dados, handleChange, clearFiltroValue } = props;

	const { t } = useTranslation("produtos");

	const filterAttr = useMemo(() => {
		const inkjet = props.categoria && props.categoria.includes("etiqink");

		return {
			gridMdSize: inkjet ? 1.5 : 2,
			inkjet: inkjet,
		};
	}, [props.categoria]);

	return (
		<div className="pimaco-product-filter-container">
			<form
				method="get"
				action={
					props.categoria == undefined
						? "/pesquisa"
						: `/categoria/${props.categoria}`
				}
			>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={12} md={filterAttr.gridMdSize}>
						<InputLabel
							id="area-input-label"
							className="produto-filter-input-label"
						>
							{t("Código")}
						</InputLabel>

						<TextField
							id="area-input"
							name="codigo"
							value={dados.codigo}
							fullWidth
							onChange={(e) => handleChange(e)}
							variant="filled"
						/>
						<FormHelperText> </FormHelperText>
					</Grid>

					<Grid item xs={12} md={filterAttr.gridMdSize}>
						<InputLabel
							id="area-input-label"
							className="produto-filter-input-label"
						>
							{t("Forma")}
						</InputLabel>

						<Select
							labelId="area-input-label"
							id="area-input"
							name="forma"
							value={dados.forma}
							fullWidth
							onChange={(e) => handleChange(e)}
							variant="filled"
							endAdornment={
								<InputAdornment
									position="end"
									className="form-clear-selection"
									onClick={() => clearFiltroValue("forma")}
								>
									<div>
										<XLg size={20} />
									</div>
								</InputAdornment>
							}
						>
							{formas.map((option) => {
								return (
									<MenuItem key={option.value} value={option.value}>
										{t(option.label)}
									</MenuItem>
								);
							})}
						</Select>
						<FormHelperText> </FormHelperText>
					</Grid>

					<Grid item xs={12} md={filterAttr.gridMdSize}>
						<InputLabel
							id="area-input-label"
							className="produto-filter-input-label"
						>
							{t("Cor")}
						</InputLabel>

						<Select
							labelId="area-input-label"
							id="area-input"
							name="cor"
							value={dados.cor}
							fullWidth
							onChange={(e) => handleChange(e)}
							variant="filled"
							endAdornment={
								<InputAdornment
									position="end"
									className="form-clear-selection"
									onClick={() => clearFiltroValue("cor")}
								>
									<div>
										<XLg size={20} />
									</div>
								</InputAdornment>
							}
						>
							{props.cores.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{t(option.label)}
								</MenuItem>
							))}
						</Select>
						<FormHelperText> </FormHelperText>
					</Grid>

					<Grid item xs={6} md={filterAttr.gridMdSize} container>
						<InputLabel className="produto-filter-input-label">
							{t("Largura")}
						</InputLabel>

						<TextField
							type="number"
							name="largura"
							aria-label="largura"
							className="number-input"
							variant="filled"
							helperText=" "
							value={dados.largura}
							onChange={(e) => handleChange(e)}
						// InputProps={{
						// 	inputProps: { min: 0 },
						// 	startAdornment:
						// 		<InputAdornment
						// 			className="adornment-number-input"
						// 			position="start"
						// 		>
						// 			min:
						// 		</InputAdornment>
						// }}
						/>
					</Grid>

					<Grid item xs={6} md={filterAttr.gridMdSize} container>
						<InputLabel className="produto-filter-input-label">
							{t("Altura")}
						</InputLabel>

						<TextField
							type="number"
							name="altura"
							aria-label="altura"
							variant="filled"
							helperText=" "
							value={dados.altura}
							onChange={(e) => handleChange(e)}
						// InputProps={{
						// 	inputProps: { min: 0 },
						// 	startAdornment:
						// 		<InputAdornment
						// 			className="adornment-number-input"
						// 			position="start"
						// 		>
						// 			min:
						// 		</InputAdornment>
						// }}
						/>
					</Grid>

					{filterAttr.inkjet && (
						<>
							<Grid item xs={12} md={filterAttr.gridMdSize}>
								<InputLabel
									id="area-input-label"
									className="produto-filter-input-label"
								>
									{t("Tamanho")}
								</InputLabel>

								<Select
									labelId="area-input-label"
									id="area-input"
									name="familia"
									value={dados.familia}
									fullWidth
									onChange={(e) => handleChange(e)}
									variant="filled"
									endAdornment={
										<InputAdornment
											position="end"
											className="form-clear-selection"
											onClick={() => clearFiltroValue("familia")}
										>
											<div>
												<XLg size={20} />
											</div>
										</InputAdornment>
									}
								>
									{familia.map((option) => {
										return (
											<MenuItem key={option.value} value={option.value}>
												{t(option.label)}
											</MenuItem>
										);
									})}
								</Select>
								<FormHelperText> </FormHelperText>
							</Grid>

							<Grid item xs={12} md={filterAttr.gridMdSize}>
								<InputLabel
									id="area-input-label"
									className="produto-filter-input-label"
								>
									{t("Folhas")}
								</InputLabel>

								<TextField
									id="area-input"
									name="folhas"
									value={dados.folhas}
									fullWidth
									type="number"
									onChange={(e) => handleChange(e)}
									variant="filled"
								/>
								<FormHelperText> </FormHelperText>
							</Grid>
						</>
					)}

					{/* <Grid item xs={12} md={3} container>
						<InputLabel
							className="produto-filter-input-label"
						>
							{t("Altura da etiqueta")}
						</InputLabel>
						
						<Grid item container spacing={2}>
							<Grid item xs={6}>
								<TextField
									type="number"
									name="AlturaMinima"
									aria-label="min"
									variant="filled"
									helperText="mm"
									value={dados.AlturaMinima}
									onChange={(e) => handleChange(e)}
									InputProps={{
										inputProps: { min: 0 },
										startAdornment:
											<InputAdornment
												className="adornment-number-input"
												position="start"
											>
												min:
											</InputAdornment>
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									type="number"
									name="AlturaMaxima"
									aria-label="max"
									variant="filled"
									helperText="mm"
									value={dados.AlturaMaxima}
									onChange={(e) => handleChange(e)}
									InputProps={{
										inputProps: { min: 0 },
										startAdornment:
											<InputAdornment
												className="adornment-number-input"
												position="start"
											>
												max:
											</InputAdornment>
									}}
								/>
							</Grid>
						</Grid>
					</Grid> */}

					<Grid item xs={12} md={filterAttr.gridMdSize}>
						<Button
							fullWidth
							variant="contained"
							className="btn btn-contained-primary"
							disableElevation
							type="submit"
						>
							{t("Filtrar")}
						</Button>
					</Grid>
				</Grid>

				{props.children}
			</form>
		</div>
	);
}
