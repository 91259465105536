import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import sUsuario from "../../../services/usuarioService";
import { handleFormErrors } from "../../../helpers/formErrorHandler";

import { useMessage } from "../../../context/alertContext/context";
import { useTranslation } from "react-i18next";

const ModalUserData = (props: {
  open: boolean;
  handleCloseModal: Function;
}) => {
  const { showAlertApp } = useMessage();

  const [enableEdit, setEnableEdit] = useState<boolean>(false);

  const [form, setForm] = useState({
    nome: "",
    email: "",
    emailConfirmation: "",
    endereco: "",
    estado: "",
    cidade: "",
  });

  const [errors, setErrors] = useState({
    nome: false,
    email: false,
    emailConfirmation: false,
    endereco: false,
    estado: false,
    cidade: false,
  });

  const handleInputChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (handleFormErrors(form, errors, setErrors)) {
      return;
    }

    sUsuario
      .updateUserData(form)
      .then((resp) => {
        props.handleCloseModal();
        showAlertApp(t("Dados atualizados com sucesso"), "success", 5000);
      })
      .catch((err) => {
        showAlertApp(
          t("Ocorreu um problema, tente novamente mais tarde"),
          "error",
          500
        );
      });
  };

  useEffect(() => {
    if (props.open) {
      sUsuario.getUserData().then((resp) => {
        setForm(resp.data);
      });
    }
  }, [props.open]);
  const { t } = useTranslation("editor");
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.handleCloseModal()}
        scroll="paper"
        maxWidth={"md"}
      >
        <DialogTitle>
          <IconButton onClick={() => props.handleCloseModal()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            rowSpacing={2}
            className="editor-login-authentication modal-grid"
          >
            <Grid item xs={12}>
              <h1 className="editor-login-authentication-text">
                {t("Meus dados")}
              </h1>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="nome"
                type="text"
                name="nome"
                aria-label="Nome:"
                value={form.nome}
                onChange={handleInputChange}
                error={errors.nome}
                disabled={!enableEdit}
                variant="filled"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      disablePointerEvents
                      className="editor-login-authentication-fields-adornment"
                    >
                      <span className="form-label">{t("Nome:")}</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="email"
                type="email"
                name="email"
                aria-label="E-mail:"
                variant="filled"
                value={form.email}
                fullWidth
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      disablePointerEvents
                      className="editor-login-authentication-fields-adornment"
                    >
                      <span className="form-label">{t("E-mail:")}</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className="editor-login-authentication-fields editor-request-access-endereco"
                name="endereco"
                variant="filled"
                value={form.endereco}
                onChange={handleInputChange}
                error={errors.endereco}
                disabled={!enableEdit}
                multiline
                fullWidth
                rows={3}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      className="editor-request-access-endereco-adornment"
                      position="start"
                      disablePointerEvents
                    >
                      {t("Endereço (Rua, Número e Bairro):")}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6} sx={{ paddingRight: "16px" }}>
                <FormControl fullWidth>
                  <Select
                    type="text"
                    name="estado"
                    variant="filled"
                    onChange={handleInputChange}
                    value={form.estado}
                    disabled={!enableEdit}
                    startAdornment={
                      <InputAdornment position="start" disablePointerEvents>
                        <span className="form-label">{t("Estado:")}</span>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="Acre">Acre</MenuItem>
                    <MenuItem value="Alagoas">Alagoas</MenuItem>
                    <MenuItem value="Amapa">Amapá</MenuItem>
                    <MenuItem value="Aazonas">Amazonas</MenuItem>
                    <MenuItem value="Bahia">Bahia</MenuItem>
                    <MenuItem value="Ceara">Ceará</MenuItem>
                    <MenuItem value="DistritoFederal">
                      Distrito Federal
                    </MenuItem>
                    <MenuItem value="EspiritoSanto">Espírito Santo</MenuItem>
                    <MenuItem value="Goias">Goiás</MenuItem>
                    <MenuItem value="Maranhao">Maranhão</MenuItem>
                    <MenuItem value="MatoGrosso">Mato Grosso</MenuItem>
                    <MenuItem value="MatoGrossoSul">
                      Mato Grosso do Sul
                    </MenuItem>
                    <MenuItem value="MinasGerais">Minas Gerais</MenuItem>
                    <MenuItem value="Para">Pará</MenuItem>
                    <MenuItem value="Paraiba">Paraíba</MenuItem>
                    <MenuItem value="Parana">Paraná</MenuItem>
                    <MenuItem value="Pernambuco">Pernambuco</MenuItem>
                    <MenuItem value="Piaui">Piauí</MenuItem>
                    <MenuItem value="RioJaneiro">Rio de Janeiro</MenuItem>
                    <MenuItem value="RioGrandeNorte">
                      Rio Grande do Norte
                    </MenuItem>
                    <MenuItem value="RioGrandeSul">Rio Grande do Sul</MenuItem>
                    <MenuItem value="Rondonia">Rondônia</MenuItem>
                    <MenuItem value="Roraima">Roraima</MenuItem>
                    <MenuItem value="SantaCatarina">Santa Catarina</MenuItem>
                    <MenuItem value="SaoPaulo">São Paulo</MenuItem>
                    <MenuItem value="Sergipe">Sergipe</MenuItem>
                    <MenuItem value="Tocantis">Tocantins</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="cidade"
                  type="text"
                  name="cidade"
                  aria-label="cidade:"
                  value={form.cidade}
                  onChange={handleInputChange}
                  disabled={!enableEdit}
                  error={errors.cidade}
                  variant="filled"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        disablePointerEvents
                        className="editor-login-authentication-fields-adornment"
                      >
                        <span className="form-label">{t("Cidade:")}</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className="modal-userdata-buttons">
              {enableEdit ? (
                <>
                  <Button
                    type="submit"
                    variant="outlined"
                    className="form-contato-submit-button btn btn-outlined-primary"
                    disableElevation
                    onClick={() => setEnableEdit(false)}
                  >
                    {t("Cancelar")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="form-contato-submit-button btn btn-contained-primary"
                    disableElevation
                    onClick={(e) => handleSubmit(e)}
                  >
                    {t("Salvar")}
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className="form-contato-submit-button btn btn-contained-primary"
                  disableElevation
                  onClick={() => setEnableEdit(true)}
                >
                  {t("Editar")}
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalUserData;
