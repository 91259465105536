import { Route, Routes } from "react-router-dom";
import PimacoSite from './site'
import HomePimaco from "./site/home";
import APWeb from "./APWeb";
import PoliticaPrivacidade from "./site/institucional/politica_privacidade";
import PoliticaQualidade from "./site/institucional/politica_qualidade";
import QuemSomos from "./site/institucional/quemsomos";
import AssistenteImpressao from "./site/assistente_impressao";
import Faq from "./site/faq";
import LeituraEtiquetasPimaco from "./site/leitura_etiquetas";
import Gabaritos from "./site/downloads/gabaritos";
import Parametros from "./site/downloads/parametros";
import FerramentaBusca from "./site/ferramenta_busca";
import Produto from "./site/produto";
import Categoria from "./site/categoria";
import Contato from "./site/contato";
import PesquisaPimaco from "./site/pesquisa";
import Health from "./health";

export default function PimacoRoutes() {

	return (
		<Routes>
			<Route path="/" element={<PimacoSite />}>
				<Route
					index
					element={<HomePimaco />}
				/>
				<Route
					path="/politica_privacidade"
					element={<PoliticaPrivacidade />}
				/>
				<Route
					path="/politica_qualidade"
					element={<PoliticaQualidade />}
				/>
				<Route
					path="/quemsomos"
					element={<QuemSomos />}
				/>
				<Route
					path="/assistente_impressao"
					element={<AssistenteImpressao />}
				/>
				<Route
					path="/faq"
					element={<Faq />}
				/>
				<Route
					path="/leitura_etiquetas"
					element={<LeituraEtiquetasPimaco />}
				/>
				<Route
					path="/downloads/gabaritos"
					element={<Gabaritos />}
				/>
				<Route
					path="/downloads/parametros"
					element={<Parametros />}
				/>
				<Route
					path="/ferramenta_busca"
					element={<FerramentaBusca />}
				/>
				<Route
					path="/produto/:productId"
					element={<Produto />}
				/>
				<Route
					path="/categoria/:categoria"
					element={<Categoria />}
				/>
				<Route
					path="/contato"
					element={<Contato />}
				/>
				<Route
					path="/pesquisa"
					element={<PesquisaPimaco />}
				/>
			</Route>

			<Route
				path="/APWeb"
				element={<APWeb />}
			/>

			<Route
				path="/health"
				element={<Health />}
			/>

		</Routes>
	)
}