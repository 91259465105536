import { ReactChild } from 'react'

export default function ContentHeader(props: {
    image: string,
    children?:ReactChild,
    imageAltText?: string,
    imageClass?: string
}) {

    return (
        <div className='content-header-container'>
            <img
                src={props.image}
                className={`content-header-image ${props.imageClass ? props.imageClass : ''}`}
                alt={props.imageAltText}
            />
            
            {props.children}
        </div>
    )
}