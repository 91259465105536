import { FormControl, FormGroup } from "@mui/material";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { ArrowButtonIcon } from "../../../../components/common/icons";
import { ITema } from "../../../../interfaces/editor/Tema";
import { useTranslation } from "react-i18next";

export default function AccordionTemasTemplateEditor(props: {
  handleSelectCategory: Function;
  temas: ITema[];
  selectedCategory: number;
}) {
  const { handleSelectCategory, temas, selectedCategory } = props;
  const { t } = useTranslation("editor");
  return (
    <div className="editor-menu-categoria-selector-container">
      <FormControl component="fieldset">
        <FormGroup>
          <Accordion allowMultipleExpanded allowZeroExpanded={true}>
            {temas && temas.length != 0 ? (
              temas.map((tema) => {
                return (
                  <AccordionItem key={Math.random() * 1000}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span
                          onClick={() => handleSelectCategory(tema.nome)}
                          role="link"
                        >
                          {t(
                            tema.nome != "undefined" ? tema.nome : tema.nome[0]
                          )}
                        </span>
                        <ArrowButtonIcon onClick={() => undefined} />
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        {tema.categorias?.map((item) => {
                          return (
                            <li
                              onClick={() => handleSelectCategory(item)}
                              role="link"
                              className={
                                selectedCategory == item.idTema ? "active" : ""
                              }
                              key={item.idTema}
                            >
                              {item.nome}
                            </li>
                          );
                        })}
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                );
              })
            ) : (
              <div className="editor-menu-categoria-selector-error">
                {t(
                  "Não foi possível carregar as categorias, tente novamente mais tarde"
                )}
              </div>
            )}
          </Accordion>
        </FormGroup>
      </FormControl>
    </div>
  );
}
