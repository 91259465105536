import { Trans, useTranslation } from "react-i18next";

export default function StepperEditor(props: {
  activeStep: number;
  handleChangeActiveStep: Function;
}) {
  const { t } = useTranslation("editor");
  return (
    <div className="editor-stepper-container">
      <div
        className={`editor-stepper-item ${
          props.activeStep == 1 ? "editor-active-step" : ""
        }`}
        onClick={() => props.handleChangeActiveStep(1)}
      >
        <span>
          <p>
            <Trans t={t} i18nKey="01 escolha o produto">
              <strong>01</strong> Escolha o <strong>Produto</strong>
            </Trans>
          </p>
        </span>
      </div>

      <div
        className={`editor-stepper-item ${
          props.activeStep == 2 ? "editor-active-step" : ""
        }`}
        onClick={() => props.handleChangeActiveStep(2)}
      >
        <span>
          <p>
            <Trans t={t} i18nKey="02 escolha uma arte">
              <strong>02</strong> Escolha uma <strong>Arte</strong>
            </Trans>
          </p>
        </span>
      </div>

      <div
        className={`editor-stepper-item ${
          props.activeStep == 3 ? "editor-active-step" : ""
        }`}
      >
        <span>
          <p>
            <Trans t={t} i18nKey="03 personalize">
              <strong>03</strong> Personalize
            </Trans>
          </p>
        </span>
      </div>
    </div>
  );
}
