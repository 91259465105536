import ContentHeader from "../../../../components/common/content-header";

import header_image from '../../../../assets/politica_privacidade.webp'
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function PoliticaPrivacidade() {

    const { t } = useTranslation('politicaPrivacidade')
    
    useEffect(() => {
		window.scroll({
            // @ts-ignore
			// eslint-disable-next-line no-restricted-globals
			top: top,
			left: 0,
            behavior: 'smooth'	
		})
	}, [])

    return (
        <>
            <ContentHeader
                image={header_image}
                imageClass="politica-privacidade-image"
            >
                <h1 className="content-header-politica-privacidade">{t("Politica de privacidade")}</h1>
            </ContentHeader>
            <div className="page-content empresa-content">
                <ol>
                    <li className="page-paragraph">
                        <b>{t("OBJETIVO")}</b>

                        <span>
                            {t("No cotidiano da Pimaco ('Pimaco'), seja na condução dos negócios, na busca por novos produtos, serviços e oportunidades, ou na organização de sua estrutura interna, o tratamento de dados pessoais é parte indispensável dessa realidade. O tratamento de dados pessoais é fundamental às nossas atividades, a Pimaco compreende que deve agir com responsabilidade e transparência, zelando por tais informações e conferindo-lhes medidas de segurança técnicas e administrativas.")}
                        </span>

                        <span>
                            {t("A presente Política prevê diretrizes e estabelece regras relacionadas à privacidade e à proteção dos dados pessoais de clientes, colaboradores e terceiros durante o tratamento de dados pessoais pela Pimaco, e na relação com terceiros, nas quais haja compartilhamento ou uso compartilhado de dados pessoais.")}
                        </span>

                        <span>
                            {t("Com este documento, a Pimaco visa estar em conformidade com as normas aplicáveis de proteção de dados, promovendo a transparência e boa-fé perante os titulares, mediante a proteção de seus dados pessoais e de seus direitos e liberdades civis, inclusive a Lei 13.709/2018 (“LGPD”), bem como as melhores práticas ao seu alcance.")}
                        </span>

                        <br />
                    </li>

                    <li className="page-paragraph">
                        <b>{t("ABRANGÊNCIA")}</b>

                        <span>
                            {t("A Política aplica-se a Pimaco de forma integral, principalmente às áreas de negócio e operacionais, bem como os terceiros com os quais a Pimaco compartilhe dados pessoais, tanto no Brasil quanto no exterior.")}
                        </span>

                        <br />
                    </li>

                    <li className="page-paragraph">
                        <b>{t("REFERÊNCIAS")}</b>

                        <span>
                            <ul>
                                <li>
                                    {t("Lei Geral de Proteção de Dados Pessoais (“LGPD”) - Lei nº 13.709/2018;")}
                                </li>

                                <li>
                                    {t("General Data Protection Regulation (“GDPR”) - Regulation (EU) 2016/679;")}
                                </li>
                            </ul>
                        </span>

                        <br />
                    </li>

                    <li className="page-paragraph">
                        <b>{t("GLOSSÁRIO")}</b>

                        <span>
                            <ul>
                                <li>
                                    <span>
                                        {t("ANPD: Autoridade Nacional de Proteção de Dados")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Auto Adesivos Paraná S.A. (“Pimaco”): Auto Adesivos Paraná S.A. (“Pimaco”)")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Controlador ou controladores: pessoa(s) natural(is) ou jurídica(s), de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Dado Anonimizado: dado relativo ao titular que não possa ser identificado, considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Dado pessoal ou dados pessoais: informação(ões) relacionada(s) a pessoa natural identificada ou identificável. Isto é, informação(ões) que identifiquem uma pessoa natural de forma direta (nome, sobrenome, CPF, impressão digital, endereço de e-mail, número de telefone) ou indireta, a partir de associações e perfilamento (endereço, estado civil, profissão, renda, histórico financeiro, nota de crédito).")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Dado pessoal sensível ou dados pessoais sensíveis: dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural. De outro modo, são dados pessoais que revelem informações relacionadas à intimidade do Titular, podendo lhe gerar discriminação. Dados pessoais sensíveis implicam em riscos mais elevados que os dados pessoais e vulnerabilidades aos direitos e liberdades dos titulares.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Data Protection Officer (DPO) ou Encarregado: trata-se da pessoa responsável na Pimaco como canal de comunicação entre o controlador, os titulares dos dados e a ANPD.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Fluxo de tratamento de dados pessoais (“Fluxo”): qualquer operação realizada por área da Pimaco que envolva tratamento de dados pessoais com uma finalidade específica.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Eliminação: exclusão de dado ou de conjunto de dados armazenados em banco de dados ou em documentos físicos.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Incidente de segurança: ocorrência relacionada à segurança, técnica ou administrativa, dos dados pessoais e que possa trazer riscos ou danos aos Titulares. São exemplos de incidentes: vazamento de dados, acessos não autorizados, destruição ou alteração dos dados pessoais, entre outros.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Menores: refere-se às crianças (até doze anos incompletos) e adolescentes (entre doze e dezoito anos).")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Programa: refere-se ao programa de compliance de proteção de dados pessoais da Pimaco.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Operador ou operadores: pessoa(s) natural(is) ou jurídica(s), de direito público ou privado, que realiza(m) o tratamento de dados pessoais em nome do controlador.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("RIPD ou Relatório de Impacto: trata-se do relatório de impacto à proteção de dados pessoais, previsto na LGPD, e obrigatório para os casos previstos no Formulário para Registro de Novos Tratamentos de Dados Pessoais.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Terceiros: abrange pessoas naturais e jurídicas, sob a condição de fornecedores, prestadores de serviço, representantes comerciais, parceiros, agentes intermediários, entre outros vínculos jurídicos que tratem dados pessoais em nome da Pimaco.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Teste de Proporcionalidade do Legítimo Interesse: trata-se de teste de quatro fases a ser conduzido em paralelo ao Relatório de Impacto, sempre que a hipótese de tratamento for o interesse legítimo da Pimaco. O teste está previsto no Formulário para Registro de Novos Tratamentos de Dados Pessoais.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Titular ou Titulares: pessoa(s) natural(is) a quem se referem os dados pessoais que são objeto de tratamento pela Pimaco, incluindo, por exemplo: clientes, empregados, diretores, acionistas e sócios de terceiros.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Transferência internacional ou transferência internacional de dados: transferência dos dados pessoais para outro país, em qualquer momento do tratamento inclusive para mero armazenamento. A transferência não se confunde com a transmissão, que é apenas a utilização de meio (exemplo: e-mail com servidor no exterior) para destinatário no Brasil.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Tratamento: todas as operações realizadas com dados pessoais e dados pessoais sensíveis, desde sua coleta até a eliminação, incluindo o mero acesso e visualização dos dados.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Uso Compartilhado de Dados: Comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados.")}
                                    </span>
                                </li>

                                <li>
                                    <span>
                                        {t("Usuários - todas as pessoas que visitem e acessem os websites ou aplicativos da Pimaco. Também podemos nos referir ao Usuário como “você”.")}
                                    </span>
                                </li>
                            </ul>
                        </span>

                        <br />
                    </li>

                    <li className="page-paragraph">
                        <b>{t("DISPOSIÇÕES GERAIS")}</b>

                        <ol>
                            <li>
                                <span>
                                    <b>{t("Conceitos Gerais")}</b>

                                    <ol>
                                        <li>
                                            <span>
                                                <b>{t("Tratamento de Dados Pessoais")}</b>

                                                <span>
                                                    {t("O tratamento de dados pessoais inclui: “toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração”.")}
                                                </span>

                                                <span>
                                                    {t("Pela ampla definição legal, qualquer ação acima envolvendo dados pessoais configura uma atividade de tratamento. Não é necessário que se aplique efetiva conduta com o dado pessoal, ou resultar um produto diferente. A mera visualização, a partir do acesso aos dados pessoais, já caracteriza o tratamento.")}
                                                </span>

                                                <span>
                                                    {t("A Pimaco, preocupado com a conformidade de cada tratamento realizado sob sua responsabilidade, busca conscientizar seus colaboradores e terceiros e adotar continuamente medidas de segurança.")}
                                                </span>

                                                <span>
                                                    {t("Exemplos de tratamentos realizados pela Pimaco: ")}
                                                </span>

                                                <ul>
                                                    <li>
                                                        <span>
                                                            {t("Coleta, recepção, utilização e armazenamento de dados pessoais para cadastro de novos clientes e manutenção da base de clientes existentes;")}
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span>
                                                            {t("Utilização e comunicação de dados pessoais em reportes oficiais aos órgãos reguladores;")}
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span>
                                                            {t("Controle da informação de colaboradores e transmissão a órgãos públicos em observância às leis vigentes;")}
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span>
                                                            {t("Arquivamento de dados pessoais de Terceiros, pelo prazo legal;")}
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span>
                                                            {t("Eliminação de dados pessoais de colaboradores desligados, após decorrido o período de guarda obrigatória.")}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </span>

                                            <br />
                                        </li>

                                        <li>
                                            <b>{t("Informações coletadas")}</b>

                                            <span>
                                                {t("A Pimaco coleta dados e informações dos Usuários em seus websites ou aplicativos quando fornecidos pelo Usuário, e em acordo com as bases legais de tratamento da LGPD, no preenchimento de formulários ou cadastros.")}
                                            </span>

                                            <span>
                                                {t("Para facilitar o uso do website ou aplicativo, a Pimaco também pode coletar dados da navegação ou do aparelho do Usuário, através de rastreio (cookies), autorizações concedidas aos websites ou aplicativos que fornecem a localização geográfica, endereço de protocolo da internet, informações de data e hora de uso do website pelo Usuário, informações referentes a páginas acessadas, às quantidades de cliques e tentativas de uso do Website pelo Usuário.")}
                                            </span>

                                            <span>
                                                {t("Destacamos que eventual não compartilhamento de dados pelo Usuário pode impactar na usabilidade e experiência de acesso ao website ou aplicativos.")}
                                            </span>

                                            <span>
                                                {t("Todo e qualquer hipótese de fornecimento de consentimento pelo Usuário para a finalidade de tratamento do dado pessoal é coletado de forma individual, clara e específica. A qualquer tempo, o Usuário poderá alterar suas concessões de consentimento para o tratamento de seus dads, seja concedendo novas permissões ou restringindo o consentimento para a permissões atuais. Outras informações e diretrizes relacionadas ao consentimento dos Usuários poderá ser identificada na Política de Gestão de Consentimento.")}
                                            </span>

                                            <span>
                                                {t("A Pimaco disponibiliza um canal de comunicação aos titulares, acessível publicamente em nosso website. Que poderá ser acessado pelo seguinte meio: ")} <a href="https://www.contatoseguro.com.br/beontag">https://www.contatoseguro.com.br/beontag</a>.
                                            </span>

                                            <br />
                                        </li>

                                        <li>
                                            <b>{t("Importância de coletas suas informações")}</b>

                                            <span>
                                                {t("A coleta de informações tem objetivo de prestar serviços necessários e melhorar os produtos e serviços oferecidos para facilitar a utilização pelo Usuário, permitir o suporte e atendimento aos Usuários, além de cumprir e a executar obrigações legais, contratuais, regulatórias, a proteção e o exercício regular de direitos pelo Usuário e pela Pimaco.")}
                                            </span>

                                            <br />
                                        </li>

                                        <li>
                                            <b>{t("Uso de Cookies")}</b>

                                            <span>
                                                {t("Cookies são arquivos de internet que armazenam o que o internauta está visitando nos websites num determinado momento.")}
                                            </span>

                                            <span>
                                                {t("Os cookies podem servir para permitir o acesso e o funcionamento dos websites ou aplicativos; os cookies de autenticação, reconhecer o Usuário, possibilitando o seu acesso a áreas restritas dos websites ou aplicativos e para oferecer conteúdos, ofertas e/ou serviços da Pimaco ou de parceiros.")}
                                            </span>

                                            <span>
                                                {t("Os cookies disponibilizados nos sites da Pimaco permitem que o usuário tenha uma experiência de navegação personalizada, com maior velocidade e maior personalização de conteúdo.")}
                                            </span>

                                            <br />
                                        </li>

                                        <li>
                                            <b>{t("Desativação de cookies")}</b>

                                            <span>
                                                {t("O Usuário pode desativar os cookies do seu navegador e nas configurações do sistema operacional do seu aparelho ou equipamento de acesso aos websites ou aplicativos.")}
                                            </span>

                                            <span>
                                                {t("No entanto, não recomendamos desativar os cookies de funcionamento pois podem bloquear ou impedir as funcionalidades e até mesmo o uso dos websites ou aplicativos, principalmente aquelas relacionadas à personalização da experiência do usuário, prejudicando a navegação pelos sites da Pimaco.")}
                                            </span>

                                            <br />
                                        </li>

                                        <li>
                                            <b>{t("Agentes de Tratamento")}</b>

                                            <span>
                                                {t("Há duas categorias de agentes envolvidos nas operações de tratamento: controladores e operadores. Os controladores são responsáveis pelas decisões a serem tomadas no tratamento de dados pessoais, ao passo que os operadores conduzem as atividades de tratamento conforme determinações do controlador.")}
                                            </span>

                                            <br />
                                        </li>
                                    </ol>
                                </span>
                            </li>

                            <li>
                                <b>{t("Princípios e Bases Legais")}</b>

                                <span>
                                    {t("A Pimaco realiza apenas operações de tratamento que estejam alinhadas com os requisitos da LGPD, principalmente em relação a seus princípios e hipóteses de tratamento (bases legais).")}
                                </span>

                                <br />

                                <ol>
                                    <li>
                                        <b>{t("Bases Legais")}</b>

                                        <span>
                                            {t("Os dados pessoais só serão tratados sob as seguintes circunstâncias: ")}
                                        </span>

                                        <br />

                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="termo-td-first-width">{t("CONTRATOS")}</td>
                                                    <td className="termo-td-second-width">{t("Quando necessário aos procedimentos preliminares ou à execução de um contrato, a pedido do Titular, em que este é parte; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("OBRIGAÇÕES LEGAIS OU REGULATÓRIAS")}</td>
                                                    <td>{t("Se há uma obrigação legal ou regulatória da qual decorra a necessidade de tratamento de dados pessoais para cumpri-la; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("PROTEÇÃO DO CRÉDITO")}</td>
                                                    <td>{t("Para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("EXERCÍCIO REGULAR DE DIREITOS")}</td>
                                                    <td>{t("Para o exercício regular de direitos em processo judicial, administrativo ou arbitral, inclusive durante o período em que transcorra o prazo prescricional; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("POLÍTICAS PÚBLICAS")}</td>
                                                    <td>{t("Se estiverem relacionados à execução de uma política pública pela administração pública, sob cenário em que a Auto Adesivos Paraná S.A. ('Pimaco') esteja legalmente vinculado; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("LEGÍTIMO INTERESSE")}</td>
                                                    <td>{t("Para atender aos interesses legítimos do controlador ou de Terceiro, desde que preencha os requisitos do teste de proporcionalidade do legítimo interesse; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("CONSENTIMENTO")}</td>
                                                    <td>{t("Quando as hipóteses anteriores não forem aplicáveis e o Titular tiver fornecido consentimento para a exata finalidade do tratamento em questão.")}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <br />

                                        <span>
                                            {t("As operações de tratamento devem obedecer aos aspectos apontados no Mapeamento de Dados, principalmente quanto às finalidades.")}
                                        </span>

                                        <span>
                                            {t("Em regra, a Pimaco não realiza o tratamento de dados pessoais sensíveis, exceto sob as seguintes circunstâncias: ")}
                                        </span>

                                        <br />

                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="termo-td-first-width">{t("OBRIGAÇÕES LEGAIS OU REGULATÓRIAS")}</td>
                                                    <td className="termo-td-second-width">{t("Se há uma obrigação legal ou regulatória da qual decorra a necessidade de tratamento de dados pessoais para cumpri-la; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("EXERCÍCIO REGULAR DE DIREITOS")}</td>
                                                    <td>{t("Para o exercício regular de direitos relacionado a um contrato ou em processo judicial, administrativo ou arbitral, inclusive durante o período em que transcorra o prazo prescricional; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("POLÍTICAS PÚBLICAS")}</td>
                                                    <td>{t("Se estiverem relacionados à execução de uma política pública pela administração pública, sob cenário em que a Auto Adesivos Paraná S.A. ('Pimaco') esteja legalmente vinculado; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("PREVENÇÃO À FRAUDE E À SEGURANÇA DO TITULAR")}</td>
                                                    <td>{t("Para garantir de prevenção à fraude e à segurança do próprio titular, especificamente nos processos de identificação e autenticação de cadastro em sistemas eletrônicos; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("CONSENTIMENTO")}</td>
                                                    <td>{t("Quando as hipóteses anteriores não forem aplicáveis e o Titular tiver fornecido consentimento, de forma específica e destacada, para finalidade específica ligada ao tratamento do dado pessoal sensível.")}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <br />
                                    </li>

                                    <li>
                                        <span>
                                            <b>{t("Princípios")}</b>

                                            <span>
                                                {t("Todas as operações de tratamento deverão observar os princípios da LGPD, principalmente quanto às seguintes diretrizes: ")}
                                            </span>

                                            <br />

                                            <ul>
                                                <li>
                                                    {t("Não Discriminação")}
                                                    <span>
                                                        {t("Sob nenhuma circunstância, os dados pessoais e dados pessoais sensíveis poderão ser tratados de forma discriminatória entre os Titulares de determinada categoria.")}
                                                    </span>
                                                </li>

                                                <li>
                                                    {t("Finalidade, Adequação e Necessidade")}
                                                    <span>
                                                        {t("Em todas as operações, a Pimaco tratará o mínimo necessário de dados pessoais compatíveis com finalidades legítimas, específicas, explícitas e informadas ao Titular, além da observância à base legal cabível.")}
                                                    </span>
                                                </li>

                                                <li>
                                                    {t("Livre Acesso, Qualidade dos Dados e Transparência")}
                                                    <span>
                                                        {t("Aos Titulares, a Pimaco garantirá a consulta facilitada e gratuita sobre a forma e duração do tratamento, além de informações precisas e didáticas quanto à realização do tratamento e os agentes envolvidos, desde que não viole segredo comercial ou industrial da instituição ou de Terceiro.")}
                                                    </span>

                                                    <span>
                                                        {t("Também asseguramos a qualidade dos dados pessoais utilizados, possibilitando aos Titulares a atualização destes, para que sejam os mais exatos e alinhados ao tratamento.")}
                                                    </span>
                                                </li>

                                                <li>
                                                    {t("Segurança, Prevenção, Responsabilização e Prestação de Contas")}
                                                    <span>
                                                        {t("A Pimaco adota padrões de segurança adequados às suas operações, principalmente quando envolvem tratamento de dados pessoais, de modo a prevenir incidentes de segurança.")}
                                                    </span>
                                                </li>
                                            </ul>
                                        </span>
                                    </li>
                                </ol>

                                <br />
                            </li>

                            <li>
                                <b>{t("Direitos dos Titulares")}</b>

                                <span>
                                    {t("A Pimaco assegura a observância aos direitos dos titulares dos tratamentos de dados pessoais que realiza, conforme as disposições abaixo mencionadas: ")}
                                </span>

                                <ul>
                                    <li>
                                        <span>
                                            {t("Direito de Acesso/Explicação: os titulares de dados pessoais tratados pela Pimaco poderão ter acesso e solicitar informações como a confirmação de que seus dados são tratados pela companhia, bem como informações sobre o referido tratamento. As respostas para tais solicitações serão dadas de forma simplificada ou completa, de acordo com o previsto em lei, dentro de um prazo de 15 (quinze) dias a contar do requerimento do titular, resguardados os segredos industriais;")}
                                        </span>
                                    </li>

                                    <li>
                                        <span>
                                            {t("Direito de Retificação: O titular do dado pessoal poderá solicitar a retificação sobre o registro de seus dados pessoais, como dados imprecisos, incorretos ou desatualizados;")}
                                        </span>
                                    </li>

                                    <li>
                                        <span>
                                            {t("Direito de Cancelamento/Esquecimento: O titular dos dados poderá solicitar a eliminação, bloqueio ou anonimização de seus dados pessoais tratados pela Pimaco quando estes forem processados em excesso ou de forma ilegal. O titular também terá o direito a solicitar a eliminação de seus dados pessoais quando a base legal para o tratamento for proveniente de consentimento, salvo nas hipóteses de retenção previstas em lei;")}
                                        </span>
                                    </li>

                                    <li>
                                        <span>
                                            {t("Direito de Oposição: o titular dos dados poderá se opor ao tratamento de seus dados pessoais caso não tenha consentido com este tratamento;")}
                                        </span>
                                    </li>

                                    <li>
                                        <span>
                                            {t("Direito a Portabilidade: o titular dos dados poderá solicitar a portabilidade de seus dados pessoais a outro prestador de serviços ou produtos, mediante solicitação expressa, de acordo com a regulamentação da ANPD e órgãos reguladores.")}
                                        </span>
                                    </li>
                                </ul>

                                <span>
                                    {t("De modo a atender requisições dos titulares, a Pimaco possui ferramentas e mecanismos que visam a celeridade e efetividade na resposta ou observância destes direitos, bem como o devido arquivamento das medidas que forem adotadas em relação a essa requisição.")}
                                </span>

                                <span>
                                    {t("Para tanto, disponibilizamos um canal de comunicação aos titulares, acessível publicamente em nosso website. Que poderá ser acessado pelo seguinte meio: ")} <a href="https://www.contatoseguro.com.br/beontag">https://www.contatoseguro.com.br/beontag</a>.
                                </span>
                            </li>
                        </ol>

                        <br />
                    </li>

                    <li className="page-paragraph">
                        <b>{t("DIRETRIZES ESPECÍFICAS")}</b>

                        <ol>
                            <li>
                                <span>
                                    <b>{t("Transferência Internacional")}</b>

                                    <br />

                                    <span>
                                        {t("A Pimaco adota conduta restritiva quanto à transferência internacional de dados pessoais, realizando-a apenas quando estritamente necessário à condução de suas atividades ou quando há padrão de segurança compatível com suas diretrizes.")}
                                    </span>

                                    <span>
                                        {t("Nestes casos, a Pimaco observa a legislação local do país de transferência, para o devido cumprimento. A Pimaco também garante a ciência prévia dos Titulares da possibilidade de transferência internacional de seus dados pessoais, a partir de cláusulas contratuais ou do consentimento específico, conforme o caso concreto.")}
                                    </span>

                                    <span>
                                        {t("A Pimaco também observa os requisitos da LGPD para a possibilidade de transferência internacional: ")}
                                    </span>

                                    <ul>
                                        <li>
                                            <span>
                                                {t("Os dados pessoais são transferidos para países com grau adequado de proteção, em linha com as diretrizes da ANPD;")}
                                            </span>
                                        </li>

                                        <li>
                                            <span>
                                                {t("Os dados pessoais são transferidos quando a Pimaco é capaz de responsabilizar-se. Nesta hipótese, além de observar os direitos e deveres previstos na LGPD, a Pimaco se utilizará de cláusulas contratuais específicas ou padrões; normas corporativas globais; e do Programa de Compliance de Proteção de Dados Pessoais.")}
                                            </span>
                                        </li>
                                    </ul>
                                </span>

                                <br />
                            </li>

                            <li>
                                <b>{t("Tratamento de Dados Pessoais de Menores")}</b>

                                <span>
                                    {t("A Pimaco não realiza, em regra, o tratamento de dados pessoais de menores. Contudo, existem ocasiões em que será necessário tratá-los. Nestes casos, os dados serão tratados no melhor interesse do menor.")}
                                </span>

                                <span>
                                    {t("Nesses casos, será obrigatório obter o consentimento específico e em destaque dos pais do titular dos dados pessoais, salvo em casos que o tratamento tiver como base legal para exercício regular (defesa) em processo judicial, administrativo ou arbitral, somente quando se referir ao tratamento de dados de adolescentes.")}
                                </span>

                                <span>
                                    {t("Os dados pessoais de crianças e adolescentes, assim como os dados sensíveis, devem estar sujeitos a maior proteção em relação a outros dados pessoais. Desta forma, dados pessoais sensíveis deverão ser classificados de maneira destacada.")}
                                </span>

                                <br />
                            </li>

                            <li>
                                <b>{t("Privacy by Design")}</b>

                                <span>
                                    {t("Em consideração ao princípio de Privacy by Design, todos os produtos e serviços que sejam criados pela Pimaco são objeto de análise para que seja garantida a privacidade e a proteção de dados pessoais dos titulares, desde a concepção.")}
                                </span>

                                <span>
                                    {t("A análise inicia-se a partir do preenchimento do Formulário para Registro de Novos Tratamentos de Dados Pessoais, pela área responsável pela inovação, e é deliberada pelo Comitê de Proteção de Dados, que garantirá a observância ao princípio em questão.")}
                                </span>

                                <br />
                            </li>
                        </ol>
                    </li>

                    <li className="page-paragraph">
                        <b>{t("DISPOSIÇÕES FINAIS")}</b>

                        <span>
                            {t("O presente documento deve ser lido e interpretado em conjunto com as demais Políticas e Procedimentos adotados pela Pimaco, bem como com as leis e regulamentações relacionadas.")}
                        </span>

                        <span>
                            {t("Qualquer dúvida relativa a esta Política deve ser encaminhada ao Canal de Comunicação do Titular de Dados por meio do e-mail comitelgpd@beontag.com ou ao Encarregado por e-mail lgpd@beontag.com.")}
                        </span>
                    </li>
                </ol>
            </div>
        </>
    )
}