import { useEffect } from "react";
import { useBoardContext, useKeysCombinationHandler } from "@beontag/tageditor";
import { Button } from "@mui/material";
import { ArrowCounterclockwise as ArrowIcon } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const Undo = () => {
  const { boardHistory } = useBoardContext();
  const { t } = useTranslation("editor");
  const ctrlZCount = useKeysCombinationHandler(["Control", "z"]);
  useEffect(() => {
    if (boardHistory && ctrlZCount) {
      boardHistory.undo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctrlZCount]);

  return (
    <Button
      className="btn"
      startIcon={<ArrowIcon size={16} />}
      onClick={boardHistory?.undo}
      disabled={!boardHistory?.canUndo}
    >
      {t("Desfazer")}
    </Button>
  );
};

export default Undo;
