import { SearchButtonIcon } from '../common/icons';
import { useTranslation } from 'react-i18next'
import { ClickAwayListener } from '@mui/material';
import { Link, NavLink, useLocation } from 'react-router-dom';

export default function Navbar(props: {
        handleSearchBar: Function,
        handleMenuProdutos: Function,
        handleMenuDownloads: Function,
        handleMenuInstitucional: Function,
        handleCloseMenus: Function,
        showMenuDownloads: boolean,
        showMenuInstitucional: boolean,
        showMenuProdutos: boolean,
        showSearchBar: boolean,
    }) 
{

    const { t } = useTranslation('homepage');

    let location = useLocation();

    const checkActiveRoute = (route: string) => {
        if (location.pathname.includes(route)) {
            return true;
        }
        return false;
    }

    return (
        <>
            <ClickAwayListener onClickAway={() => !props.showSearchBar ? props.handleCloseMenus() : undefined}>
            <nav role='navigation' className="navbar">
                <ul>
                    <li
                        onClick={() => props.handleMenuProdutos()}
                        className={checkActiveRoute('categoria') || checkActiveRoute('produto') ? "active-route" : ""}
                        onMouseOver={() => props.handleMenuProdutos()}
                    >
                        {t("PRODUTOS")}
                    </li>
                    <li
                        className={checkActiveRoute('assistente_impressao') ? "active-route" : ""}
                    >
                        <NavLink
                            to="/assistente_impressao"
                        >
                            {t("SOFTWARE DE IMPRESSÃO")}
                        </NavLink>
                    </li>
                    <li>
                        <a
                            href="https://print.pimaco.com.br"
                            target='_blank'
                            rel='noreferrer'
                        >
                            {t("PIMACO PRINT")}
                        </a>
                    </li>
                    <li
                        className={checkActiveRoute('downloads') ? "active-route" : ""}
                    >
                        <span onClick={() => props.handleMenuDownloads()} onMouseOver={() => props.handleMenuDownloads()}>{t("DOWNLOADS")}</span>
                        
                        <div
                            className={`navbar-dropdown-menu ${props.showMenuDownloads ? 'navbar-dropdown-menu-active' : ''}`}
                        >
                            <ul>
                                <li>
                                    <Link to="/downloads/parametros">
                                        {t("PARÂMETROS DE IMPRESSÃO")}
                                    </Link>
                                    
                                </li>
                                <li>
                                    <Link to="/downloads/gabaritos">
                                        {t("GABARITOS")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        
                    </li>
                    <li
                        className={checkActiveRoute('politica') || checkActiveRoute('quemsomos') ? "active-route" : ""}
                    >
                        <span
                            onClick={() => props.handleMenuInstitucional()}
                            onMouseOver={() => props.handleMenuInstitucional()}
                        >
                            {t("INSTITUCIONAL")}
                            </span>
                        
                        <div
                            className={`navbar-dropdown-menu ${props.showMenuInstitucional ? 'navbar-dropdown-menu-active' : ''}`}
                        >
                            <ul>
                                <li>
                                    <Link to="/quemsomos">
                                        {t("QUEM SOMOS")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/politica_privacidade">
                                        {t("POLÍTICA DE PRIVACIDADE")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/politica_qualidade">
                                        {t("POLÍTICA DE QUALIDADE")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        className={checkActiveRoute('faq') ? "active-route" : ""}
                    >
                        <NavLink
                            to="/faq"
                        >
                            {t('DÚVIDAS FREQUENTES')}
                        </NavLink>
                    </li>
                    <li
                        className={checkActiveRoute('contato') ? "active-route" : ""}
                    >
                        <NavLink
                            to="/contato"
                        >
                            {t("CONTATO")}
                        </NavLink>
                    </li>
                    <li>
                        <SearchButtonIcon
                            onClick={() => props.handleSearchBar()}
                        />

                    </li>
                </ul>
            </nav>
            </ClickAwayListener>
            
        </>
    )
}