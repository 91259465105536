import React, { useMemo } from 'react';
import api from './index'
import { useMessage } from '../context/alertContext/context';
import { useEditorContext } from '../context/editorContext/EditorContext';
import { useConfigContext } from '../context/configContext';
import { useUserContext } from '../context/userContext/userContext';

// Allow use of hooks inside axios interceptor
const WithAxios: React.FunctionComponent<{children: React.ReactElement}> = function (props) {

	const { clearJwt, userJwt, setJwt } = useUserContext();
	const { showAlertApp } = useMessage();
	const clientConfig = useConfigContext();

	api.interceptors.request.use(
		function (config) {

			if (config) {
				config.baseURL = config.baseURL ?? clientConfig.apiUrl;
			}

			if (typeof window !== 'undefined') {
				api.defaults.headers.common.Authorization = `Bearer ${userJwt}`;

				if (config && config.headers && userJwt && !config.headers["Authorization"]) {
					config.headers["Authorization"] =  `Bearer ${userJwt}`;
				}
			}

			return config;
		}
		
	)

	    api.interceptors.response.use(
	        function (response) {
	            // hideLoading();
	            // Update tokenJwt after recieve a response

				const tokenJwt = response.headers["x-access-token"];
				//sessionStorage.getItem("tokenJwt")

	            if (tokenJwt && typeof window !== 'undefined') {
	                api.defaults.headers.common.Authorization = `Bearer ${tokenJwt}`;
	                setJwt(tokenJwt) // Save jwt token at local storage
	            }

	            return Promise.resolve(response);
	        },
	        function (error) {
				let message: string = '';
	            if (error.message === 'isOffline') {
	                return Promise.reject(error);
	            }

	            //let message = Message.defaultError;
	            let status: 'error' | 'info' | 'warning' | 'success' = 'error';

	            if (error.code === 'ECONNABORTED') {
	                //message = Message.serverTimeout;
	            }

	            if (error.response) {

					if (error.response.status == 401 || error.response.status == 403) {
						clearJwt()
						//showAlertApp("Sessão expirada, entre novamente para continuar", "error", 5000)
					}

					if (error.response.status == 413) {
						showAlertApp("Ops, esta etiqueta é muito grande, não foi possível salvá-la!", "warning", 5000)
					}

	                // Recieve message returned
	                // if (error.response.data && error.response.data.message) {
	                //     message = error.response.data.message;
	                // }

					if (error.response.data.errors) {
						const errors = Object.keys(error.response.data.errors)
						if (errors.length > 0) {
							message = error.response.data.errors[errors[0]]
						}
					}

	                if (
	                    ['error'].includes(error.response.data.status)
	                ) {
	                    status = error.response.data.status;
	                }


	                showAlertApp(message, status, 5000);
	                return Promise.reject(error);
	            }

	            return Promise.reject(error);
	        }
	    );

		return (
			<>
				{props.children}
			</>
		)
};


export default WithAxios;
