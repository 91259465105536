import { IStep } from "../../../interfaces/assistenteStep";

import {
    Impressao,
    Escrever,
    ProntoUso
} from './steps_tipo_interacao'

import {
    Retangular,
    Circular,
    Coracao,
    Estrela,
    Quadrada
} from './steps_formatos'

export const IdentificarSinalizar:IStep = {
    stepId: 38,
    text: 'Identificar e sinalizar',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para identificação e sinalização',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'IdentificarSinalizar',
}

export const Customizar:IStep = {
    stepId: 39,
    text: 'Customizar',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para customização',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Customizar'
}

export const ReforcoFichario:IStep = {
    stepId: 40,
    text: 'Reforço de fichario',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para reforços de fichário',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: 'Uso',
    value: 'ReforcoFichario'
}

export const MarcarAbas:IStep = {
    stepId: 41,
    text: 'Marcar abas',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para marcar abas',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Marcar abas'
}

export const PastasDocumentos:IStep = {
    stepId: 42,
    text: 'Pastas e documentos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em pastas e documentos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'PastasDocumentos'
}

export const IdentificarObjetos:IStep = {
    stepId: 43,
    text: 'Identificar objetos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para identificação de objetos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'IdentificarObjetos'
}

export const CaixasOrganizadoras:IStep = {
    stepId: 44,
    text: 'Caixas organizadoras',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em caixas organizadoras',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'CaixasOrganizadoras'
}

export const CartasEnvelopes:IStep = {
    stepId: 45,
    text: 'Cartas e envelopes',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em cartas e envelopes',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'CartasEnvelopes',
}

export const CadernosLivrosFicharios:IStep = {
    stepId: 46,
    text: 'Cadernos, livros e ficharios',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em cadernos e fichários',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: '',
    value: ''
}

export const OrgDocumentos:IStep = {
    stepId: 47,
    text: 'Organizar documentos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'organizar documentos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: '',
    value: ''
}

export const EncaparProtegerLivrosCadernos: IStep = {
    stepId: 90,
    text: 'Encapar e proteger livros e cadernos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'encapar e proteger livros e cadernos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: '',
    value: 'EncaparProtegerLivrosCadernos'
}

export function stepBuilderEscola() {

    IdentificarSinalizar.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Coracao, Estrela], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
    )

    Customizar.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Coracao, Estrela], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
    )

    if (CadernosLivrosFicharios.nextStep.length == 0) {
        CadernosLivrosFicharios.nextStep.push(
            IdentificarSinalizar,
            Customizar,
            ReforcoFichario,
            MarcarAbas,
            EncaparProtegerLivrosCadernos
        );
    }

    PastasDocumentos.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [Retangular, Circular], false) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    IdentificarObjetos.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Coracao, Quadrada, Estrela], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    CaixasOrganizadoras.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Coracao, Estrela], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )
    
    CartasEnvelopes.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [Retangular, Circular], false) : Impressao
    )

    if (OrgDocumentos.nextStep.length == 0) {
        OrgDocumentos.nextStep.push(
            PastasDocumentos,
            IdentificarObjetos,
            CaixasOrganizadoras,
            CartasEnvelopes,
        );
    }

    return [CadernosLivrosFicharios, OrgDocumentos];
}