import api from '../api'

const listTemplates = async function(locale:string) {
    return await api.get('templates', {
      headers: {
        "Accept-Language": locale
      }
    });
  }

  
  const listTemplateEditor = async function(locale: string, id: number) {
    return await api.get(`templates/${id}`, {
      headers: {
        "Accept-Language": locale
      }
    });
  };
  
const getProdutos = async function() {

    return api.get('editor/produtos');
}

const listFormas = async function() {

    return api.get('editor/formas');
}

const editorService = {
    listTemplates,
    getProdutos,
    listFormas,
    listTemplateEditor,
}

export default editorService;