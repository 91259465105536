import { IStep } from "../../interfaces/assistenteStep";

export default function StepInfoBox(props: {stepNumber: number, text?: string, currentStep: boolean, onClick?: Function, step?: IStep }) {
    return (
        <div
            className={`step-info-box-container ${props.currentStep ? 'current-step' : 'old-step'}`}
            onClick={() => props.onClick ? props.onClick(props.step) : undefined}
        >
            <p
                className="page-paragraph"
            >
                <b>{props.stepNumber}º PASSO:</b>
                {!props.currentStep ? props.text : ''}
            </p>
        </div>
    )
}