import { IconButton, InputAdornment } from "@mui/material"
import { Eye, EyeSlash } from 'react-bootstrap-icons'

export default function ShowPasswordInputAdornment(props: {
    inputVisible: boolean,
    setInputVisible: Function
}) {
    
    return (
        <InputAdornment position="end">
            <IconButton
                onClick={() => props.setInputVisible(!props.inputVisible)}
            >
                {props.inputVisible ? <EyeSlash /> : <Eye />}
            </IconButton>
        </InputAdornment>
    )
}