import { ReactChild } from "react";

export default function ContentBox(props: { children: ReactChild }) {
    return (
        <div className="content-box-container">
            <div className="content-box-content">
                {props.children}
            </div>
            
        </div>
    )
}