import { useEffect } from "react";
import { useBoardContext, useKeysCombinationHandler } from "@beontag/tageditor";
import { Button } from "@mui/material";
import { ArrowClockwise as RedoIcon } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const Redo = () => {
  const { boardHistory } = useBoardContext();
  const { t } = useTranslation("editor");
  const ctrlYCount = useKeysCombinationHandler(["Control", "y"]);
  useEffect(() => {
    if (boardHistory && ctrlYCount) {
      boardHistory.redo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctrlYCount]);

  return (
    <Button
      className="btn"
      startIcon={<RedoIcon size={16} />}
      onClick={boardHistory?.redo}
      disabled={!boardHistory?.canRedo}
    >
      {t("Refazer")}
    </Button>
  );
};

export default Redo;
