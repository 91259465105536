import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ISubCategoria } from '../../interfaces/produto/subCategoria';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import { ICategoria, IFiltroCategoria } from '../../interfaces/produto/categoria';
import { ArrowButtonIcon } from '../common/icons';
import { Link } from 'react-router-dom';

export default function AccordionMenuProdutos(props: {
    categorias:any,
    expandedItem:number,
    setExpandedItem: Function,
    setSelectedFilter: Function,
    selectedFilter:IFiltroCategoria
}) {
    const theme = createTheme();
    const { t } = useTranslation("common");
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <div className="menu-accordion-produtos">
            <Accordion allowZeroExpanded={true}>
                {props.categorias?.map((categoria:ICategoria) => {
                    return (
                        <AccordionItem
                            key={categoria.idCategoria}
                            dangerouslySetExpanded={props.expandedItem == categoria.idCategoria}
                        >
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="menu-produtos-category-item">
                                        <Link
                                            to={`categoria/${categoria.alias}`}
                                            title={categoria.nome}
                                        >
                                            {categoria.nome}
                                        </Link>

                                        {categoria.filtros &&
                                            <ArrowButtonIcon onClick={() => props.setExpandedItem(props.expandedItem == categoria.idCategoria ? 0 : categoria.idCategoria)}/>

                                        }
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>

                            {!matches && categoria.filtros &&  
                                <AccordionItemPanel className='accordion__panel-filtros'>
                                    <ul>
                                        {categoria.filtros.map((subCategoria:IFiltroCategoria, index:number) => {
                                            
                                            
                                            if (subCategoria.nome == "undefined") {
                                                return (
                                                    <span key={subCategoria.nome}>
                                                    {subCategoria.items.map((item) => {

                                                        return (
                                                            <li
                                                                className={`menu-produtos-subcategoria-item ${props.selectedFilter.nome == subCategoria.nome ? 'selected-menu-produtos-subcategoria-item' : ''}`}
                                                                key={item.idFiltroItem}
                                                            >   
                                                                { !matches ?
                                                                    <Link
                                                                        to={`categoria/${subCategoria.alias}`}
                                                                    >
                                                                        {item.nome}
                                                                    </Link>
                                                                :
                                                                    <span>{item.nome}</span>
                                                                }
                                                                

                                                            </li>
                                                        )
                                                    })}
                                                    </span>
                                                )
                                                                                                    

                                            } else {
                                                
                                                return (
                                                    <li
                                                        className={`menu-produtos-subcategoria-item ${props.selectedFilter.nome == subCategoria.nome ? 'selected-menu-produtos-subcategoria-item' : ''}`}
                                                        onMouseOver={() => matches ? props.setSelectedFilter(subCategoria) : undefined}
                                                        onClick={() => matches ? undefined : props.setSelectedFilter(subCategoria)}
                                                        key={index}
                                                    >   
                                                        
                                                        <span className='clickable-span'>{t(subCategoria.nome)}</span>

                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </AccordionItemPanel>
                            }
                        </AccordionItem>
                    )
                })}
            </Accordion>
        </div>
    )
}