import { Grid } from "@mui/material";

import Slider from 'react-slick'
import NextPrevButtonGroup from "../common/nextPrevButtonGroup";
import { useRef } from "react";

import impressao from '../../assets/explore_categorias_home/impressao.webp';
import escolar from '../../assets/explore_categorias_home/escolar.webp';
import corporativo from '../../assets/explore_categorias_home/corporativo.webp';
import domestico from '../../assets/explore_categorias_home/domestico.webp';
import blocoAdesivo from '../../assets/explore_categorias_home/BlocosAdesivos_Explore.webp'
import cartao_negocio from '../../assets/explore_categorias_home/cartao_negocio.webp';
import smart_label_printer from '../../assets/explore_categorias_home/smart_label_printer.webp';
import transfer_camiseta from '../../assets/explore_categorias_home/transfer_camiseta.webp';
import fotografia from '../../assets/explore_categorias_home/fotografia.webp';
import sinalizacao from '../../assets/explore_categorias_home/sinalizacao.webp';

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ExploreCategorias() {

    const settings = {
        infinite: false,
        slidesToShow: 1,
        arrows: false,
    }

    const customSlider = useRef();

    const goToNext = () => {
        // @ts-ignore
        customSlider.current.slickNext()
    }

    const goToPrev = () => {
        // @ts-ignore
        customSlider.current.slickPrev()
    }

    const { t } = useTranslation('homepage')

    return (
        <section className="explore-categorias-container">
            <div className="page-content">
                <h1 className="h1-section-title">{t("Explore")}</h1>

                <p className="page-paragraph">
                    {t("Dê uma olhada nas nossas categorias de produtos e descubra a solução certa para o que você precisa.")}
                </p>
            </div>

            <Slider
                {...settings}
                // @ts-ignore
                ref={slider => (customSlider.current = slider)}
            >
                <Grid container>

                    <Grid item xs={6} md={3}>
                        <div className="explore-categorias-item">
                            <div className="explore-categorias-item-text">
                                <Link to="/categoria/etiq?Uso=Impressao" className="btn-link">
                                    <h1>{t("Impressão")}</h1>
                                </Link>
                            </div>
                            <img alt="" src={impressao} />
                        </div>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <div className="explore-categorias-item">
                            <div className="explore-categorias-item-text">
                                <Link to="/categoria/etiq?Uso=Escolar" className="btn-link">
                                    <h1>{t("Escolar")}</h1>
                                </Link>
                            </div>
                            <img alt="" src={escolar} />
                        </div>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <div className="explore-categorias-item">
                            <div className="explore-categorias-item-text">
                                <Link to="/categoria/etiq?Uso=Corporativo" className="btn-link">
                                    <h1>{t("Corporativo")}</h1>
                                </Link>
                            </div>
                            <img alt="" src={corporativo} />
                        </div>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <div className="explore-categorias-item">
                            <div className="explore-categorias-item-text">
                                <Link to="/categoria/etiq?Uso=Domestico" className="btn-link">
                                    <h1>{t("Doméstico")}</h1>
                                </Link>

                            </div>
                            <img alt="" src={domestico} />
                        </div>
                    </Grid>
                    
                </Grid>

                <Grid container>
                    
                    <Grid item xs={6} md={3}>
                        <div className="explore-categorias-item">
                            <div className="explore-categorias-item-text">
                                <Link to="/categoria/bloade" className="btn-link">
                                    <h1>{t("Blocos adesivos e marcadores")}</h1>
                                </Link>
                            </div>
                            <img alt="" src={blocoAdesivo} />
                        </div>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <div className="explore-categorias-item">
                            <div className="explore-categorias-item-text">
                                <Link to="/categoria/cartneg" className="btn-link">
                                    <h1>{t("Cartões para seu negócio")}</h1>
                                </Link>
                            </div>
                            <img alt="" src={cartao_negocio} />
                        </div>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <div className="explore-categorias-item">
                            <div className="explore-categorias-item-text">
                                <Link to="/categoria/imprslp" className="btn-link">
                                    <h1>{t("Impressoras & Etiquetas SLP")}</h1>
                                </Link>
                            </div>
                            <img alt="" src={smart_label_printer} />
                        </div>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <div className="explore-categorias-item">
                            <div className="explore-categorias-item-text">
                                <Link to="/categoria/papfot" className="btn-link">
                                    <h1>{t("Fotografia")}</h1>
                                </Link>
                            </div>
                            <img alt="" src={fotografia} />
                        </div>
                    </Grid>

                    
                </Grid>
                <Grid container>
                    <Grid item xs={6} md={3}>
                        <div className="explore-categorias-item">
                            <div className="explore-categorias-item-text">
                                <Link to="/categoria/sinali" className="btn-link">
                                    <h1>{t("Sinalização")}</h1>
                                </Link>
                            </div>
                            <img alt="" src={sinalizacao} />
                        </div>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <div className="explore-categorias-item">
                            <div className="explore-categorias-item-text">
                                <Link to="/categoria/tracam" className="btn-link">
                                    <h1>{t("Transfer para camiseta")}</h1>
                                </Link>
                            </div>
                            {/* Trocar imagem */}
                            <img alt="" src={transfer_camiseta} />
                        </div>
                    </Grid>
                    
                </Grid>
            </Slider>

            <span className="white-bg-btn">
                <NextPrevButtonGroup
                    goToNext={goToNext}
                    goToPrev={goToPrev}
                />
            </span>

        </section>
    )
}