import { useBoardContext } from "@beontag/tageditor";
import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import { useEditorContext } from "../../../../../context/editorContext/EditorContext";
import { useTranslation } from "react-i18next";

export default function EtiquetaPaginator() {
  const { boardFile, boardConfig } = useBoardContext();
  const { produtoSelecionado, numberOfPages } = useEditorContext();

  const { t } = useTranslation("editor");
  return (
    <>
      <div className="etiqueta-paginator-container">
        <button
          title="Anterior"
          onClick={() =>
            boardFile?.tagNumber != 1 && boardFile?.tagNumber
              ? (boardFile.tagNumber = boardFile?.tagNumber - 1)
              : ""
          }
        >
          <ArrowBackIosNewOutlined
            className={`etiqueta-paginator-arrow ${
              boardFile?.tagNumber != 1 ? "active-button" : ""
            }`}
          />
        </button>

        <span className="etiqueta-paginator-text">
          {t("Etiqueta")} &nbsp;
          <span className="etiqueta-paginator-current-page">
            {boardFile?.tagNumber}
          </span>
          &nbsp; {t("de")} &nbsp;
          <span className="etiqueta-paginator-total-pages">
            {boardFile && boardConfig && boardConfig.page
              ? boardConfig?.page?.columns * boardConfig?.page?.rows
              : (produtoSelecionado?.etiquetasPorFolha ?? 1) * numberOfPages}
            {}
            {/* {(produtoSelecionado?.etiquetasPorFolha * numberOfPages)} */}
          </span>
        </span>

        <button
          title="Próximo"
          onClick={() =>
            boardFile?.tagNumber !=
              ((produtoSelecionado?.etiquetasPorFolha ?? 1) * numberOfPages ||
                150) && boardFile?.tagNumber
              ? (boardFile.tagNumber = boardFile?.tagNumber + 1)
              : ""
          }
        >
          <ArrowForwardIosOutlined
            className={`etiqueta-paginator-arrow ${
              boardFile?.tagNumber !=
              (produtoSelecionado?.etiquetasPorFolha ?? 1) * numberOfPages
                ? "active-button"
                : ""
            }`}
          />
        </button>
      </div>
    </>
  );
}
