import { BoardContextProvider } from "@beontag/tageditor";
import { Box, Button } from "@mui/material";
import {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import PWAInstall from "../../components/editor/pwa";
import sEditor from "../../services/editorService";

import EditorContextProvider, {
  useEditorContext,
} from "../../context/editorContext/EditorContext";

import "../../styles/editor/main.css";

import AxiosInterceptor from "../../api/interceptor";

import EditorComponent from "../../components/editor";
import ITemaTemplateResponse from "../../interfaces/editor/TemaTemplateResponse";
import { IFormaEditor } from "../../interfaces/editor/formaEditor";
import { categoriaLoader } from "../../helpers/categoriaLoader";

import sProduto from "../../services/produtoService";
import { ICategoria } from "../../interfaces/produto/categoria";

import IProdutoCardPimaco from "../../interfaces/produto/produtoCardPimaco";
import UserContextProvider from "../../context/userContext/userContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { usePWAInstall } from 'react-use-pwa-install'
//import { Language } from "remix-i18next";

export interface ILoaderDataEditor {
  installPWA: boolean;
  templates: ITemaTemplateResponse;
  formas: IFormaEditor[];
  categoriasEditor: ICategoria[];
  produtosEditor: IProdutoCardPimaco[];
  //i18n: Record<string, Language>
}

function Editor() {
  const [searchParams, setSearchParams] = useSearchParams();
  const installPWA = searchParams.get("installPWA") === "true";
  const [templates, setTemplates] = useState<ITemaTemplateResponse>({
    temas: [],
    templates: [],
  });
  const [formas, setFormas] = useState<IFormaEditor[]>([]);
  const [categoriasEditor, setCategoriasEditor] = useState<ICategoria[]>([]);
  const [produtosEditor, setProdutosEditor] = useState<IProdutoCardPimaco[]>(
    []
  );
  const editorFileExtension = process.env.REACT_APP_EDITOR_FILE_EXTENSION;

  const [openPWAModal, setOpenPWAModal] = useState<boolean>(
    installPWA ?? false
  );

  const handleCloseModal = () => {
    if (openPWAModal) {
      setOpenPWAModal(false);
    }
  };

  let buttonRef = useRef<any>(null);
  let deferredPrompt: any;

  const { i18n } = useTranslation();

  const installPWAApp = usePWAInstall();

  const { t } = useTranslation("editor");
  return (
    <>
      <Box className="main-container">
        <UserContextProvider>
          <EditorContextProvider
            templates={templates}
            formas={formas}
            fileExtension={editorFileExtension || ".json"}
          >
            <AxiosInterceptor>
              <>
                <EditorComponent setOpenPWAModal={setOpenPWAModal} />

                <PWAInstall
                  open={openPWAModal}
                  handleCloseModal={handleCloseModal}
                  buttonRef={buttonRef}
                >
                  <Button
                    variant="contained"
                    className="btn btn-contained-primary"
                    onClick={installPWAApp}
                  >
                    {t("INSTALAR")}
                  </Button>
                </PWAInstall>
              </>
            </AxiosInterceptor>
          </EditorContextProvider>
        </UserContextProvider>
      </Box>
      <button
        ref={buttonRef}
        className="modal-pwa-button"
        title="Open modal PWA"
      />
    </>
  );
}

export default Editor;
