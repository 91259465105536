import api from "../api";

const getGabaritos = async function() {

    return api.get('gabaritos');
}

const gabaritoService = {
    getGabaritos,
}

export default gabaritoService;