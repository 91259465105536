import AssistentePimaco from "../../../components/assistente-busca/assistente-main";
import ContentHeader from "../../../components/common/content-header";

import header_image from '../../../assets/ferramenta_de_busca.webp'
import { stepBuilder } from '../../../components/assistente-busca/steps'
import { useEffect } from "react";

export default function FerramentaBusca() {

    const steps = stepBuilder();

    useEffect(() => {
		window.scroll({
            // @ts-ignore
			// eslint-disable-next-line no-restricted-globals
			top: top,
			left: 0,
            behavior: 'smooth'	
		})
	}, [])

    return (
        <>
            <ContentHeader
                image={header_image}
                imageClass="ferramenta-busca-header-image"
            >
                <h1 className="content-header-ferramenta-busca">Ferramenta de busca</h1>
            </ContentHeader>

            <div className="page-content">
                <h1>Assistente de busca</h1>
                <p className="page-paragraph">Precisando de uma ajuda na hora de escolher a etiqueta certa? Nosso assistente pode te ajudar</p>
                <p className="page-paragraph">Siga nosso passo a passo, selecionando as configurações que procura, e nós te mostraremos as melhores opções para você</p>

                <AssistentePimaco steps={steps}/>
            </div>
        </>
    )
}