import {
  AddPolylineFeature,
  AddPolylineFeatures,
  useBoardContext,
} from "@beontag/tageditor";
import {
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { useCallback, useState } from "react";

import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import {
  ArrowButtonIcon,
  SearchButtonIcon,
} from "../../../../../components/common/icons";
import { useEditorContext } from "../../../../../context/editorContext/EditorContext";
import ShapeItem from "./shapeItem";
import { useTranslation } from "react-i18next";

export default function Shapes(props: { setMenuAnchorEl: Function }) {
  const [polylineFeature, setPolylineFeature] = useState<AddPolylineFeatures>();

  const [newPolylineFeature, setNewPolylineFeature] =
    useState<AddPolylineFeatures>();

  const { formas } = useEditorContext();

  const { board, boardHistory } = useBoardContext();

  const [filterValue, setFilterValue] = useState<string>("");

  function onClickButton(e: any, dadosForma: string) {
    importObject(dadosForma);
    props.setMenuAnchorEl(e.target);
  }

  const importObject = useCallback(
    (object: string) => {
      boardHistory?.runPaused(() => {
        const content = JSON.parse(object);
        board?.importObjects(content);
      });
    },
    [board, boardHistory]
  );

  const handleFiltering = () => {
    if (filterValue !== "") {
      return formas.filter((x) =>
        x.descricao.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    return formas;
  };
  const { t } = useTranslation("editor");
  return (
    <>
      <AccordionItem uuid="shapes">
        <AccordionItemHeading>
          <AccordionItemButton>
            {t("Formas")}
            <ArrowButtonIcon onClick={() => undefined} />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                placeholder={t("Circulo") as string}
                fullWidth
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchButtonIcon onClick={() => undefined} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {formas &&
              handleFiltering().map((forma, index) => {
                return (
                  <AddPolylineFeature
                    featureUpdate={(f) => setPolylineFeature(f)}
                    key={forma.descricao}
                  >
                    <Grid
                      item
                      className="editor-shapes-grid-item editor-menu-item-teste-hover"
                      xs={4}
                      key={index}
                    >
                      <Tooltip disableInteractive title={forma.descricao}>
                        <MenuItem
                          sx={{ paddingLeft: "8px", paddingRight: "8px" }} //onClick={(e) => onClickButton(e, forma.dadosForma)}
                          onPointerUp={(e) =>
                            onClickButton(e, forma.dadosForma)
                          }
                        >
                          <ShapeItem forma={forma.dadosForma} />
                        </MenuItem>
                      </Tooltip>
                    </Grid>
                  </AddPolylineFeature>
                );
              })}
          </Grid>

          <AddPolylineFeature featureUpdate={(f) => setNewPolylineFeature(f)}>
            <MenuItem onClick={() => newPolylineFeature?.toggle()}>
              {!newPolylineFeature?.active
                ? t("Novo polígono")
                : t("Cancelar novo")}
            </MenuItem>
          </AddPolylineFeature>
        </AccordionItemPanel>
      </AccordionItem>
    </>
  );
}
