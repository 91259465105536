import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { ITemplate } from "../../../../interfaces/editor/Templates";

import CloseIcon from "@mui/icons-material/Close";
import { useBoardContext } from "@beontag/tageditor";
import TemplateView from "./templateView";
import { useEditorContext } from "../../../../context/editorContext/EditorContext";

import sTemplateService from "../../../../services/templateService";
import { useMessage } from "../../../../context/alertContext/context";

import { emptyTag } from "../../../../components/editor/steps/templateSelection/gridTemplatesEditor";
import { useTranslation } from "react-i18next";

export default function ModalConfirmDelete(props: {
  openModal: boolean;
  setOpenModal: Function;
  template: ITemplate;
  userCanDeleteTemplate: boolean;
}) {
  const { setTemplateEdicao, setTemplatesData, templates } = useEditorContext();
  const { boardConfig } = useBoardContext();

  const { showAlertApp } = useMessage();
  const { t } = useTranslation("editor");
  const handleDelete = (template: ITemplate) => {
    if (!props.userCanDeleteTemplate) {
      showAlertApp(
        t("Ops, parece que você não tem acesso à essa funcionalidade!"),
        "warning",
        5000
      );
    }

    sTemplateService
      .deleteTemplate(template.idTemplate)
      .then((resp) => {
        showAlertApp(t("Template apagado com sucesso!"), "success", 5000);
        setTemplateEdicao({
          idTema: 0,
          idTemplate: 0,
          nomept: "",
          nomeen: "",
          nomees: "",
          json: "",
        });
        setTemplatesData({
          ...templates,
          templates: templates.templates.filter(
            (x) => x.idTemplate != template.idTemplate
          ),
        });
        props.setOpenModal(false);
      })
      .catch((err) => {
        if (err.response.status == 403) {
          showAlertApp(
            t(
              "Ops, você não tem permissão para usar esta função, contate um administrador de sistema"
            ),
            "error",
            5000
          );
        }
        showAlertApp(
          t(
            "Houve um problema ao apagar o template, tente novamente mais tarde"
          ),
          "error",
          5000
        );
      });
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={() => props.setOpenModal(false)}
      scroll="paper"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>
        <IconButton onClick={() => props.setOpenModal(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="dialog-preview-template">
        <h1 className="editor-login-authentication-text">
          {t("Excluir template")}
        </h1>
        <p>{t("Tem certeza que deseja excluir este template?")}</p>

        {props.template.idTemplate != 0 && (
          <TemplateView
            handleDelete={() => undefined}
            handleEditTemplate={() => undefined}
            handleSelectedTemplate={() => undefined}
            template={props.template}
            emptyJson={emptyTag}
            boardConfig={boardConfig}
            userCanEditOrDeleteTemplate={{
              userCanDeleteTemplate: props.userCanDeleteTemplate,
              userCanEditTemplate: true,
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className="btn btn-outlined-primary"
          onClick={() => props.setOpenModal(false)}
        >
          {t("Cancelar")}
        </Button>

        <Button
          disableElevation
          variant="contained"
          className="btn btn-contained-primary"
          onClick={() => handleDelete(props.template)}
          autoFocus
        >
          {t("Confirmar")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
