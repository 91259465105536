import {
  Fonts,
  useBoardContext,
  useKeysCombinationHandler,
  usePropertyContext,
} from "@beontag/tageditor";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect } from "react";

import { fabric } from "fabric";

import { TypeBold, TypeItalic } from "react-bootstrap-icons";

import CloseIcon from "@mui/icons-material/Close";
import { ArrowsMove, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const CurvedText: React.FunctionComponent = () => {
  const { properties, setProperty } = usePropertyContext<fabric.CurvedText>();

  const keysPressCountBold = useKeysCombinationHandler(["Control", "b"]);
  const keysPressCountItalic = useKeysCombinationHandler(["Control", "i"]);
  useEffect(() => {
    if (keysPressCountBold) {
      fontWeightToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysPressCountBold]);

  useEffect(() => {
    if (keysPressCountItalic) {
      fontStyleToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysPressCountItalic]);

  const fontWeightToggle = () => {
    setProperty({
      fontWeight: properties.fontWeight === "normal" ? "bold" : "normal",
    });
  };

  const fontStyleToggle = () => {
    setProperty({
      fontStyle: properties.fontStyle === "normal" ? "italic" : "normal",
    });
  };

  const handleOnChange = (key: string, rawValue: string | number) => {
    setProperty({ [key]: rawValue });
  };

  const handleOnChangeBooleanProp = (key: string, rawValue: boolean) => {
    setProperty({ [key]: rawValue });
  };

  const { board } = useBoardContext();

  const closeWindow = () => {
    board?.setActiveObjects();
  };

  const { t } = useTranslation("editor");
  return (
    <>
      <div className="property-bar-title">
        {t("Propriedades do objeto")}
        <ArrowsMove size={20} className="icon left" />
        <IconButton
          onClick={() => closeWindow()}
          className="icon right not-draggable"
        >
          <XLg size={20} color="white" />
        </IconButton>
      </div>

      <Grid container className="tb-main-grid">
        <Grid item md={4}>
          <div className="property-bar-text-itens">
            <span>{t("Texto")}</span>
            &nbsp;
            <TextField
              fullWidth
              className="barcode-legenda-select"
              type="text"
              value={properties.text}
              onChange={(e) => handleOnChange("text", e.currentTarget.value)}
              variant="filled"
              onKeyDown={(e) => {
                if (e.key == "Delete") e.stopPropagation();
              }}
            />
          </div>
          <div className="property-bar-text-itens">
            <span>{t("Fonte")}</span>
            <Select
              value={properties.fontFamily || "Times New Roman"}
              onChange={(e) => handleOnChange("fontFamily", e.target.value)}
              variant="filled"
              className="barcode-legenda-select"
            >
              {Fonts.getList().map((font, index) => {
                return (
                  <MenuItem value={font} key={index}>
                    {" "}
                    {font}{" "}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="property-bar-text-itens">
            <span>{t("Tamanho da fonte")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { min: 0, step: 1 } }}
              value={properties.fontSize || 15}
              onChange={(e) =>
                handleOnChange("fontSize", e.currentTarget.value)
              }
              variant="filled"
            />
          </div>
          <Stack
            direction="row"
            className="property-bar-text-options-container text-options-align-left"
          >
            <IconButton
              aria-label="Negrito"
              size="large"
              onClick={fontWeightToggle}
              className={`property-bar-text-options-button ${
                properties.fontWeight == "bold" ? "active" : ""
              }`}
            >
              <TypeBold />
            </IconButton>

            <IconButton
              aria-label="Itálico"
              size="large"
              onClick={fontStyleToggle}
              className={`property-bar-text-options-button ${
                properties.fontStyle == "italic" ? "active" : ""
              }`}
            >
              <TypeItalic />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item md={4}>
          <div className="property-bar-text-itens">
            <label htmlFor="cor-do-objeto" className="cursor-pointer">
              {t("Cor do Objeto")}
            </label>
            <input
              id="cor-do-objeto"
              className="property-bar-text-color-input"
              type="color"
              value={properties.fill?.toString() || "#000"}
              onChange={(e) => handleOnChange("fill", e.currentTarget.value)}
            ></input>
          </div>
          <div className="property-bar-text-itens">
            <label htmlFor="cor-da-borda" className="cursor-pointer">
              {t("Cor da Borda")}
            </label>
            <input
              id="cor-da-borda"
              className="property-bar-text-color-input"
              type="color"
              value={properties.strokeStyle?.toString() || "#000"}
              onChange={(e) =>
                handleOnChange("strokeStyle", e.currentTarget.value)
              }
            ></input>
          </div>
          <div className="property-bar-text-itens">
            <span>{t("Transparência do Objeto")}</span>
            &nbsp;
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
              value={properties.opacity || 1}
              onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
              variant="filled"
            />
          </div>
          <div className="property-bar-text-itens">
            <span>{t("Largura da borda")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 9, step: 1 } }}
              value={properties.strokeWidth?.toString() || 0}
              onChange={(e) =>
                handleOnChange("strokeWidth", +e.currentTarget.value)
              }
              variant="filled"
            />
          </div>
        </Grid>

        <Grid item xs={4}>
          <div className="property-bar-text-itens not-draggable">
            <span className="curved-text-kerning-span">{t("Diâmetro")}</span>
            <Slider
              defaultValue={properties.diameter ?? 1000}
              min={properties.minDiameter}
              max={properties.maxDiameter}
              step={10}
              value={properties.diameter ?? 1000}
              // @ts-ignore
              onChange={(e) => handleOnChange("diameter", e.target.value)}
            />
          </div>
          <div className="property-bar-text-itens not-draggable">
            <span className="curved-text-kerning-span">{t("Kerning")}</span>
            <Slider
              defaultValue={properties.kerning ?? 0}
              min={-10}
              max={10}
              step={1}
              value={properties.kerning ?? 0}
              // @ts-ignore
              onChange={(e) => handleOnChange("kerning", e.target.value)}
            />
          </div>

          <div className="property-bar-text-itens extra-bottom-margin">
            <FormGroup>
              <FormControlLabel
                className="editor-numeration-checkbox"
                control={
                  <Checkbox
                    value={Boolean(properties.flipped)}
                    name="checkbox-subtitle"
                    color="primary"
                    checked={Boolean(properties.flipped)}
                    checkedIcon={<CloseIcon />}
                    onChange={(e) =>
                      handleOnChangeBooleanProp(
                        "flipped",
                        e.currentTarget.checked
                      )
                    }
                  />
                }
                label={t("Invertido") as string}
              />
            </FormGroup>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CurvedText;
