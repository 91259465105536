import { Button, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import { ICategoria, IFiltroCategoria } from '../../interfaces/produto/categoria';

import AccordionMenuProdutos from '../../components/header-footer/accordion-menu-produtos'

import SearchIcon from '@mui/icons-material/Search';
import { ArrowButtonIcon } from '../common/icons';
//import LanguageSelector from './language-selector.tsx.todo';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function NavbarMobile(props: { categorias: ICategoria[] }) {

    const [selectedFilter, setSelectedFilter] = useState<IFiltroCategoria>({ nome: '', items: [], alias: '' })
    const [expandedItem, setExpandedItem] = useState<number>(0);

    const categorias: ICategoria[] = props.categorias;

    const { t } = useTranslation('homepage');

    return (
        <div className='navbar-mobile-accordion-container'>

            <div className='navbar-mobile-search-container'>
                <TextField
                    variant='filled'
                    className='navbar-mobile-search-textfield'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize='large' />

                            </InputAdornment>

                        )
                    }}
                />
                <Link to="/ferramenta_busca" className="btn-link">
                    <Button
                        className='btn btn-outlined-primary'
                        variant='outlined'
                    >
                        {t('BUSCA GUIADA')}
                    </Button>
                </Link>
            </div>


            <span className={`accordion-menu ${selectedFilter.nome == '' ? 'accordion-menu-selected' : ''}`}>
                <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {t("PRODUTOS")}
                                <ArrowButtonIcon onClick={() => undefined} />
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <AccordionMenuProdutos
                                categorias={categorias}
                                selectedFilter={selectedFilter}
                                expandedItem={expandedItem}
                                setExpandedItem={setExpandedItem}
                                setSelectedFilter={setSelectedFilter}
                            />

                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <Link to="/assistente_impressao">
                                    {t("SOFTWARE DE IMPRESSÃO")}
                                </Link>
                            </AccordionItemButton>
                        </AccordionItemHeading>

                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <a
                                    href="https://print.pimaco.com.br"
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {t("PIMACO PRINT")}
                                </a>
                            </AccordionItemButton>
                        </AccordionItemHeading>

                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {t("DOWNLOADS")}
                                <ArrowButtonIcon onClick={() => undefined} />
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <ul className='ul-navbar-mobile-itens'>
                                <li>
                                    <Link to="/downloads/parametros">
                                        {t("PARÂMETROS DE IMPRESSÃO")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/downloads/gabaritos">
                                        {t("GABARITOS")}
                                    </Link>
                                </li>

                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {t("INSTITUCIONAL")}
                                <ArrowButtonIcon onClick={() => undefined} />
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <ul className='ul-navbar-mobile-itens'>
                                <li>
                                    <Link to="/quemsomos">
                                        {t("QUEM SOMOS")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/politica_qualidade">
                                        {t("POLÍTICA DE QUALIDADE")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/politica_privacidade">
                                        {t("POLÍTICA DE PRIVACIDADE")}
                                    </Link>
                                </li>

                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <Link to="/faq">
                                    {t("DÚVIDAS FREQUENTES")}
                                </Link>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <Link to="/contato">
                                    {t("CONTATO")}
                                </Link>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                    </AccordionItem>
                </Accordion>
            </span>

            {selectedFilter.nome != '' ?
                <>
                    <span className={`accordion-menu ${selectedFilter.nome != '' ? 'accordion-filtros-selected' : ''}`}>
                        <Accordion >
                            <AccordionItem dangerouslySetExpanded={true}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {t(selectedFilter.nome)}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ul className='ul-navbar-mobile-itens'>
                                        {selectedFilter.items?.map((filtroItem) => {
                                            return (
                                                <li key={filtroItem.idFiltroItem}>
                                                    <Link to={`/categoria/${filtroItem.alias}`}>
                                                        {filtroItem.nome}
                                                    </Link>

                                                </li>
                                            )
                                        })}
                                    </ul>
                                </AccordionItemPanel>
                            </AccordionItem>


                        </Accordion>

                        <div className="accordion-menu-btn-container">
                            <Button
                                className='btn btn-outlined-primary'
                                variant="outlined"
                                onClick={() => setSelectedFilter({ nome: '', items: [], alias: '' })}
                            >
                                {t("VOLTAR")}
                            </Button>
                        </div>


                    </span>



                </>
                : ''}

            {/* <LanguageSelector /> */}
        </div>
    )
}