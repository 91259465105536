import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import icone from '../../assets/icons/duvidas_frequentes_com_ler_etiquetas.webp'

export default function VisiteDuvidasFrequentesLeituraEtiquetaPimaco () {

    const { t } = useTranslation('homepage')

    return (
        <div className="visite-duvidas-container visite-duvidas-como-ler-etiquetas">
            <div className="visite-duvidas-image-container">
                <img src={icone}/>
            </div>
            <div className="visite-duvidas-text-container">
                <h1>{t("Ainda com dúvidas?")}</h1>
                <Link
                    to="/faq"
                    className="btn-link"
                >           
                    <Button
                        variant="outlined"
                        className="btn btn-outlined-primary"
                    >
                        {t("Fale conosco")}
                    </Button>
                </Link>
            </div>

            <div className="shape-divider-top-visite-duvidas-frequentes-como-ler-etiquetas">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
        </div>
    )
}
