import {
  Fonts,
  useBoardContext,
  useKeysCombinationHandler,
  usePropertyContext,
} from "@beontag/tageditor";
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect } from "react";

import OverlineIcon from "@mui/icons-material/FormatOverline";

import {
  TypeBold,
  TypeItalic,
  TypeStrikethrough,
  TypeUnderline,
} from "react-bootstrap-icons";

import { fabric } from "fabric";

import { ArrowsMove, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const SequentialText: React.FunctionComponent = () => {
  const { properties, setProperty } =
    usePropertyContext<fabric.SequentialText>();

  const keysPressCountBold = useKeysCombinationHandler(["Control", "b"]);

  useEffect(() => {
    if (keysPressCountBold) {
      fontWeightToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysPressCountBold]);

  const keysPressCountItalic = useKeysCombinationHandler(["Control", "i"]);
  useEffect(() => {
    if (keysPressCountItalic) {
      fontStyleToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysPressCountItalic]);

  const fontWeightToggle = () => {
    setProperty({
      fontWeight: properties.fontWeight === "normal" ? "bold" : "normal",
    });
  };

  const fontStyleToggle = () => {
    setProperty({
      fontStyle: properties.fontStyle === "normal" ? "italic" : "normal",
    });
  };

  const handleOnChange = (
    key: string,
    rawValue: string | boolean,
    type?: "int"
  ) => {
    let value: string | boolean | number = rawValue;

    if (type === "int" && typeof rawValue === "string") {
      value = Number.parseInt(rawValue);
    }

    setProperty({ [key]: value });
  };

  const { board } = useBoardContext();

  const closeWindow = () => {
    board?.setActiveObjects();
  };
  const { t } = useTranslation("editor");

  return (
    <>
      <div className="property-bar-title">
        {t("Propriedades do objeto")}
        <ArrowsMove size={20} className="icon left" />
        <IconButton
          onClick={() => closeWindow()}
          className="icon right not-draggable"
        >
          <XLg size={20} color="white" />
        </IconButton>
      </div>

      <Grid container className="tb-main-grid">
        <Grid item md={6} lg={3}>
          <div className="property-bar-text-itens">
            <span>{t("Inicio:")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { step: 1 } }}
              value={properties.sequenceStarts}
              onChange={(e) =>
                handleOnChange("sequenceStarts", e.currentTarget.value, "int")
              }
              variant="filled"
            />
          </div>
          <div className="property-bar-text-itens">
            <span>{t("Fim:")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { step: 1 } }}
              value={properties.sequenceEnds}
              onChange={(e) =>
                handleOnChange("sequenceEnds", e.currentTarget.value, "int")
              }
              variant="filled"
            />
          </div>
          <div className="property-bar-text-itens">
            <span>{t("Incremento:")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { step: 1 } }}
              value={properties.sequenceIncrement}
              onChange={(e) =>
                handleOnChange(
                  "sequenceIncrement",
                  e.currentTarget.value,
                  "int"
                )
              }
              variant="filled"
            />
          </div>
        </Grid>
        <Grid item md={6} lg={3}>
          <div className="property-bar-text-itens">
            <span>{t("Prefixo:")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="text"
              value={properties.sequencePrefix}
              onChange={(e) =>
                handleOnChange("sequencePrefix", e.currentTarget.value)
              }
              variant="filled"
            />
          </div>
          <div className="property-bar-text-itens">
            <span>{t("Sufixo:")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="text"
              value={properties.sequenceSuffix}
              onChange={(e) =>
                handleOnChange("sequenceSuffix", e.currentTarget.value)
              }
              variant="filled"
            />
          </div>
        </Grid>
        <Grid item md={6} lg={3}>
          <div className="property-bar-text-itens">
            <span>{t("Fonte")}</span>
            <Select
              value={properties.fontFamily || "Times New Roman"}
              onChange={(e) => handleOnChange("fontFamily", e.target.value)}
              variant="filled"
              className="barcode-legenda-select"
            >
              {Fonts.getList().map((font, index) => {
                return (
                  <MenuItem value={font} key={index}>
                    {" "}
                    {font}{" "}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="property-bar-text-itens">
            <span>{t("Tamanho da fonte")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { min: 0, step: 1 } }}
              value={properties.fontSize || 15}
              onChange={(e) =>
                handleOnChange("fontSize", e.currentTarget.value)
              }
              variant="filled"
            />
          </div>
          <Stack
            direction="row"
            className="property-bar-text-options-container"
          >
            <IconButton
              aria-label="Negrito"
              size="large"
              onClick={fontWeightToggle}
              className={`property-bar-text-options-button ${
                properties.fontWeight == "bold" ? "active" : ""
              }`}
            >
              <TypeBold />
            </IconButton>

            <IconButton
              aria-label="Itálico"
              size="large"
              onClick={fontStyleToggle}
              className={`property-bar-text-options-button ${
                properties.fontStyle == "italic" ? "active" : ""
              }`}
            >
              <TypeItalic />
            </IconButton>

            <IconButton
              aria-label="Sublinhado"
              size="large"
              onClick={() => {
                if (properties.underline) {
                  handleOnChange("underline", false);
                } else {
                  handleOnChange("underline", true);
                }
              }}
              className={`property-bar-text-options-button ${
                properties.underline ? "active" : ""
              }`}
            >
              <TypeUnderline />
            </IconButton>

            <IconButton
              aria-label="Sobrelinha"
              size="large"
              onClick={() => {
                if (properties.overline) {
                  handleOnChange("overline", false);
                } else {
                  handleOnChange("overline", true);
                }
              }}
              className={`property-bar-text-options-button ${
                properties.overline ? "active" : ""
              }`}
            >
              <OverlineIcon />
            </IconButton>

            <IconButton
              aria-label="Tachado"
              size="large"
              onClick={() => {
                if (properties.linethrough) {
                  handleOnChange("linethrough", false);
                } else {
                  handleOnChange("linethrough", true);
                }
              }}
              className={`property-bar-text-options-button ${
                properties.linethrough ? "active" : ""
              }`}
            >
              <TypeStrikethrough />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={6} lg={3}>
          <div className="property-bar-text-itens">
            <label htmlFor="cor-do-objeto" className="cursor-pointer">
              {t("Cor do Objeto")}
            </label>
            <input
              id="cor-do-objeto"
              className="property-bar-text-color-input"
              type="color"
              value={properties.fill?.toString() || "#000"}
              onChange={(e) => handleOnChange("fill", e.currentTarget.value)}
            ></input>
          </div>

          <div className="property-bar-text-itens">
            <label htmlFor="cor-da-borda" className="cursor-pointer">
              {t("Cor da Borda")}
            </label>
            <input
              id="cor-da-borda"
              className="property-bar-text-color-input"
              type="color"
              value={properties.stroke?.toString() || "#000"}
              onChange={(e) => handleOnChange("stroke", e.currentTarget.value)}
            ></input>
          </div>

          <div className="property-bar-text-itens">
            <span>{t("Transparência do Objeto")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
              value={properties.opacity || 1}
              onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
              variant="filled"
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SequentialText;
