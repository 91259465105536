import { IStep } from "../../../interfaces/assistenteStep";

import {
    Impressao,
    Escrever,
    NA,
    NAImpressao
} from './steps_tipo_interacao'

import {
    Retangular,
    Circular,
    Estrela,
    Coracao,
} from './steps_formatos'

export const CartasEnvelopes:IStep = {
    stepId: 48,
    text: 'Cartas e envelopes',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em cartas e envelopes',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'CartasEnvelopes',
}

export const Artesanato:IStep = {
    stepId: 49,
    text: 'Artesanato',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para artesanato',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Artesanato',
}

export const FestasEventos:IStep = {
    stepId: 50,
    text: 'Festas e eventos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para festas e eventos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'FestasEventos',
}

export const Confeitaria:IStep = {
    stepId: 51,
    text: 'Confeitaria',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em confeitaria',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Confeitaria',
}

export const Transfer:IStep = {
    stepId: 52,
    text: 'Transfer',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em transfer',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: 'Uso',
    value: 'Transfer',
}

export const PlacaSinalizacao:IStep = {
    stepId: 53,
    text: 'Placa de sinalização',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para placas de sinalização',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: 'Uso',
    value: 'PlacaSinalizacao',
}

export const Planners:IStep = {
    stepId: 54,
    text: 'Planners',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em planners',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Planners',
}

export const Presentes:IStep = {
    stepId: 55,
    text: 'Presentes',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para presentes',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Presentes',
}

export const EmbalagensCosmeticos:IStep = {
    stepId: 56,
    text: 'Embalagens de cosmeticos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'embalagens de cosméticos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'EmbalagensCosmetico',
}

export const ImpressaoTermica:IStep = {
    stepId: 57,
    text: 'Impressão térmica',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para impressão térmica',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'ImpressaoTermica',
}

export const PapelFotografico:IStep = {
    stepId: 58,
    text: 'Papel fotográfico',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'papel fotográfico',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: 'Uso',
    value: 'PapelFotografico',
}

export const CDsDVDs:IStep = {
    stepId: 59,
    text: 'CDs e DVDs',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em CDs e DVDs',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: 'Uso',
    value: 'CDsDVDs'
}

export const ProtegerTrabalhosSuperficies: IStep = {
    stepId: 93,
    text: 'Proteger trabalhos artesanais ou superficies',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'proteger trabalhos artesanais ou superficies',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: '',
    value: 'EncaparProtegerLivrosCadernos'
}

export function stepBuilderProjPersonalizado() {

    Artesanato.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Coracao, Estrela], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
    )

    FestasEventos.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Coracao, Estrela], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao
    )

    Confeitaria.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Coracao, Estrela], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        NAImpressao.builder ? NAImpressao.builder(NAImpressao, [], true) : NAImpressao
    )

    Planners.nextStep.push(
        Retangular,
        Circular,
        Estrela,
        Coracao
    )

    Presentes.nextStep.push(
        Retangular,
        Circular,
        Estrela,
        Coracao
    )

    EmbalagensCosmeticos.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao
    )

    ImpressaoTermica.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [], true) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        NAImpressao.builder ? NAImpressao.builder(NAImpressao, [], true) : NAImpressao
    )

    PapelFotografico.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [], true) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
    )


    return [
        Artesanato,
        FestasEventos,
        Confeitaria,
        Transfer,
        PlacaSinalizacao,
        Planners,
        Presentes,
        EmbalagensCosmeticos,
        ImpressaoTermica,
        PapelFotografico,
        CDsDVDs,
        ProtegerTrabalhosSuperficies
    ];
}