import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";

import iconePimaco from "../../../assets/logo-ETQVRSEX.webp";

import sUsuario from "../../../services/usuarioService";
import { handleFormErrors } from "../../../helpers/formErrorHandler";

import { useMessage } from "../../../context/alertContext/context";
import ShowPasswordInputAdornment from "./showPasswordInputAdornment";
import { useTranslation } from "react-i18next";

function CriarNovaSenha(props: { modifyActiveComponent: Function }) {
  const [inputPasswordVisible, setInputPasswordVisible] =
    useState<boolean>(false);

  const { showAlertApp } = useMessage();
  const { t } = useTranslation("editor");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  });

  const [errors, setErrors] = useState({
    oldPassword: false,
    newPassword: false,
    newPasswordConfirmation: false,
  });

  const handleInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdatePassword = async (e: any) => {
    e.preventDefault();
    if (handleFormErrors(formData, errors, setErrors)) {
      return;
    }

    setIsLoading(true);

    await sUsuario
      .updatePassword(formData)
      .then((resp) => {
        setIsLoading(false);
        props.modifyActiveComponent("login");
        showAlertApp(
          t("Senha atualizada com sucesso, faça login para utilizar o sistema"),
          "success",
          5000
        );
      })
      .catch((err) => {
        setIsLoading(false);
        showAlertApp(
          t("Erro ao atualizar senha, tente novamente mais tarde"),
          "error",
          5000
        );
      });
  };

  return (
    <div className="editor-login">
      <div className="editor-login-container">
        <div className="editor-login-authentication-image">
          <img
            src={iconePimaco}
            alt=""
            className="editor-login-authentication-image-img"
          />
        </div>

        <p className="page-paragraph">{t("Cadastre uma nova senha")}</p>

        <Grid container rowSpacing={2} className="editor-login-authentication">
          <Grid item xs={12}>
            <h1 className="editor-login-authentication-text">
              {t("Criar nova senha")}
            </h1>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="oldPassword"
              type={inputPasswordVisible ? "text" : "password"}
              name="oldPassword"
              aria-label="Senha antiga:"
              variant="filled"
              value={formData.oldPassword}
              onChange={handleInputChange}
              onPaste={(e) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  oldPassword: e.clipboardData.getData("Text").trim(),
                });
              }}
              error={!errors.oldPassword}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    disablePointerEvents
                    className="editor-login-authentication-fields-adornment"
                  >
                    <span className="form-label">{t("Senha provisória")}</span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <ShowPasswordInputAdornment
                    inputVisible={inputPasswordVisible}
                    setInputVisible={setInputPasswordVisible}
                  />
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="newPassword"
              type={inputPasswordVisible ? "text" : "password"}
              name="newPassword"
              aria-label="Senha nova:"
              variant="filled"
              value={formData.newPassword}
              onChange={handleInputChange}
              error={!errors.newPassword}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    disablePointerEvents
                    className="editor-login-authentication-fields-adornment"
                  >
                    <span className="form-label">{t("Nova senha")}</span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <ShowPasswordInputAdornment
                    inputVisible={inputPasswordVisible}
                    setInputVisible={setInputPasswordVisible}
                  />
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="newPasswordConfirmation"
              type={inputPasswordVisible ? "text" : "password"}
              name="newPasswordConfirmation"
              aria-label="COnfirmar nova senha:"
              variant="filled"
              value={formData.newPasswordConfirmation}
              onChange={handleInputChange}
              error={!errors.newPasswordConfirmation}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    disablePointerEvents
                    className="editor-login-authentication-fields-adornment"
                  >
                    <span className="form-label">{t("Confirme senha")}</span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <ShowPasswordInputAdornment
                    inputVisible={inputPasswordVisible}
                    setInputVisible={setInputPasswordVisible}
                  />
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              className="form-contato-submit-button btn btn-contained-primary"
              disableElevation
              onClick={handleUpdatePassword}
            >
              {t("Enviar")}
            </Button>

            <p
              role="button"
              className="editor-login-paragraphs"
              onKeyDown={() => props.modifyActiveComponent("login")}
              onClick={() => props.modifyActiveComponent("login")}
            >
              {t("Retornar para a tela de autenticação")}
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default CriarNovaSenha;
