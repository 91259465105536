import { useCallback, useEffect } from "react";
import {
  objectFactory,
  useBoardContext,
  useKeysCombinationHandler,
} from "@beontag/tageditor";
import { useClipboardContext } from "./ClipboardContext";
import { Button } from "@mui/material";
import { Clipboard2Fill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export interface PasteProps {
  pasteDisplacement: number;
}

const Paste: React.FunctionComponent<PasteProps> = (props) => {
  const { board, boardHistory } = useBoardContext();
  const { clipboard } = useClipboardContext();
  const { t } = useTranslation("editor");
  const paste = useCallback(() => {
    if (board && boardHistory && clipboard?.length) {
      clipboard.forEach((obj) => {
        obj.set({
          left: obj.left! + props.pasteDisplacement,
          top: obj.top! + props.pasteDisplacement,
        });
      });

      objectFactory.Object.clone(clipboard, undefined).then((clones) => {
        boardHistory.runPaused(() => {
          clones.forEach((c) => board.add(c));
        });

        // board.fireModified(...clones);

        board.setActiveObjects(...clones);
      });
    }
  }, [props.pasteDisplacement, board, boardHistory, clipboard]);

  const CtrlVPressCount = useKeysCombinationHandler(["Control", "V"]);
  useEffect(() => {
    if (CtrlVPressCount) {
      paste();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CtrlVPressCount]);

  return (
    <Button
      className="btn"
      startIcon={<Clipboard2Fill size={16} />}
      onClick={paste}
      disabled={!clipboard?.length}
    >
      {t("Colar")}
    </Button>
  );
};

export default Paste;
