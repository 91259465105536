import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import IEditorContext from "../../interfaces/context/editorContext";
import { IEtiquetaUsuario } from "../../interfaces/editor/etiquetaUsuario";
import { IFormaEditor } from "../../interfaces/editor/formaEditor";
import ITemaTemplateResponse from "../../interfaces/editor/TemaTemplateResponse";
import { ITemplate } from "../../interfaces/editor/Templates";
import IProdutoCardPimaco from "../../interfaces/produto/produtoCardPimaco";
import sProduto from "../../services/produtoService";
import sEditor from "../../services/editorService";
import { useTranslation } from "react-i18next";
import { ICategoria } from "../../interfaces/produto/categoria";

const EditorContext = createContext<IEditorContext | undefined>(undefined);

const EditorContextProvider = (props: {
  children: JSX.Element;
  templates: ITemaTemplateResponse;
  formas: IFormaEditor[];
  fileExtension: string;
}) => {
  const { i18n } = useTranslation();

  const [categoriasEditor, setCategoriasEditor] = useState<ICategoria[]>([]);
  const [categoriasSite, setCategoriasSite] = useState<ICategoria[]>([]);
  const [produtosEditor, setProdutosEditor] = useState<IProdutoCardPimaco[]>(
    []
  );
  const [formas, setFormas] = useState<IFormaEditor[]>([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState<
    IProdutoCardPimaco | undefined
  >(undefined);
  const [etiquetaData, setEtiquetaData] = useState<string>("");
  const [etiquetaUsuario, setEtiquetaUsuarioState] = useState<
    IEtiquetaUsuario | undefined
  >();
  const [templatesData, setTemplatesData] = useState<ITemaTemplateResponse>(
    props.templates
  );
  const [numberOfPages, setNumberOfPages] = useState<number>(1);

  const [templateEdicao, setTemplateEdicao] = useState<ITemplate>({
    idTema: 0,
    idTemplate: 0,
    nomept: "",
    nomeen: "",
    nomees: "",
    json: "",
  });

  const selecionarProduto = useCallback((produto: IProdutoCardPimaco) => {
    setProdutoSelecionado(produto);
  }, []);

  const limparProdutoSelecionado = useCallback(() => {
    setProdutoSelecionado(undefined);
  }, []);

  const setDadosEtiqueta = useCallback((dados?: string) => {
    setEtiquetaData(dados || "");
  }, []);

  const setEtiquetaUsuario = useCallback(
    (etiqueta: IEtiquetaUsuario | undefined) => {
      if (!etiqueta?.idProduto && etiqueta?.produto) {
        etiqueta.idProduto = etiqueta.produto.idProduto;
      }
      setEtiquetaUsuarioState(etiqueta);
      if (etiqueta) setEtiquetaData(etiqueta.dadosEtiqueta);
    },
    []
  );

  const setTemplateEdicaoAdmin = useCallback((template: ITemplate) => {
    let locale = i18n.language;
    sEditor
      .listTemplateEditor(locale, template.idTemplate)
      .then((resp) => {
        setTemplateEdicao(resp.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSetTemplatesData = useCallback(
    (templates: ITemaTemplateResponse) => {
      setTemplatesData(templates);
    },
    []
  );

  const setterNumberOfPages = useCallback((pages: number) => {
    setNumberOfPages(pages);
  }, []);

  useEffect(() => {
    let locale = i18n.language;
    sEditor
      .listTemplates(locale)
      .then((resp) => {
        setTemplatesData(resp.data);
      })
      .catch((err) => {
        console.error(err);
      });

    sEditor
      .listFormas()
      .then((resp) => {
        setFormas(resp.data);
      })
      .catch((err) => {
        console.error(err);
      });

    sProduto
      .obtemCategorias(locale, true, true)
      .then((resp) => {
        //const resultadoLoader = categoriaLoader(resp.data.categorias);
        setCategoriasEditor(resp.data.categoriasDB);
        setProdutosEditor(resp.data.produtos);
      })
      .catch((err) => {
        console.error(err);
      });

    sProduto
      .obtemCategorias(locale, false)
      .then((resp) => {
        setCategoriasSite(resp.data.categoriasDB);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [i18n.language]);

  const data: IEditorContext = useMemo(
    () => ({
      produtoSelecionado,
      selecionarProduto,
      limparProdutoSelecionado,
      templates: templatesData,
      setTemplatesData: handleSetTemplatesData,
      setEtiqueta: setDadosEtiqueta,
      etiquetaData,
      etiquetaUsuario,
      setEtiquetaUsuario,
      formas,
      templateEdicao,
      setTemplateEdicao: setTemplateEdicaoAdmin,
      numberOfPages,
      setNumberOfPages: setterNumberOfPages,
      fileExtension: props.fileExtension,
      categoriasEditor,
      categoriasSite,
      produtosEditor,
    }),
    [
      produtoSelecionado,
      selecionarProduto,
      limparProdutoSelecionado,
      templatesData,
      handleSetTemplatesData,
      setDadosEtiqueta,
      etiquetaData,
      etiquetaUsuario,
      setEtiquetaUsuario,
      formas,
      templateEdicao,
      setTemplateEdicaoAdmin,
      numberOfPages,
      setterNumberOfPages,
      props.fileExtension,
      categoriasEditor,
      categoriasSite,
      produtosEditor,
    ]
  );

  return (
    <EditorContext.Provider value={data}>
      {props.children}
    </EditorContext.Provider>
  );
};

const useEditorContext = () => {
  const context = useContext(EditorContext);

  if (context === undefined) {
    throw new Error(
      "useEditorContext must be used within a EditorContextProvider"
    );
  }

  return context;
};

export { useEditorContext };

export default EditorContextProvider;
