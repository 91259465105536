import { useState } from "react";
import {
  useBoardContext,
  AlignFeature,
  AlignFeatures,
} from "@beontag/tageditor";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  AlignStart,
  AlignEnd,
  AlignCenter,
  AlignTop,
  AlignBottom,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const Alignment = () => {
  const { board } = useBoardContext();
  const [alignFeature, setAlignFeature] = useState<AlignFeatures>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation("editor");
  return (
    <AlignFeature featureUpdate={(f) => setAlignFeature(f)}>
      <Button
        className="btn"
        startIcon={<AlignStart size={16} />}
        endIcon={<KeyboardArrowDown />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={!board?.activeObjects.length}
      >
        {t("Alinhamento")}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          className: "menu-dropdown",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <MenuItem onClick={() => alignFeature?.align("left")}>
          <ListItemIcon>
            <AlignStart size={16} />
          </ListItemIcon>

          <ListItemText> {t("À esquerda")} </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => alignFeature?.align("right")}>
          <ListItemIcon>
            <AlignEnd size={16} />
          </ListItemIcon>

          <ListItemText> {t("À direita")} </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => alignFeature?.align("center")}>
          <ListItemIcon>
            <AlignCenter size={16} />
          </ListItemIcon>

          <ListItemText>{t("Ao centro")} </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => alignFeature?.align("top")}>
          <ListItemIcon>
            <AlignTop size={16} />
          </ListItemIcon>

          <ListItemText> {t("No topo")} </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => alignFeature?.align("bottom")}>
          <ListItemIcon>
            <AlignBottom size={16} />
          </ListItemIcon>

          <ListItemText> {t("No fundo")} </ListItemText>
        </MenuItem>
      </Menu>
    </AlignFeature>
  );
};

export default Alignment;
