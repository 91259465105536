import api from '../api'
import { ITemplate } from '../interfaces/editor/Templates';

const saveAsTemplate = async function(categoria: number, json: string, nomept: string,nomeen:string,nomees:string) {

    const newTemplate: ITemplate = {
        idTemplate: 0,
        idTema: categoria,
        json: json,
        nomept: nomept,
        nomeen: nomeen,
        nomees: nomees,
    }

    return api.post('templates', newTemplate);
}

const updateTemplate = async function (template: ITemplate) {

    return api.put(`templates/${template.idTemplate}`, template)
}

const deleteTemplate = async function (idTemplate: number) {

    return api.delete(`templates/${idTemplate}`)
}

const templateService = {
    saveAsTemplate,
    updateTemplate,
    deleteTemplate
}

export default templateService;