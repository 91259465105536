import { FacebookLogo, InstagramLogo, YoutubeLogo } from "../common/social-logos"

export default function SocialIcons() {
    return (
        <>
            <a
                href="https://www.facebook.com/pimaco.oficial/"
                target="_blank"
                rel='noopener noreferrer'
                title='facebook'
            >
                <FacebookLogo />
            </a>
            <a
                href="https://www.instagram.com/pimaco.oficial/"
                target="_blank"
                rel='noopener noreferrer'
                title='instagram'
            >
                <InstagramLogo />
            </a>
        </>
    )
}