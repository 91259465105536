import { useCallback } from "react";
import { useBoardContext } from "@beontag/tageditor";
import { Button } from "@mui/material";
import { PhoneLandscape } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const Orientation = () => {
  const { board } = useBoardContext();
  const { t } = useTranslation("editor");
  const toggleOrientation = useCallback(
    (value: number) => {
      if (board) {
        board.angle = value;
      }
    },
    [board]
  );

  return (
    <>
      <Button
        className="btn"
        startIcon={<PhoneLandscape size={16} />}
        onClick={() => toggleOrientation(0)}
        disabled={board?.angle === 0}
      >
        {t("Paisagem")}
      </Button>

      <Button
        className="btn"
        startIcon={
          <PhoneLandscape size={16} className="orientation-portrait-icon" />
        }
        onClick={() => toggleOrientation(90)}
        disabled={board?.angle === 90}
      >
        {t("Retrato")}
      </Button>
    </>
  );
};

export default Orientation;
