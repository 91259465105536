import { useEffect, useRef, useState } from "react";

import ProdutoFilterPimaco from "../../../components/produto/produtoFilter";
import IProdutoCard from "../../../interfaces/produto/produtoCardPimaco";
import { useTranslation } from "react-i18next";

import ProdutoCard from "../../../components/produto/produto-card/produtoCard";

import sProduto from "../../../services/produtoService";

import CategoriaHeaderCarousel from "../../../components/produto/categorias/categoria-header-carousel";
import ConhecaAssistenteBuscaPimaco from "../../../components/landing-page/conheca-assistente-busca-pimaco";
import IProdutoCardPimaco from "../../../interfaces/produto/produtoCardPimaco";
import ProdutoList from "../../../components/produto/produto-list";

import { categorias_data } from "../../../components/produto/categorias/categoria-header-data";
import CategoriaHeaderBase from "../../../components/produto/categorias/categoria-header-base";
import { ICategoria } from "../../../interfaces/produto/categoria";

import { cores } from "../../../components/produto/produtoFilter";

import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";
import { useParams, useSearchParams } from "react-router-dom";

function Categoria() {

	const { categoria } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const filters = Object.fromEntries(searchParams);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [categoriaState, setCategoria] = useState<string>('');
	const [filteredCores, setFilteredCores] = useState<{ value: string, label: string}[]>([]);
	const [uso, setUso] = useState<string>('');
	const [produtosList, setProdutos] = useState<IProdutoCard[]>([]);
	const [categoriaSelecionada, setCategoriaSelecionada] = useState<ICategoria | undefined>(undefined);

	let imagemCategoria = uso
		? categorias_data.find((categoriaItem) => categoriaItem.uso == uso)
		: categorias_data.find((categoriaItem) =>
			categoriaItem.aliases.includes(categoria || "")
		);

	const theme = createTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	const size = matches ? "mobile" : "desktop";

	const { i18n } = useTranslation();

	const initialFilterBuilder = () => {
		const dados = filters;
		if (!("forma" in dados)) {
			Object.assign(dados, {
				codigo: "",
				forma: "",
				altura: "",
				largura: "",
				cor: "",
				Uso: "",
				folhas: "",
				familia: "",
			});
		}
		return dados;
	};

	useEffect(() => {
		const filters = Object.fromEntries(searchParams);

		let coresDB: string[] = [];

		let locale = i18n.language;

		sProduto
			.listProduto(locale, filters, categoria)
			.then((resp) => {
				setProdutos(resp.data.produtos);
				setCategoria(categoria || '')
				setCategoriaSelecionada(resp.data.selecionada);
				console.log("cores DB", resp.data.cores);
				coresDB = resp.data.cores;
				setFilteredCores(cores.filter((x) => coresDB.includes(x.value)));
			})
			.catch((err) => {
				console.error(err);
			});

	
		setUso(searchParams.get('Uso') || '')
		setDadosFiltro(filters)

	}, [i18n.language, searchParams, categoria])

	const [dadosFiltro, setDadosFiltro] = useState<{ [key: string]: string }>(
		initialFilterBuilder()
	);

	const handleFiltroChange = (e: any) => {
		setDadosFiltro({ ...dadosFiltro, [e.target.name]: e.target.value });
	};

	const clearFiltroValue = (filter: string) => {
		setDadosFiltro({ ...dadosFiltro, [filter]: "" });
	};

	// useEffect(() => {
	// 	setIsLoading(transition.state !== "idle");
	// }, [transition]);

	// useEffect(() => {
	// 	setProdutos(produtos);
	// }, [produtos]);

	useEffect(() => {
		window.scroll({
			// @ts-ignore
			// eslint-disable-next-line no-restricted-globals
			top: top,
			left: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<>
			<CategoriaHeaderBase
				image={imagemCategoria?.image[size][i18n.language] ?? ""}
				position={imagemCategoria?.position ?? ""}
			/>

			<div className="page-content">
				<h1>{categoriaSelecionada?.nome}</h1>

				{categoriaSelecionada?.alias !== "imprslpslp" ? (
					<ProdutoFilterPimaco
						dados={dadosFiltro}
						handleChange={handleFiltroChange}
						categoria={categoriaState}
						clearFiltroValue={clearFiltroValue}
						cores={filteredCores}
					>
						<>
							{uso && <input type="hidden" value={uso} name="Uso" />}
							<input type="hidden" value={categoria} name="Categoria" />
						</>
					</ProdutoFilterPimaco>
				) : (
					""
				)}

				{produtosList && <ProdutoList produtos={produtosList} />}
			</div>
			<ConhecaAssistenteBuscaPimaco />
		</>
	);
}

export default Categoria;
