import { IconButton } from "@mui/material";
import Text from "./Text";
import DirectMail from "./DirectMail";
import Numeration from "./Numeration";
import QRBarcode from "./qrbarcode";
import Images from "./Images";
import Shapes from "./Shapes";
import { createElement, useState } from "react";
import { ArrowLeftSquare } from "react-bootstrap-icons";

import { Accordion } from 'react-accessible-accordion'
import PropertyBar from "../propertyBar";
import { useBoardContext } from "@beontag/tageditor";

function ToolsBar() {

	const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement | Element>(document.getElementsByClassName('canvas-container')[0] || createElement('div'));
	const [hidden, setHidden] = useState<boolean>(false);

	return (
		<div className={`toolbar-container ${hidden ? 'hidden' : ''}`}>
			<div className='toolbar-accordion-container'>

				<Accordion
					allowZeroExpanded={true}
					preExpanded={["shapes"]}
				>
					<Shapes setMenuAnchorEl={setMenuAnchorEl} />
					<Text setMenuAnchorEl={setMenuAnchorEl} />
					<Images setMenuAnchorEl={setMenuAnchorEl} />
					<DirectMail setMenuAnchorEl={setMenuAnchorEl} />
					<Numeration setMenuAnchorEl={setMenuAnchorEl} />
					<QRBarcode setMenuAnchorEl={setMenuAnchorEl} />

				</Accordion>

				{/* @ts-ignore */}
				<PropertyBar handleOpen={menuAnchorEl} />

			</div>
			<IconButton onClick={() => setHidden(!hidden)}>
				<ArrowLeftSquare size={22} />
			</IconButton>
		</div>
	);
}


export default ToolsBar;