import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import ContentHeader from '../../../components/common/content-header';
import AccordionFaq from '../../../components/faq/accordion-faq';
import { duvidas } from '../../../components/faq/duvidas'

import { InputAdornment } from '@mui/material';

import { useTranslation } from 'react-i18next';
import FaqCategoryChooser from '../../../components/faq/faq-category-chooser';

import header_image from '../../../assets/duvidas_frequentes.webp'
import { SearchButtonIcon } from '../../../components/common/icons';

function Faq() {

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [questions, setQuestions] = useState(duvidas[0]);

  const handleSelectCategory = (categoria: number) => {
	  setSelectedCategory(categoria)
	  setQuestions(duvidas[categoria])
	  setSearchTerm('')
  }

  useEffect(() => {
	if (searchTerm) {
		let localQuestions = Object.assign({}, duvidas[selectedCategory]);
		let filtered = duvidas[selectedCategory].duvidas.filter((duvida) => {
			let lowercaseSearchTerm = searchTerm.toLowerCase()
			let tituloLowerCase = duvida.titulo.toLowerCase()
			let subtituloLowerCase = duvida.subtitulo.toLowerCase()

			return tituloLowerCase.includes(lowercaseSearchTerm) ||
				subtituloLowerCase.includes(lowercaseSearchTerm)
		})
		localQuestions.duvidas = filtered;
	  	setQuestions(localQuestions)

	} else {
		setQuestions(duvidas[selectedCategory])
	}
	
  },[searchTerm])

  const { t } = useTranslation('faq')

  useEffect(() => {
	window.scroll({
		// @ts-ignore
		// eslint-disable-next-line no-restricted-globals
		top: top,
		left: 0,
		behavior: 'smooth'	
	})
	}, [])

  return (
	<>
		<ContentHeader
			image={header_image}
		>
			<h1 className="content-header-faq">{t("Dúvidas Frequentes")}</h1>
		</ContentHeader>
	  <div className='page-content'>

		<TextField
			// @ts-ignore
			placeholder={t("Digite o termo que está buscando...")}
			value={searchTerm}
			onChange = {(e) => setSearchTerm(e.target.value)}
			variant="filled"
			className='search-field-faq'
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<SearchButtonIcon onClick={() => undefined}/>
						
					</InputAdornment>
					
				)
			}}
		/>

		</div>

		<FaqCategoryChooser
			categories={duvidas}
			handleSelectCategory={handleSelectCategory}
			selectedCategory={selectedCategory}
		/>

		<div className='page-content'>

		<h2 className="faq-section-header">{t(questions.tituloCategoria)}</h2>
		{questions.duvidas.map((item, index2) => {
			return (
				<AccordionFaq
					key={index2}
					titulo={t(item.titulo)}
					subtitulo={t(item.subtitulo)}
				/>
			)
		})}

	  </div>
	</>
  );
}
export default Faq;