import { Outlet } from "react-router-dom";
//import HeaderPimaco from "../../../components/header-footer/header";
import { ICategoria } from "../../../interfaces/produto/categoria";
//import FloatingButton from "../../../components/common/floatingButton";
//import FooterPimaco from "../../../components/header-footer/footer";
import { useEffect, useState } from "react";
import CarouselLandingPage from "../../../components/landing-page/carousel-landing-page";
import ConhecaLancamentos from "../../../components/landing-page/conheca-lancamentos/conheça-lancamentos-pimaco";
import DicasUsoProdutoPinterestPimaco from "../../../components/landing-page/dicas-uso-produto-pinterest-pimaco";
import EscolhaMelhorEtiquetaPimaco from "../../../components/landing-page/escolha-melhor-etiqueta-pimaco";
import ExperimenteAssistentePimaco from "../../../components/landing-page/experimente-assistente-pimaco";
import ExploreCategorias from "../../../components/landing-page/explore-categorias";
import VisiteDuvidasFrequentesPimaco from "../../../components/landing-page/visite-duvidas-frequentes-pimaco";
import ConhecaAssistentePimaco from '../../../components/landing-page/conheca-assistente-busca-pimaco';
//import sProduto from '../../../services/produtoService'

export default function HomePimaco() {

	return (
		<>
			<CarouselLandingPage />
			<ConhecaLancamentos />
			<ExploreCategorias />
			<DicasUsoProdutoPinterestPimaco />
			<ExperimenteAssistentePimaco />
			<EscolhaMelhorEtiquetaPimaco />
			<ConhecaAssistentePimaco />
			<VisiteDuvidasFrequentesPimaco />
		</>
	);
}