import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function ConhecaLancamentosCard(props: {invisible?: boolean, text?: string, btnLink?: string, name?: string, image?: string}) {

    const { t } = useTranslation('homepage')

    const ariaHidden = props.invisible ? 'true' : 'false'

    return (
        <div
            className={`conheca-lancamentos-card-container ${props.invisible ? 'invisible' : ''}`}
            aria-hidden={ariaHidden}
        >
            <div className="conheca-lancamentos-card-image-description-container">
                <img src={props.image} alt ={props.text}/>
                <div className="conheca-lancamentos-card-description-container">
                    <div className="conheca-lancamentos-card-description-text-container">
                        <p className="page-paragraph">{t(props.text || '')}</p>
                        <Link to={props.btnLink || '#'} className="btn-link">
                            <Button
                                className='btn btn-outlined-secondary'
                                variant="outlined"
                            >
                                {t("Saiba mais")}
                            </Button>
                        </Link>
                    </div>

                    
                </div>
            </div>

            <div className='conheca-lancamentos-card-info-container'>
                <h2>{t(props.name || '')}</h2>
            </div>

        </div>
    )
}