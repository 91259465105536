import LiteYouTubeEmbed from "react-lite-youtube-embed";
import TimelineSlider from "../../../../components/timeline/timelineSlider";

import header_image from '../../../../assets/conheca_nossa_historia.webp'
import ContentHeader from "../../../../components/common/content-header";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const videos = {
    "pt": "GQ-MFjNrt04",
    en: "GQ-MFjNrt04",
    es: "GQ-MFjNrt04"
}

export default function QuemSomos() {

    const { t, i18n } = useTranslation('institucional')

    function videoLoader() {
        const lang = i18n.language
    
        if (lang == "es")
            return videos["es"]
        if (lang == "en")
            return videos["en"]
        if (lang == "pt")
            return videos["pt"]
    }

    useEffect(() => {
		window.scroll({
            // @ts-ignore
			// eslint-disable-next-line no-restricted-globals
			top: top,
			left: 0,
            behavior: 'smooth'	
		})
	}, [])

    return (
        <>
            <ContentHeader
                image={header_image}
                imageClass="quem-somos-image"
            >
                <div className="content-header-quem-somos-container">
                    <h1 className="content-header-quem-somos">{t("Conheça nossa história")}</h1>
                </div>
            </ContentHeader>

            <div className="page-content empresa-content">
                <p className="page-paragraph">{t("Confira nossa linha do tempo e veja a evolução da marca!")}</p>
            </div>

            <TimelineSlider />

            <div className="page-content empresa-content">                

                <h2>{t("Nosso propósito")}</h2>
                <p className="page-paragraph">{t("Empoderar empresas, marcas e pessoas para que expressem sua identidade única.")}</p>

                <h2>{t("Nossa Visão")}</h2>
                <p className="page-paragraph">{t("Dar voz a trilhões de itens do cotidiano de forma sustentável.")}</p>

                <h2>{t("Nossos pilares")}</h2>
                <span className="pilares-empresa-content">
                    <p className="page-paragraph">{t("Sem Fronteiras")}</p>
                    <p className="page-paragraph">{t("Fazemos acontecer")}</p>
                    <p className="page-paragraph">{t("Juntos")}</p>
                </span>

                <h2>{t("Beontag")}</h2>

            </div>

            <div className="page-content"> 

                <LiteYouTubeEmbed
                    // id="0MlDq8Tovy0"
                    id={videoLoader() as string}
                    title="OUR CULTURE MANIFESTO"
                    thumbnail='https://img.youtube.com/vi/GQ-MFjNrt04/maxresdefault.jpg'
                />

            </div>

            <div className="page-content empresa-content"> 

                <p className="page-paragraph">{t("A holding Beontag teve início em 2011 com a fusão de duas grandes empresas com mais de 50 anos de atuação: a Colacril, indústria de autoadesivos com forte atuação de mercado na América Latina, e a RR Etiquetas, uma das maiores empresas brasileiras de conversão de etiquetas autoadesivas para varejo, indústria e serviços.")}</p>

                <p className="page-paragraph">{t("Nesse mesmo ano, a Beontag passou a integrar um fundo de Private Equity.")}</p>

                <p className="page-paragraph">{t("No ano seguinte, foi criada uma nova unidade de negócios do grupo responsável pela gestão de produtos com tecnologia RFID, de conversão e identificação por radiofrequência, hoje representada pela marca Beontag.")}</p>

                <p className="page-paragraph">{t("Como resultado, formou-se um grupo forte que contribui para o desenvolvimento de todo o setor ao aliar amplo portfólio de produtos a competência técnica e um modelo de gestão eficiente, contando também com espírito empreendedor e capacidade de investimento.")}</p>

            </div>

        </>
    )
}