import { useCallback, useEffect } from "react";
import {
  objectFactory,
  useBoardContext,
  useKeydownHandler,
} from "@beontag/tageditor";
import { Button } from "@mui/material";
import { Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const Remove = () => {
  const { board } = useBoardContext();
  const { t } = useTranslation("editor");

  const canRemoveActiveObjects = useCallback(() => {
    return (
      board?.activeObjects.length &&
      board.activeObjects.every((obj) => !objectFactory.Object.isEditing(obj))
    );
  }, [board?.activeObjects]);

  const removeActiveObjects = useCallback(() => {
    if (canRemoveActiveObjects()) {
      board?.remove(...board.activeObjects);
    }
  }, [board, canRemoveActiveObjects]);

  const keysPressCount = useKeydownHandler(["Delete"]);
  useEffect(() => {
    if (keysPressCount && canRemoveActiveObjects()) {
      board?.remove(...board.activeObjects);
    }
  }, [keysPressCount, canRemoveActiveObjects, board]);

  return (
    <Button
      className="btn"
      startIcon={<Trash size={16} />}
      onClick={removeActiveObjects}
      disabled={!board?.activeObjects.length}
    >
      {t("Excluir")}
    </Button>
  );
};

export default Remove;
