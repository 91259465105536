import Navbar from "./navbar";

import logo from '../../assets/common/logo.webp'
import { Button, IconButton, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import NavbarMobile from "./navbar-mobile";

import NavBarProdutos from "./navbar-produtos";
import { ICategoria } from "../../interfaces/produto/categoria";

import { ClickAwayListener } from '@mui/material'
import { CloseButtonIcon, MenuButtonIcon, SearchButtonIcon } from "../common/icons";
import LanguageSelector from "./language-selector";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function HeaderPimaco(props: { categorias: ICategoria[], setHasOpenMenu: Function }) {
    const theme = createTheme();

    const navigate = useNavigate()

    const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
    const [showMenuProdutos, setShowMenuProdutos] = useState<boolean>(false);
    const [showMenuInstitucional, setShowMenuInstitucional] = useState<boolean>(false);
    const [showMenuDownloads, setShowMenuDownloads] = useState<boolean>(false);

    const [searchText, setSearchText] = useState<string>('')

    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const [isExpandedMobile, setIsExpandedMobile] = useState<boolean>(false);

    const handleMenuProdutos = () => {
        setShowMenuProdutos(true)
        setShowSearchBar(false)
        setShowMenuInstitucional(false)
        setShowMenuDownloads(false)
    }

    const handleMenuInstitucional = () => {
        setShowMenuInstitucional(true)
        setShowMenuProdutos(false)
        setShowSearchBar(false)
        setShowMenuDownloads(false)
    }

    const handleMenuDownloads = () => {
        setShowMenuDownloads(true)
        setShowMenuInstitucional(false)
        setShowMenuProdutos(false)
        setShowSearchBar(false)
    }

    const handleSearchBar = () => {
        setShowSearchBar(!showSearchBar)
        setShowMenuDownloads(false)
        setShowMenuInstitucional(false)
        setShowMenuProdutos(false)
    }

    const handleCloseMenus = () => {
        setShowSearchBar(false)
        setShowMenuProdutos(false)
        setShowMenuInstitucional(false)
        setShowMenuDownloads(false)
    }

    useEffect(() => {
        if (showSearchBar || showMenuProdutos || showMenuInstitucional || showMenuDownloads) {
            props.setHasOpenMenu(true)
        } else {
            props.setHasOpenMenu(false)
        }
    }, [showSearchBar, showMenuProdutos, showMenuInstitucional, showMenuDownloads])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener("scroll", resizeHeaderOnScroll);
        }
    })

    const location = useLocation();

    useEffect(() => {
        handleCloseMenus()
    }, [location.pathname])

    const { t } = useTranslation('homepage');

    function resizeHeaderOnScroll() {
        if (typeof window !== 'undefined') {

            const distanceY = window.scrollY || document.documentElement.scrollTop,
                shrinkOn = 180,
                headerEl = document.getElementById("logo");

            if (headerEl) {
                if (distanceY > shrinkOn) {
                    headerEl.classList.add("small-logo");
                } else if (distanceY <= (shrinkOn - 60)) {
                    headerEl.classList.remove("small-logo");

                } else {
                }
            }
        }
    }

    return (

        <header className="header" id="header">
            <div className="header-top-half-container">
                <div className="logo-row-container">
                    <Link to="/" title="logo-pimaco">
                        <img src={logo} className="logo" title="logo" id="logo" />
                    </Link>

                    {matches && !isExpandedMobile ?
                        <div className="menu-icon-container">
                            <MenuButtonIcon onClick={() => setIsExpandedMobile(true)} />
                        </div>
                        : ''}

                    {matches && isExpandedMobile ?
                        <div className="menu-icon-container">
                            <CloseButtonIcon onClick={() => setIsExpandedMobile(false)} />
                        </div>
                        : ''}

                    {!matches ?    
                        <LanguageSelector />
                        : ''}
                </div>
            </div>

            
                    {matches && isExpandedMobile ?
                        <NavbarMobile categorias={props.categorias} />
                        : ''}

                    {!matches ?
                        <div className="navbar-container">

                
                            <Navbar
                                handleSearchBar={handleSearchBar}
                                handleMenuProdutos={handleMenuProdutos}
                                handleMenuDownloads={handleMenuDownloads}
                                handleMenuInstitucional={handleMenuInstitucional}
                                showMenuDownloads={showMenuDownloads}
                                showMenuInstitucional={showMenuInstitucional}
                                showMenuProdutos={showMenuProdutos}
                                handleCloseMenus={handleCloseMenus}
                                showSearchBar={showSearchBar}
                            />

                            <div className={`search-bar-container ${showSearchBar ? 'show' : ''}`}>
                                <div
                                    className="search-bar-form"
                                >
                                    <TextField
                                        variant="filled"
                                        placeholder="Digite o nome ou código do produto"
                                        size="small"
                                        fullWidth
                                        name="pesquisa"
                                        value={searchText}
                                        required
                                        onChange={(e) => setSearchText(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton type="submit">
                                                        <SearchButtonIcon />
                                                    </IconButton>

                                                </InputAdornment>

                                            )
                                        }}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                const params = new URLSearchParams({pesquisa: searchText}).toString()
                                                navigate({pathname: '/pesquisa', search: params })
                                                setSearchText('')
                                            }
                                        }}
                                    />
                                </div>
{/* @ts-ignore */}
                                <Link to="/ferramenta_busca" className="btn-link" title={t('BUSCA GUIADA')}>
                                    <Button
                                        className='btn btn-outlined-primary'
                                        variant='outlined'
                                    >
                                        {t('BUSCA GUIADA')}
                                    </Button>
                                </Link>

                                <CloseButtonIcon onClick={() => setShowSearchBar(false)} />

                            </div>
                        </div>
                        : ''}


            {showMenuProdutos ?
                <NavBarProdutos
                    categorias={props.categorias}
                    handleCloseMenus={handleCloseMenus}
                />
                : ''}

        </header>
    )
}