import { Button } from "@mui/material";
import { useBoardContext } from "@beontag/tageditor";
import { ZoomIn as ZoomInIcon } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export interface ZoomInProps {
  increaseStep: number;
}

const ZoomIn: React.FunctionComponent<ZoomInProps> = (props) => {
  const { boardZoom } = useBoardContext();

  const zoomIn = () =>
    boardZoom?.set(round(boardZoom?.value + props.increaseStep));

  const highestValue = () => boardZoom?.value === boardZoom?.max;

  const round = (value: number) => {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  };

  const { t } = useTranslation("editor");
  return (
    <Button
      className="btn"
      startIcon={<ZoomInIcon size={16} />}
      onClick={zoomIn}
      disabled={highestValue()}
    >
      {t("Zoom In")}
    </Button>
  );
};

export default ZoomIn;
