import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import { Button, Grid } from '@mui/material';

import background_image from '../../assets/desktop_downloads.webp';
import background_image_mobile from '../../assets/desktop_downloads_mobile.webp';

export default function FacaMaisComEtiquetas(props: { gabaritoUrl?: string }) {

    console.log(props)

    const theme = createTheme();
    const { t } = useTranslation("produtos");
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <div className="faca-mais-com-etiquetas-container">
            <div className="faca-mais-com-etiquetas-text-container">
                <h1>{t("Faça mais com nossas etiquetas")}</h1>
                <p className="page-paragraph">{t("Oferecemos ferramentas para personalização das suas etiquetas de acordo com o que você precisa e de forma eficaz! Experimente nosso software de impressão ou baixe o gabarito!")}</p>
                <Grid container spacing={1}>
                    {props.gabaritoUrl ?
                        <>
                            <Grid item xs={12} md={6}>
                                <Button
                                    variant="outlined"
                                    className="btn btn-outlined-primary"
                                    fullWidth
                                >
                                    {t("Software de impressão")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <a
                                    href={props.gabaritoUrl}
                                    className="btn-link"
                                    download
                                >
                                    <Button
                                        variant="outlined"
                                        className="btn btn-outlined-primary"
                                        fullWidth
                                    >
                                        {t("Gabarito")}
                                    </Button>
                                </a>
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    className="btn btn-outlined-primary"
                                    fullWidth
                                >
                                    {t("Software de impressão")}
                                </Button>
                            </Grid>
                        </>
                    }

                </Grid>

            </div>
            <img src={matches ? background_image : background_image_mobile} />
        </div>
    )
}