import { IStep } from "../../interfaces/assistenteStep"
import { stepBuilderCasa } from "./steps/steps_casa";

import { stepBuilderCorporativo } from './steps/steps_corporativo'

import { stepBuilderEscola } from "./steps/steps_escola";
import { stepBuilderProjPersonalizado } from "./steps/steps_proj_personalizados";

export function stepBuilder() {
    const rootStep:IStep = {
        stepId: 60,
        text: 'Casa',
        stepDescriptionText: '',
        selectedText: '',
        parent: undefined,
        nextStep: [],
        finalStep: false,
        value: '',
        name: ''
    };
    
    const step1Casa:IStep = {
        stepId: 61,
        text: 'Minha casa',
        stepDescriptionText: 'Você precisa da etiqueta para:',
        selectedText: 'etiqueta para minha casa',
        parent: rootStep,
        nextStep: [],
        finalStep: false,
        name: 'Finalidade',
        value: 'Casa'
    }
    
    const step1Negocio:IStep = {
        stepId: 62,
        text: 'Meu Negocio',
        stepDescriptionText: 'Você precisa da etiqueta para:',
        selectedText: 'etiqueta para meu negócio',
        parent: rootStep,
        nextStep: [],
        finalStep: false,
        name: 'Finalidade',
        value: 'MeuNegocioOuCorporativo'

    }
    
    const step1Escola:IStep = {
        stepId: 63,
        text: 'Escola',
        stepDescriptionText: 'Você precisa da etiqueta para:',
        selectedText: 'etiqueta para escola',
        parent: rootStep,
        nextStep: [],
        finalStep: false,
        name: 'Finalidade',
        value: 'Escola'

    }
    
    const step1Personalizados:IStep = {
        stepId: 64,
        text: 'Projetos personalizados',
        stepDescriptionText: 'Você precisa da etiqueta para:',
        selectedText: 'etiqueta para projetos personalizados',
        nextStep: [],
        finalStep: false,
        name: 'Finalidade',
        value: 'ProjetosPersonalizados'

    }

    const passosNegocio = stepBuilderCorporativo()
    passosNegocio.forEach((passo) => {
        step1Negocio.nextStep.push(passo)
    })

    const passosCasa = stepBuilderCasa()
    passosCasa.forEach((passo) => {
        step1Casa.nextStep.push(passo)
    })

    const passosEscola = stepBuilderEscola()
    passosEscola.forEach((passo) => {
        step1Escola.nextStep.push(passo)
    })
    
    const passosProjPersonalizados = stepBuilderProjPersonalizado()
    passosProjPersonalizados.forEach((passo) => {
        step1Personalizados.nextStep.push(passo)
    })

    rootStep.nextStep.push(step1Casa, step1Negocio, step1Escola, step1Personalizados);

    return rootStep;
}