import { AddImageFeature, AddImageFeatures } from "@beontag/tageditor";
import { Image } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCallback, useState } from "react";

import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { ArrowButtonIcon } from "../../../../../components/common/icons";
import { useTranslation } from "react-i18next";

export default function Images(props: { setMenuAnchorEl: Function }) {
  const [imageFeature, setImageFeature] = useState<AddImageFeatures>();

  const addImage = useCallback(
    (eMain: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const inputEl = document.createElement("input");
      inputEl.accept = "image/*";
      inputEl.type = "file";
      inputEl.hidden = true;
      inputEl.onchange = (e) => {
        const element = e.target as HTMLInputElement;
        if (element?.files?.length) {
          const reader = new FileReader();
          reader.onload = (ev) => {
            const content = ev?.target?.result as string;
            imageFeature?.add(content);
          };
          reader.readAsDataURL(element.files[0]);
        }
      };
      document.body.appendChild(inputEl);
      inputEl.click();
      inputEl.remove();
      props.setMenuAnchorEl(eMain.target);
    },
    [imageFeature]
  );

  const { t } = useTranslation("editor");
  return (
    <>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            {t("Imagem")}
            <ArrowButtonIcon onClick={() => undefined} />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <AddImageFeature featureUpdate={(f) => setImageFeature(f)}>
            <div className="editor-tb-button-container">
              <Button
                className="btn btn-outlined-primary"
                startIcon={<Image />}
                variant="outlined"
                component="label"
                // @ts-ignore
                onClick={(e) => addImage(e)}
              >
                {" "}
                {t("Imagem")}
              </Button>
            </div>
          </AddImageFeature>
        </AccordionItemPanel>
      </AccordionItem>
    </>
  );
}
