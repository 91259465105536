import { useRef } from "react";
import Slider from "react-slick";
import NextPrevButtonGroup from "../common/nextPrevButtonGroup";

import banner_11 from "../../assets/banners_home/MeuFeed_PT.webp";
import banner_12 from "../../assets/banners_home/Multicolor_PT.webp";
import banner_13 from "../../assets/banners_home/PimacoPrint_PT.webp";
import banner_15 from "../../assets/banners_home/PromoAdesivoCriativo_PT.webp"
import banner_16 from "../../assets/banners_home/Mosaico_Produtos.webp"

import banner_11_EN from "../../assets/banners_home/MeuFeed_EN.webp";
import banner_12_EN from "../../assets/banners_home/Multicolor_EN.webp";
import banner_13_EN from "../../assets/banners_home/PimacoPrint_EN.webp";

import banner_11_ES from "../../assets/banners_home/MeuFeed_ES.webp";
import banner_12_ES from "../../assets/banners_home/Multicolor_ES.webp";
import banner_13_ES from "../../assets/banners_home/PimacoPrint_ES.webp";

import banner_11_mobile from "../../assets/banners_home/mobile/MeuFeed-mobile_PT.webp";
import banner_12_mobile from "../../assets/banners_home/mobile/Multicolor-mobile_PT.webp";
import banner_13_mobile from "../../assets/banners_home/mobile/PimacoPrint-mobile_PT.webp";
import banner_15_mobile from "../../assets/banners_home/mobile/PromoAdesivoCriativo-mobile_PT.webp"
import banner_16_mobile from "../../assets/banners_home/mobile/Mosaico_Produtos_Mobile.webp"

import banner_11_mobile_EN from "../../assets/banners_home/mobile/MeuFeed-mobile_EN.webp";
import banner_12_mobile_EN from "../../assets/banners_home/mobile/Multicolor-mobile_EN.webp";
import banner_13_mobile_EN from "../../assets/banners_home/mobile/PimacoPrint-mobile_EN.webp";

import banner_11_mobile_ES from "../../assets/banners_home/mobile/MeuFeed-mobile_ES.webp";
import banner_12_mobile_ES from "../../assets/banners_home/mobile/Multicolor-mobile_ES.webp";
import banner_13_mobile_ES from "../../assets/banners_home/mobile/PimacoPrint-mobile_ES.webp";

import { useTranslation } from "react-i18next";

import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

interface IDevice {
	desktop: { [key: string]: string };
	mobile: { [key: string]: string };
}

interface IBannerImage {
	[key: string]: IDevice;
}

const banners: IBannerImage = {

	Mosaico: {
		desktop: {
			pt: banner_16,
			en: banner_16,
			es: banner_16
		},
		mobile: {
			pt: banner_16_mobile,
			en: banner_16_mobile,
			es: banner_16_mobile
		},
	},

	MultiColor: {
		desktop: {
			pt: banner_12,
			en: banner_12_EN,
			es: banner_12_ES,
		},
		mobile: {
			pt: banner_12_mobile,
			en: banner_12_mobile_EN,
			es: banner_12_mobile_ES,
		},
	},

	PimacoPrint: {
		desktop: {
			pt: banner_13,
			en: banner_13_EN,
			es: banner_13_ES,
		},
		mobile: {
			pt: banner_13_mobile,
			en: banner_13_mobile_EN,
			es: banner_13_mobile_ES,
		},
	}
	// PromoAdesivoRecreativo: {
	// 	desktop: {
	// 		pt: banner_15
	// 	},
	// 	mobile: {
	// 		pt: banner_15_mobile
	// 	}
	// }
};

export default function CarouselLandingPage() {
	const customSlider = useRef();

	const { i18n } = useTranslation();
	const theme = createTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	const size = matches ? "mobile" : "desktop";

	const settings = {
		className: "carousel-landing-slider",
		cssEase: "linear",
		infinite: true,
		slidesToShow: 1,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: true,
		speed: 500,
		dots: false,
	};

	const goToNext = () => {
		// @ts-ignore
		customSlider.current.slickNext();
	};

	const goToPrev = () => {
		// @ts-ignore
		customSlider.current.slickPrev();
	};

	return (
		<section className="carousel-landing-slider-container">
			<Slider
				{...settings}
				// @ts-ignore
				ref={(slider) => (customSlider.current = slider)}
			>

				<div className="carousel-landing-slide-item-container">
					<img
						className="carousel-landing-slide-image center"
						src={banners["Mosaico"][size][i18n.language] || banner_16}
						id="home_banner11_image"
						alt="Mosaico de produtos Pimaco"
						loading="eager"
						fetchpriority="high"
						rel="preload"
					/>
				</div>

				<a
					href="https://print.pimaco.com.br/loja/home/"
					className="btn-link"
					title="Pimaco Print"
				>
					<div className="carousel-landing-slide-item-container">
						<img
							className="carousel-landing-slide-image center"
							src={banners["PimacoPrint"][size][i18n.language] || banner_13}
							id="home_banner13_image"
							alt="Pimaco Print"
							loading="lazy"
						/>
					</div>
				</a>
				<Link
					to="/pesquisa?destaque=MultiColor"
					className="btn-link"
					title="Etiquetas Multicolor"
				>
					<div className="carousel-landing-slide-item-container">
						<img
							className="carousel-landing-slide-image center"
							src={banners["MultiColor"][size][i18n.language] || banner_12}
							id="home_banner12_image"
							alt="Etiquetas Multicolor Pimaco"
							loading="lazy"
						/>
					</div>
				</Link>














			</Slider>

			<div className="carousel-landing-button-container">
				<NextPrevButtonGroup goToNext={goToNext} goToPrev={goToPrev} />
			</div>
		</section>
	);
}
