import { useBoardContext } from "@beontag/tageditor";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Grid3x3 } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const GridLines = () => {
  const { board } = useBoardContext();
  const [visible, setVisible] = useState(true);
  const { t } = useTranslation("editor");
  useEffect(() => {
    if (board) {
      const workspace = board.getWorkspace();
      const gridlines = workspace.getChild("gridlines");
      setVisible(gridlines.visible === true);
    }
  }, [board]);

  const toggleVisible = () => {
    if (board) {
      const workspace = board.getWorkspace();
      const gridlines = workspace.getChild("gridlines");
      const newValue = !gridlines.visible;
      gridlines.set({ visible: newValue });
      board.requestRenderAll();
      setVisible(newValue);
    }
  };

  const buttonProps = visible
    ? {
        icon: <Grid3x3 size={16} />,
        text: t("Ocultar Grade"),
      }
    : {
        icon: <Grid3x3 size={16} />,
        text: t("Mostrar Grade"),
      };

  return (
    <>
      <Button
        className="btn"
        startIcon={buttonProps.icon}
        onClick={toggleVisible}
      >
        {buttonProps.text}
      </Button>
    </>
  );
};

export default GridLines;
