import { useCallback, useEffect, useState } from "react";
import { MirrorFeature, MirrorFeatures, objectFactory, useBoardContext, useKeysCombinationHandler } from "@beontag/tageditor";
import { useClipboardContext } from "./ClipboardContext";
import { Button } from "@mui/material";
import { Clipboard2Fill, SymmetryVertical } from "react-bootstrap-icons";
import MirrorHorizontal from "./MirrorHorizontal";
import MirrorVertical from "./MirrorVertical";

const Mirror: React.FunctionComponent = (props) => {

    const { board } = useBoardContext();
    const [mirrorFeature, setMirrorFeature] = useState<MirrorFeatures>();

    return (
        <MirrorFeature featureUpdate={(f) => setMirrorFeature(f)}>
            <MirrorHorizontal mirrorFeature={mirrorFeature} />
            <MirrorVertical mirrorFeature={mirrorFeature} />
        </MirrorFeature>
    );
};

export default Mirror;
