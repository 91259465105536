import { useCallback, useState } from "react";
import { useBoardContext } from "@beontag/tageditor";
import {
  Button,
  Dialog,
  DialogTitle,
  ListItemIcon,
  Menu,
  MenuItem,
  IconButton,
  DialogContent,
  TextField,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Save as SaveIcon,
  FileEarmarkArrowDown,
  CloudArrowUp,
  Send,
  Tags,
} from "react-bootstrap-icons";

import sEtiquetasUsuario from "../../../../../services/etiquetasUsuarioService";
import { useEditorContext } from "../../../../../context/editorContext/EditorContext";
import { useMessage } from "../../../../../context/alertContext/context";
import ModalSaveNewTemplate from "../../templateSelection/modalSaveNewTemplate";
import { useUserContext } from "../../../../../context/userContext/userContext";
import { Roles } from "../../../../../interfaces/editor/rolesEnum";
import { useTranslation } from "react-i18next";

const Save = () => {
  const { boardFile } = useBoardContext();
  const {
    produtoSelecionado,
    etiquetaUsuario,
    setEtiquetaUsuario,
    fileExtension,
  } = useEditorContext();
  const { showAlertApp } = useMessage();
  const { isUserInRole } = useUserContext();

  const [openModalName, setOpenModal] = useState<boolean>(false);
  const [openModalSaveTemplate, setOpenModalSaveTemplate] =
    useState<boolean>(false);
  const [newDescriptionName, setNewDescriptionName] = useState<string>("");

  const userCanCreateTemplate: boolean = isUserInRole(Roles.TemplateAdicionar);

  const { t } = useTranslation("editor");

  const download = useCallback(() => {
    if (boardFile) {
      const name = produtoSelecionado?.codigo || "etiqueta-pimaco";
      const content = boardFile.export({
        tagData: {
          sku: produtoSelecionado?.sku,
        },
      });

      const anchorEl = document.createElement("a");
      anchorEl.href = `data:text/json;charset=utf-8,${encodeURIComponent(
        content
      )}`;
      anchorEl.download = `${name}${fileExtension}`;
      document.body.appendChild(anchorEl); // required for firefox
      anchorEl.click();
      anchorEl.remove();
    }
  }, [boardFile]);

  const saveToCloud = () => {
    if (boardFile) {
      const content = boardFile.export();

      if (etiquetaUsuario?.idUsuarioEtiqueta) {
        let updatedEtiqueta = etiquetaUsuario;
        updatedEtiqueta.dadosEtiqueta = content;
        setEtiquetaUsuario(updatedEtiqueta);
        showAlertApp(t("Salvando...."), "info");
        sEtiquetasUsuario
          .updateEtiqueta(updatedEtiqueta)
          .then((resp) => {
            showAlertApp(t("Salvo com sucesso!"), "success", 5000);
          })
          .catch((err) => {
            showAlertApp(
              t(
                "Houve um problema ao salvar a etiqueta, salve a sua edição no seu computador para não perder seu progresso"
              ),
              "error",
              5000
            );
          });
        return;
      } else {
        setOpenModal(true);
      }
    }
  };

  const saveNew = () => {
    if (boardFile) {
      showAlertApp(t("Salvando..."), "info");

      if (etiquetaUsuario?.idUsuarioEtiqueta) {
        saveToCloud();
        return;
      }

      const dadosEtiqueta = boardFile.export();

      sEtiquetasUsuario
        .saveEtiqueta({
          IdProduto: produtoSelecionado?.idProduto.toString() || "",
          DadosEtiqueta: dadosEtiqueta,
          Descricao: newDescriptionName,
        })
        .then((resp) => {
          setEtiquetaUsuario({
            idProduto: produtoSelecionado?.idProduto || 0,
            descricao: newDescriptionName,
            dadosEtiqueta: dadosEtiqueta,
            idUsuarioEtiqueta: resp.data,
          });
          showAlertApp(t("Salvo com sucesso!"), "success", 5000);
          setOpenModal(false);
        })
        .catch((err) => {
          showAlertApp(
            t(
              "Houve um problema ao salvar a etiqueta, salve a sua edição no seu computador para não perder seu progresso"
            ),
            "error",
            5000
          );
        });
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        className="btn btn-header-editor"
        startIcon={<SaveIcon size={28} />}
        sx={{ flexDirection: "column" }}
        onClick={handleClick}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        {t("Salvar")}
      </Button>
      <Menu
        elevation={1}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={download}>
          <ListItemIcon>
            <FileEarmarkArrowDown size="20" />
          </ListItemIcon>
          {t("Salvar no computador")}
        </MenuItem>
        <MenuItem onClick={() => saveToCloud()}>
          <ListItemIcon>
            <CloudArrowUp size="20" />
          </ListItemIcon>
          {t("Salvar na nuvem")}
        </MenuItem>
        {userCanCreateTemplate && (
          <MenuItem onClick={() => setOpenModalSaveTemplate(true)}>
            <ListItemIcon>
              <Tags size="20" />
            </ListItemIcon>
            {t("Salvar como template")}
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={openModalName}
        onClose={() => setOpenModal(false)}
        scroll="paper"
        maxWidth={"md"}
      >
        <DialogTitle>
          <IconButton onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <h3>{t("Minhas etiquetas")}</h3>

          <TextField
            fullWidth
            name="newDescriptionName"
            type="text"
            value={newDescriptionName}
            onChange={(e) => setNewDescriptionName(e.target.value)}
            onKeyDown={(e) => (e.key == "Enter" ? saveNew() : undefined)}
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" disablePointerEvents>
                  <span className="form-label">{t("Nome:")}</span>
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => saveNew()}>
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
      </Dialog>

      <ModalSaveNewTemplate
        open={openModalSaveTemplate}
        handleCloseModal={() => setOpenModalSaveTemplate(false)}
        userCanCreateTemplate={userCanCreateTemplate}
      />
    </>
  );
};

export default Save;
