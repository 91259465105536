import { Box } from "@mui/material";
import { Canvas, useBoardContext } from '@beontag/tageditor';
import ToolsBar from "./toolsBar";
import ActionBar from "./actionBar";
import EtiquetaPaginator from "./tools/etiquetaPaginator";
import { useEffect } from "react";
import EtiquetaEditorPages from "./tools/etiquetaEditorPages";
import GlobalHandlers from "./globalHandlers";
import ZoomStatus from "./tools/zoomStatus";
import { useEditorContext } from "../../../../context/editorContext/EditorContext";
import DadosEtiqueta from "./tools/DadosEtiqueta";

function TagEditor(props: { setOpenModalMinhasEtiquetas: Function, setActiveStep: Function }) {

    const { etiquetaData } = useEditorContext();
    const { boardFile } = useBoardContext();

    useEffect(() => {
        if (etiquetaData) {
            boardFile?.importTemplate(etiquetaData).catch(error => {
                alert(error);
            });
        }
        
    }, [boardFile])

    useEffect(() => {
        if (etiquetaData) {
            boardFile?.import(etiquetaData).catch(error => {
                alert(error);
            })
        }
    }, [etiquetaData])

    // Adicionamos um listener para capturar o fechamento da janela e evitar que o usuário perca o seu progresso
    useEffect(() => {
        const handleTabClose = (event: any) => {
            event.preventDefault();

            return (event.returnValue = '')
        }

        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose)
        }
    }, [])

	return (
        <>
            <GlobalHandlers />

            <ActionBar
                setOpenModalMinhasEtiquetas={props.setOpenModalMinhasEtiquetas}
                setActiveStep={props.setActiveStep}
            />

            <Box className='editor-container'>
                <ToolsBar />
                
                <EtiquetaPaginator />

                <Canvas />

                <EtiquetaEditorPages />

                <DadosEtiqueta />
                
                <ZoomStatus />
                
            </Box>
        </>
    );
}


export default TagEditor;