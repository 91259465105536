import React, { useEffect, useState } from 'react';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import InputMask from "react-input-mask";
import { IFormTrabalheConosco } from '../../interfaces/form/form-trabalhe-conosco';
import { useDropzone } from 'react-dropzone';
import EnviarCurriculo from './trab_con_enviar_curriculo';
import FormSwitcherButtons from './form-switcher-buttons';
import contatoService from '../../services/contatoService';
import ContentHeader from '../common/content-header';
import { useTranslation } from 'react-i18next';
import { useConfigContext } from '../../context/configContext';
import { useMessage } from '../../context/alertContext/context';

function TrabalheConosco(props: {modifyActiveComponent: Function, activeComponent: string, image:string, setOldComponent: Function }) {

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const config = useConfigContext();
	const { showAlertApp } = useMessage();

	const [fileName, setFileName] = useState<string>("");
	const [validEmail, setValidEmail] = useState<boolean>(true);
	const [validTelefone, setValidTelefone] = useState<boolean>(true);

	const arquivoEstrutura = useDropzone({
		maxFiles: 1,
		multiple: false,
		accept: ".docx, .doc, .pdf, .odf"
	});

	const [form, setForm] = useState<IFormTrabalheConosco>({
		nome: '',
		email: '',
		telefone: '',
		endereco: '',
		numero: '',
		cep: '',
		estado: '',
		cidade: '',
		curriculo: undefined,
		checkboxTermos: false,
		checkboxContatoTelefone: false,
		checkboxContatoEmail: false,
		linkedin: '',
	})

	const [errors, setErrors] = useState({
		nome: false,
		endereco: false,
		numero: false,
		estado: false,
		cidade: false,
		cep: false,
		curriculoLinkedIn: false,
	})


	useEffect(() => {
		if (arquivoEstrutura.acceptedFiles.length > 0) {
			setFileName(arquivoEstrutura.acceptedFiles[0].name)
			setForm({...form, curriculo: arquivoEstrutura.acceptedFiles[0]})
		}
	}, [arquivoEstrutura.acceptedFiles[0]])


	function validation(fieldName: string, value: string) {

		if (fieldName == 'email') {

			//Responsável por verificar se o e-mail é válido
			var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

			if (value.match(validRegex)) {
				setValidEmail(true)
				return true;

			} else {
				setValidEmail(false)
				return false;
			}

		} else if (fieldName == 'telefone') {

			//Responsável por verificar se o telefone é válido
			var validRegex = /(?:\(([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;

			if (value.match(validRegex)) {
				setValidTelefone(true)
				return true;

			} else {
				setValidTelefone(false)
				return false;
			}
		}
	}

	function validateFields() {
		let errorExists = false;

		setErrors({
			nome: false,
			endereco: false,
			numero: false,
			estado: false,
			cidade: false,
			cep: false,
			curriculoLinkedIn: false,
		})

		if (!form.nome) {
			setErrors(prevErrors => ({ ...prevErrors, nome: true }))
			errorExists = true;
		}

		if (!form.endereco) {
			setErrors(prevErrors => ({ ...prevErrors, endereco: true }))
			errorExists = true;
		}

		if (!form.numero) {
			setErrors(prevErrors => ({ ...prevErrors, numero: true }))
			errorExists = true;
		}

		if (!form.cep) {
			setErrors(prevErrors => ({ ...prevErrors, cep: true }))
			errorExists = true;
		}

		if (!form.estado) {
			setErrors(prevErrors => ({ ...prevErrors, estado: true }))
			errorExists = true;
		}

		if (!form.cidade) {
			setErrors(prevErrors => ({ ...prevErrors, cidade: true }))
			errorExists = true;
		}

		if (form.curriculo == undefined && !form.linkedin) {
			setErrors(prevErrors => ({ ...prevErrors, curriculoLinkedIn: true }))
			errorExists = true;
		}

		return errorExists;
	}


	const handleInputChange = (e: any) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};


	const handleCheckboxChange = (e: any) => {
		setForm({ ...form, [e.target.name]: e.target.checked });
	};

	async function eventoSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		let validationError = validateFields()
		if (!validationError) {		
			setIsSubmitting(true)
			contatoService.submitTrabalheConosco(form, config.apiUrl).then((resp) => {
				props.setOldComponent("trabalhe-conosco")
				setIsSubmitting(false)
				props.modifyActiveComponent('submitted')
			}).catch((err) => {
				setIsSubmitting(false)
				showAlertApp("Ocorreu um erro, tente novamente mais tarde", 'error', 5000)
			})
		}	
	}

	const { t } = useTranslation('contato')

	return (
		<>
		
		<ContentHeader
			image={props.image}
		>
			<h1 className='content-header-trabalhe-conosco'>{t("Trabalhe conosco")}</h1>
		</ContentHeader>

		<div className='form-contato-container'>
			<div className='page-content'>


			{/*Parágrafo principal*/}
			<p className='form-contato-initial-paragraph'>{t("Para dúvidas e sugestões, entre em contato conosco preenchendo o formulário abaixo!")}</p>
			</div>

			<FormSwitcherButtons
				modifyActiveComponent={props.modifyActiveComponent}
				activeComponent={props.activeComponent}
			/>

			<div className='form-container'>

				{/* Início do formulário */}
				<form onSubmit={(e) => eventoSubmit(e)}>

					{/*Início dos inputs do formulário*/}
					<Grid container spacing={2}>

						{/*Nome*/}
						<Grid item xs={12}>
							<h1 className="h1-form-title">{t("Dados pessoais")}</h1>
							<TextField
								fullWidth
								id="nome"
								type="text"
								name="nome"
								aria-label="Nome:"
								variant="filled"
								onChange={handleInputChange} 
								error={errors.nome}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start" disablePointerEvents>
											<span className='form-label'>
												{t("Nome: ")}
											</span>
	
										</InputAdornment>
									),
								}}
								/>
						</Grid>

						{/*E-mail e telefone*/}
						<Grid item container spacing={1}>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									id="email"
									type="email"
									name="email"
									aria-label="E-mail:"
									variant="filled"
									onChange={handleInputChange}
									error={!validEmail}
									onBlur={(e) => validation("email", form.email)}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start" disablePointerEvents>
												<span className='form-label'>
													{t("E-mail: ")}
												</span>

											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<InputMask
									mask={"(99) 99999-9999"}
									onBlur={(e) => validation("telefone", form.telefone)}
									onChange={handleInputChange}
								>
									{/* @ts-ignore */}
									{() => (
										<TextField
											fullWidth
											id="telefone"
											type="tel"
											name="telefone"
											aria-label="Telefone:"
											variant="filled"
											error={!validTelefone}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" disablePointerEvents>
														<span className='form-label'>
															{t("Telefone: ")}
														</span>

													</InputAdornment>
												),
											}}
										/>
									)}

								</InputMask>
							</Grid>
						</Grid>

						{/*Endereço e número --> Grid*/}
						<Grid item container spacing={1}>
							<Grid item xs={12} sm={9}>
								<TextField
									fullWidth
									id="endereco"
									type="text"
									name="endereco"
									aria-label="Endereço:"
									variant="filled"
									onChange={handleInputChange}
									error={errors.endereco}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start" disablePointerEvents>
												<span className='form-label'>
													{t("Endereço: ")}
												</span>

											</InputAdornment>
										),
									}}
								/>
							</Grid>

							<Grid item xs={12} sm={3}>
								<TextField
									fullWidth
									id="numero"
									type="number"
									name="numero"
									aria-label="Número:"
									variant="filled"
									onChange={handleInputChange}
									error={errors.numero}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start" disablePointerEvents>
												<span className='form-label'>
													{t("Número: ")}
												</span>

											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>

						{/*CEP, Estado e cidade */}
						<Grid item container spacing={1}>
							<Grid item xs={12} sm={4}>
								<InputMask
									mask={"99999-999"}
									//onBlur={(e) => validation("telefone", form.telefone)}
									onChange={handleInputChange}
								>
									{/* @ts-ignore */}
									{() => (
								
										<TextField
											fullWidth
											id="cep"
											type="text"
											name="cep"
											aria-label="CEP:"
											variant="filled"
											onChange={handleInputChange}
											error={errors.cep}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" disablePointerEvents>
														<span className='form-label'>
															{t("CEP: ")}
														</span>

													</InputAdornment>
												),
											}}
										/>
									)}
								</InputMask>
							</Grid>
							<Grid item xs={12} sm={2}>
								<TextField
									fullWidth
									id="estado"
									type="text"
									name="estado"
									aria-label="Estado:"
									variant="filled"
									onChange={handleInputChange}
									error={errors.estado}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start" disablePointerEvents>
												<span className='form-label'>
													{t("Estado: ")}
												</span>

											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									id="cidade"
									type="text"
									name="cidade"
									aria-label="Cidade:"
									variant="filled"
									onChange={handleInputChange}
									error={errors.cidade}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start" disablePointerEvents>
												<span className='form-label'>
													{t("Cidade: ")}
												</span>

											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>

						<EnviarCurriculo
							arquivoEstrutura={arquivoEstrutura}
							fileName={fileName}
							errors={errors}
							handleInputChange={handleInputChange}
							handleCheckboxChange={handleCheckboxChange}
						/>
					</Grid>


					<Button
						variant="contained"
						className='form-contato-submit-button btn btn-contained-secondary'
						type='submit'
						disableElevation
						disabled={isSubmitting}
					>
						{t("Enviar")}
					</Button>

					{/* Final do formulário geral */}
				</form>

			</div>
		</div>
		
		</>
	);
}
export default TrabalheConosco;