import {
  BarCodes,
  useBoardContext,
  usePropertyContext,
} from "@beontag/tageditor";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { fabric } from "fabric";

import { ArrowsMove, InfoCircle } from "react-bootstrap-icons";

import CloseIcon from "@mui/icons-material/Close";
import { XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const BarCode: React.FunctionComponent = () => {
  const { properties, setProperty } = usePropertyContext<fabric.BarCode>();
  const barCodes = BarCodes.getList();

  const handleOnChange = (key: string, rawValue: string) => {
    setProperty({ [key]: rawValue });
  };

  const handleOnChangeBooleanProp = (key: string, rawValue: boolean) => {
    setProperty({ [key]: rawValue });
  };

  const { board } = useBoardContext();

  const closeWindow = () => {
    board?.setActiveObjects();
  };

  const { t } = useTranslation("editor");
  return (
    <>
      <div className="property-bar-title">
        {t("Propriedades do objeto")}
        <ArrowsMove size={20} className="icon left" />
        <IconButton
          onClick={() => closeWindow()}
          className="icon right not-draggable"
        >
          <XLg size={20} color="white" />
        </IconButton>
      </div>
      <Grid container className="tb-main-grid">
        <Grid item md={3}>
          <div className="property-bar-text-itens extra-bottom-margin">
            <label htmlFor="escala" className="cursor-pointer">
              {t("Escala")}
            </label>
            <TextField
              id="escala"
              type="number"
              value={properties.scaleY || 1}
              InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
              onChange={(e) => {
                const value = e.currentTarget.value;
                handleOnChange("scaleX", value);
                handleOnChange("scaleY", value);
              }}
              variant="filled"
            ></TextField>
          </div>
          <div className="property-bar-text-itens extra-bottom-margin">
            <label htmlFor="opacidade" className="cursor-pointer">
              {t("Opacidade")}
            </label>
            <TextField
              id="opacidade"
              type="number"
              value={properties.opacity || 1}
              InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
              onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
              variant="filled"
            ></TextField>
          </div>
        </Grid>

        <Grid item md={3} className="double-width-grid-item">
          <div className="property-bar-text-itens barcode-valor-input">
            <span>{t("Endereço do codigo")}</span>
            <TextField
              type="text"
              onChange={(e) =>
                handleOnChange("barCodeText", e.currentTarget.value)
              }
              value={properties.barCodeText ?? ""}
              className="not-draggable value-input"
              variant="filled"
              helperText={`Exemplo: ${
                barCodes.find(
                  (barCode) => barCode.value == properties["barCodeType"]
                )?.example
              }`}
            />
          </div>
          <div className="property-bar-text-itens barcode-valor-input">
            <span>{t("Modelo de código")}</span>

            <Select
              onChange={(e) => handleOnChange("barCodeType", e.target.value)}
              value={properties.barCodeType || "qrcode"}
              variant="filled"
              className="value-input"
            >
              {barCodes.map((barCode, index) => {
                return (
                  <MenuItem value={barCode.value} key={index}>
                    {barCode.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          {properties.error && (
            <FormHelperText error className="qr-bar-code-error-text">
              {t("Ocorreu um erro com esta etiqueta")}
              <Tooltip title={properties.error}>
                <InfoCircle />
              </Tooltip>
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={3}>
          <div className="property-bar-text-itens extra-bottom-margin">
            <FormGroup>
              <FormControlLabel
                className="editor-numeration-checkbox"
                control={
                  <Checkbox
                    value={Boolean(properties.showSubtitle)}
                    name="checkbox-subtitle"
                    color="primary"
                    checked={Boolean(properties.showSubtitle)}
                    checkedIcon={<CloseIcon />}
                    onChange={(e) =>
                      handleOnChangeBooleanProp(
                        "showSubtitle",
                        e.currentTarget.checked
                      )
                    }
                    disabled={
                      !BarCodes.getList().find(
                        (x) => x.value === properties.barCodeType
                      )?.hasSubtitle ?? true
                    }
                  />
                }
                label="Legenda"
              />
            </FormGroup>
          </div>

          <div className="property-bar-text-itens extra-bottom-margin">
            <span>{t("Posição da legenda")}</span>
            <Select
              onChange={(e) => handleOnChange("subtitleAlign", e.target.value)}
              value={properties.subtitleAlign || "center"}
              variant="filled"
              className="barcode-legenda-select"
              disabled={Boolean(
                !properties.showSubtitle ||
                  (!BarCodes.getList().find(
                    (x) => x.value === properties.barCodeType
                  )?.hasSubtitle ??
                    true)
              )}
            >
              {[
                "left",
                "center",
                "right",
                "offleft",
                "offright",
                "justify",
              ].map((align, index) => {
                return (
                  <MenuItem value={align} key={index}>
                    {align}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default BarCode;
