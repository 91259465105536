import { useState } from "react";
import { ICategoria } from "../../interfaces/produto/categoria"

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function TitleHeader(props: { texto: string }) {
    return (
        <div className="navbar-produtos-filtros-header">{props.texto}</div>
    )
}

export default function NavBarProdutos(props: { categorias: ICategoria[], handleCloseMenus: Function }) {
    const theme = createTheme();

    const [selectedCategoria, setSelectedCategoria] = useState<ICategoria>();

    const { categorias } = props;

    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const categoriasExist = categorias.length != 0;

    const { t, i18n, ready } = useTranslation('common')
    
    return (
        <div className="navbar-produtos-container">
            { categorias ?
                <>
                    <div className="navbar-produtos-categorias-container">

                        <TitleHeader texto={t("Categorias")} />

                        {categoriasExist &&
                            <ul
                                className="navbar-produtos-categorias-list"
                            >
                                {categorias.map((categoria) => {
                                    return (
                                        <li
                                            className={`navbar-produtos-categorias-list-item ${selectedCategoria?.idCategoria == categoria.idCategoria ? 'active-item' : ''}`}
                                            key={categoria.idCategoria}
                                            onClick={() => { setSelectedCategoria(categoria); props.handleCloseMenus() }}
                                            onMouseOver={() => matches ? '' : setSelectedCategoria(categoria)}
                                        >
                                            <Link to={`/categoria/${categoria.alias}`}>
                                                {categoria.nome}
                                            </Link>

                                        </li>
                                    )
                                })}
                            </ul>
                        }
                    </div>

                    <div className="navbar-produtos-categorias-filtros-container">
                        {selectedCategoria && selectedCategoria.filtros && selectedCategoria.filtros.length != 0 ?

                            selectedCategoria.filtros.map((filtro, index) => {
                                return (

                                    <div
                                        className="navbar-produtos-filtros-list-container"
                                        key={index}
                                    >
                                        {filtro.nome != "undefined"
                                            ? <TitleHeader texto={t(filtro.nome)} />
                                            : <TitleHeader texto={selectedCategoria.nome} /> 
                                        }
                                        <ul
                                            className="navbar-produtos-filtros-list"
                                        >
                                            {filtro.items.map((filtroItem) => {
                                                return (
                                                    <li
                                                        className="navbar-produtos-filtros-list-item"
                                                        key={filtroItem.idFiltroItem}
                                                        onClick={() => props.handleCloseMenus()}
                                                    >
                                                        <Link to={`/categoria/${filtroItem.alias}`}>
                                                            {filtroItem.nome}
                                                        </Link>   
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                )
                            })
                            
                        : ''}
                    </div>
                </>
            : 
                <div className='menu-produtos-container menu-produtos-erro-container'>
                    <h1>
                        Tivemos um problema ao carregar os produtos
                    </h1>
                </div>
            }
        </div>
    )
}