import { useCallback, useEffect } from "react";
import {
  useBoardContext,
  objectFactory,
  useKeysCombinationHandler,
} from "@beontag/tageditor";
import { useClipboardContext } from "./ClipboardContext";
import { Button } from "@mui/material";
import { Clipboard } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const Copy = () => {
  const { board } = useBoardContext();
  const { setClipboard } = useClipboardContext();
  const { t } = useTranslation("editor");
  const copy = useCallback(() => {
    const activeObjects = board?.activeObjects;
    const canCopy =
      activeObjects?.length &&
      activeObjects.every((obj) => !objectFactory.Object.isEditing(obj));
    if (canCopy) {
      objectFactory.Object.clone(activeObjects, board?.activeSelection).then(
        (clones) => setClipboard(clones)
      );
    }
  }, [board?.activeObjects, board?.activeSelection, setClipboard]);

  const CtrlCPressCount = useKeysCombinationHandler(["Control", "C"]);
  useEffect(() => {
    if (CtrlCPressCount) {
      copy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CtrlCPressCount]);

  return (
    <Button
      className="btn"
      startIcon={<Clipboard size={16} />}
      onClick={copy}
      disabled={!board?.activeObjects.length}
    >
      {t("Copiar")}
    </Button>
  );
};

export default Copy;
