import api from "../api";

const listProduto = async function() {

    return api.get('instagram');
}

const instagramService = {
    listProduto,
}

export default instagramService;