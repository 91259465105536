import { useRef } from "react";

import ConhecaLancamentosCard from "./conheca-lancamentos-card";

import Slider from "react-slick";
import NextPrevButtonGroup from "../../../components/common/nextPrevButtonGroup";

import pima_note from "../../../assets/lancamentos/pima_note.webp";
import pima_film from "../../../assets/lancamentos/pima_film.webp";
import escolar from "../../../assets/lancamentos/escolar.webp";
import marcador_abas_pastel from "../../../assets/lancamentos/marcador_abas_pastel.webp";
import presentes from "../../../assets/lancamentos/presentes.webp";
import etiqueta_rolo from "../../../assets/lancamentos/etiqueta_rolo.webp";
import multiuso_pastel from "../../../assets/lancamentos/multiuso_pastel.webp";
import temperos_mantimentos from "../../../assets/lancamentos/temperos_mantimentos.webp";
import removivel from "../../../assets/lancamentos/removivel.webp";
import rolo from "../../../assets/lancamentos/rolo.webp";
import transparente from "../../../assets/lancamentos/Transparente.webp";
import kit_meu_feed from "../../../assets/lancamentos/kit_meu_feed.webp"

import { useTranslation } from "react-i18next";

export default function ConhecaLancamentos() {
  const { t } = useTranslation("homepage");

  const settings = {
    className: "conheca-lancamentos-slider",
    centerMode: true,
    infinite: false,
    slidesToShow: 3,
    arrows: false,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          initialSlide: 0,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          initialSlide: 0,
          slidesToShow: 1,
        },
      },
    ],
  };

  const customSlider = useRef();

  const goToNext = () => {
    // @ts-ignore
    customSlider.current.slickNext();
  };

  const goToPrev = () => {
    // @ts-ignore
    customSlider.current.slickPrev();
  };

  return (
    <div className="conheca-lançamentos-container">
      <div className="conheca-lancamentos-content-container">
        <h1 className="h1-section-title">{t("Conheça nossos lançamentos")}</h1>

        <Slider
          {...settings}
          // @ts-ignore
          ref={(slider) => (customSlider.current = slider)}
        >
          <ConhecaLancamentosCard
            name="O kit Meu Feed tem tudo o que você precisa para organizar o seu dia a dia com estilo."
            text="O  kit Meu Feed contém: 1 bloco adesivo 76x76mm - 50 folhas; 1 bloco adesivo 100x151mm - 50 folhas; 1 marcador de páginas - 20 folhas por cor e Cartela com 125 adesivos."
            btnLink="pesquisa?destaque=MeuFeed"
            image={kit_meu_feed}
          />
          <ConhecaLancamentosCard
            name="Etiqueta Removível. Cola e descola sem deixar resíduo"
            text="Etiqueta Removível em diversas cores, facilitando a identificação  de conteúdo e data"
            btnLink="categoria/etiqremcor"
            image={removivel}
          />
          <ConhecaLancamentosCard
            name="Etiquetas Transparente Cristal em diferentes formatos"
            text="Etiquetas Transparentes perfeitas para personalização de rótulos"
            btnLink="categoria/etiqtracor"
            image={transparente}
          />
          <ConhecaLancamentosCard
            name="Etiquetas multiuso em rolo com caixa dispenser"
            text="As principais cores das etiquetas multiuso, agora em rolo e caixa dispenser, com a qualidade pimaco que você coonhece!"
            btnLink="categoria/etiqredcor"
            image={rolo}
          />

          <ConhecaLancamentosCard
            name="Pima Note. Blocos adesivos nas cores neon, pastel e transparente"
            text="Pima Note, blocos adesivos perfeitos para anotar tudo o que quiser"
            btnLink="categoria/bloadebloc"
            image={pima_note}
          />
          <ConhecaLancamentosCard
            name="Pima Film. Filmes adesivos transparentes"
            text="Pima Film, proteja seus livros, cadernos e objetos com resistência e qualidade Pimaco que você já conhece"
            btnLink="categoria/filade"
            image={pima_film}
          />
          <ConhecaLancamentosCard
            name="Etiquetas escolares"
            text="Etiquetas escolares com designs divertidos, perfeitas para identificar os materiais"
            btnLink="categoria/etiq?Uso=Escolar"
            image={escolar}
          />
          <ConhecaLancamentosCard
            name="Marcador de abas pastel"
            text="Ideais para marcar paginas importantes com o charme das cores pastel"
            btnLink="produto/970842"
            image={marcador_abas_pastel}
          />
          <ConhecaLancamentosCard
            name="Etiquetas de presente"
            text="Identifique e dê um toque especial nos presentes"
            btnLink="categoria/etiqpredom"
            image={presentes}
          />
          <ConhecaLancamentosCard
            name="Etiquetas para potes de mantimentos e temperos"
            text="Deixe sua cozinha mais organizada com potes de mantimentos e temperos personalizados e decorados!"
            btnLink="/pesquisa?destaque=EtiquetasPotesMantimentosTemperos"
            image={temperos_mantimentos}
          />
          <ConhecaLancamentosCard
            name="Etiquetas multiuso pastel"
            text="Com as etiquetas multiuso pastel, organizar e categorizar nunca foi tão fofo e divertido!"
            btnLink="/pesquisa?destaque=EtiquetasMultiusoPastel"
            image={multiuso_pastel}
          />
          <ConhecaLancamentosCard
            name="Etiquetas em rolo"
            text="Etiquetas em rolo com papel couchê, branco fosco ou térmicas! Ideais para precificar e codificar tudo no seu lugar, tudo do seu jeito."
            btnLink="/pesquisa?destaque=EtiquetasRolo"
            image={etiqueta_rolo}
          />
          <ConhecaLancamentosCard invisible={true} />
          <ConhecaLancamentosCard invisible={true} />
        </Slider>
        <div className="page-content button-slider-container">
          <div className="carousel-landing-button-container">
            <NextPrevButtonGroup goToNext={goToNext} goToPrev={goToPrev} />
          </div>
        </div>
      </div>
    </div>
  );
}
