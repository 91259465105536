import { Button, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";

import StepperEditor from "./Stepper";

import pimaco_logo from "../../../assets/common/logo.webp";

import { BoxArrowRight, Laptop } from "react-bootstrap-icons";
import { useState } from "react";
import {
  PersonCircle,
  InputCursor,
  PersonLinesFill,
  Sticky,
  QuestionCircle,
  Trash
} from "react-bootstrap-icons";
import ModalUserData from "../authentication/modalUserData";
import ModalChangePassword from "../authentication/modalChangePassword";
import { useUserContext } from "../../../context/userContext/userContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModalDeleteAccount from "../authentication/modalDeleteAccount";

function Header(props: {
  userLogged: boolean;
  activeStep: number;
  handleChangeActiveStep: Function;
  setOpenModalMinhasEtiquetas: Function;
  setUserLogged: Function;
  setOpenPWAModal: Function;
  setActiveStep: Function;
}) {
  const { userData } = useUserContext();

  const isInstalledPWA: boolean =
    typeof window !== "undefined" &&
    window.matchMedia("(display-mode: standalone)").matches;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [openModalUserData, setOpenModalUserData] = useState<boolean>(false);
  const [openModalChangePassword, setOpenModalChangePassword] =
    useState<boolean>(false);

  const [openModalDeleteAccount, setOpenModalDeleteAccount] = useState<boolean>(false);
  const { t } = useTranslation("editor");
  return (
    <header className="editor-header">
      <img alt="" className="logo" src={pimaco_logo} />

      <StepperEditor
        activeStep={props.activeStep}
        handleChangeActiveStep={props.handleChangeActiveStep}
      />

      <div>
        <>
          <>
            {!isInstalledPWA && (
              <Button
                className="btn btn-header-editor"
                startIcon={<Laptop size="28" />}
                sx={{ flexDirection: "column" }}
                onClick={() => props.setOpenPWAModal(true)}
              >
                {t("Instalar")}
              </Button>
            )}
          </>
          {props.userLogged && (
            <>
              <Tooltip title="Configurações de conta">
                <Button
                  className="btn btn-header-editor"
                  startIcon={<PersonCircle size="28" />}
                  sx={{ flexDirection: "column" }}
                  onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  {userData.unique_name.split(" ").slice(0, 2).join(" ")}
                </Button>
              </Tooltip>
              <Menu
                elevation={1}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => setOpenModalUserData(true)}>
                  <ListItemIcon>
                    <PersonLinesFill size="20" />
                  </ListItemIcon>
                  {t("Meus dados")}
                </MenuItem>
                <MenuItem
                  onClick={() => props.setOpenModalMinhasEtiquetas(true)}
                >
                  <ListItemIcon>
                    <Sticky size="20" />
                  </ListItemIcon>
                  {t("Minhas Etiquetas")}
                </MenuItem>
                <MenuItem onClick={() => setOpenModalChangePassword(true)}>
                  <ListItemIcon>
                    <InputCursor size="20" />
                  </ListItemIcon>
                  {t("Trocar senha")}
                </MenuItem>
                <MenuItem onClick={() => setOpenModalDeleteAccount(true)}>
                  <ListItemIcon>
                    <Trash size="20" color="#D32F2F" />
                  </ListItemIcon>
                  {t("Excluir conta")}
                </MenuItem>
                <MenuItem>
                  <Link to="/faq" target="_blank" className="btn-link">
                    <ListItemIcon>
                      <QuestionCircle size="20" />
                    </ListItemIcon>
                    {t("Ajuda")}
                  </Link>
                </MenuItem>
                <MenuItem onClick={() => props.setUserLogged()}>
                  <ListItemIcon>
                    <BoxArrowRight size="20" />
                  </ListItemIcon>
                  {t("Sair")}
                </MenuItem>
              </Menu>
            </>
          )}
        </>
      </div>
      <ModalUserData
        open={openModalUserData}
        handleCloseModal={() => setOpenModalUserData(false)}
      />
      <ModalChangePassword
        open={openModalChangePassword}
        handleCloseModal={() => setOpenModalChangePassword(false)}
      />
      <ModalDeleteAccount
        open={openModalDeleteAccount}
        handleCloseModal={() => setOpenModalDeleteAccount(false)}
      />
    </header>
  );
}

export default Header;
