import { Button } from "@mui/material";

import imagem from '../../assets/assistente_impressao.webp'
import imagem_mobile from '../../assets/assistente_impressao_mobile.webp'

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ExperimenteAssistentePimaco () {

    const theme = createTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

	const { t } = useTranslation('homepage')

    return (
        <section className="experimente-assistente-impressao-container">
            <div className="experimente-asssistente-impressao-text-container">
                <h1>{t("Conheça nosso software de impressão")}</h1>
                
                <p>{t("Imprima suas etiquetas de forma simples! Conte com designs exclusivos ou personalize do seu jeito!")}</p>

                <Link to="/assistente_impressao" className="btn-link"> 
                    <Button
                        variant="outlined"
                        className="btn btn-outlined-primary"
                    >
                        {t("Saiba mais")}
                    </Button>
                </Link>
            </div>
            <img alt="" src={matches ? imagem_mobile: imagem} />
            
        </section>
    )
}