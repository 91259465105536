import { IStep } from "../../../interfaces/assistenteStep";

import {
    Impressao,
    Escrever,
    ProntoUso
} from './steps_tipo_interacao'

import {
    Retangular,
    Circular,
    Estrela,
    Coracao,
    Diversos,
} from './steps_formatos'

export const CartasEnvelopes:IStep = {
    stepId: 29,
    text: 'Cartas e envelopes',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em cartas e envelopes',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'CartasEnvelopes',
}

export const PastasDocumentos:IStep = {
    stepId: 30,
    text: 'Pastas e documentos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em pastas e documentos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'PastasDocumentos'
}

export const IdentificarObjetos:IStep = {
    stepId: 31,
    text: 'Identificar objetos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para identificar objetos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'IdentificarObjetos'
}

export const CaixasOrganizadoras:IStep = {
    stepId: 32,
    text: 'Caixas organizadoras',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em caixas organizadoras',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'CaixasOrganizadoras'
}

export const OrganizacaoAmbientes:IStep = {
    stepId: 33,
    text: 'Organização de ambientes',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para organização de ambientes',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'OrganizacaoAmbientes'
}

export const Congelamento:IStep = {
    stepId: 34,
    text: 'Congelamento',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'uso em congelamento',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Congelamento'
}

export const Presentes:IStep = {
    stepId: 35,
    text: 'Presentes',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para presentes',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: 'Uso',
    value: 'Presentes'
}


export const OrgDocumentos:IStep = {
    stepId: 36,
    text: 'Organizar documentos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para organizar documentos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: '',
    value: ''
}

export const Organizacao:IStep = {
    stepId: 37,
    text: 'Organização',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'para organização',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: false,
    name: '',
    value: ''
}

export const EncaparProtegerLivrosCadernos: IStep = {
    stepId: 91,
    text: 'Proteger superficies, livros e cadernos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'proteger superficies, livros e cadernos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: '',
    value: 'EncaparProtegerLivrosCadernos'
}

export const IdentificarAmbientesEletrodomesticos: IStep = {
    stepId: 92,
    text: 'Identificar ambientes ou eletrodomesticos',
    stepDescriptionText: 'Escolha o tipo de uso',
    selectedText: 'identificar ambientes ou eletrodomesticos',
    parent: undefined,
    //parent: step1Negocio,
    nextStep: [],
    finalStep: true,
    name: '',
    value: 'IdentificarAmbientesEletrodomesticos'
}

export function stepBuilderCasa() {

    CartasEnvelopes.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Coracao, Estrela], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    PastasDocumentos.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Coracao, Estrela], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    IdentificarObjetos.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Coracao, Estrela], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    CaixasOrganizadoras.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Coracao, Estrela], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    if (OrgDocumentos.nextStep.length == 0) {
        OrgDocumentos.nextStep.push(
            CartasEnvelopes,
            PastasDocumentos,
            IdentificarObjetos,
            CaixasOrganizadoras,
        );
    }

    OrganizacaoAmbientes.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [Retangular, Circular, Diversos], false) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao,
        ProntoUso.builder ? ProntoUso.builder(ProntoUso, [], true) : ProntoUso
    )

    Congelamento.nextStep.push(
        Escrever.builder ? Escrever.builder(Escrever, [], true) : Escrever,
        Impressao.builder ? Impressao.builder(Impressao, [], true) : Impressao, 
    )

    Presentes.nextStep.push(
        Retangular,
        Circular,
        Estrela,
        Coracao
    )

    if (Organizacao.nextStep.length == 0) {
        Organizacao.nextStep.push(
            OrganizacaoAmbientes,
            Congelamento,
            Presentes,
            IdentificarAmbientesEletrodomesticos
        );
    }

    return [OrgDocumentos, Organizacao, Presentes, EncaparProtegerLivrosCadernos];
}