import imagem from "../../../assets/assistente_impressao.webp";
import imagem2 from "../../../assets/tela_novo_software_limpa.webp";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
} from "@mui/material";

import LiteYouTubeEmbed from "react-lite-youtube-embed";

import { Plus } from "react-bootstrap-icons";

import CloseIcon from "@mui/icons-material/Close";

import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function AssistenteImpressao() {
  const { t } = useTranslation("assistente");

  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    window.scroll({
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      top: top,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="page-content assistente-impressao-page-content">
        <h1 className="h1-section-title">
          <span className="destaque-header-title">{t("NOVO")} &nbsp;</span>
          {t("Software de Impressão Pimaco")}
        </h1>
      </div>

      <div className="assistente-impressao-page-content-new-assistant-container">
        <div className="assistente-impressao-page-content-image">
          <img alt="" src={imagem2} />
        </div>
        <div className="assistente-impressao-page-content-text">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="destaques-assistente-impressao">
                <Plus className="icon" fontSize="3.5em" />
                <span>{t("Recursos de edição")}</span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="destaques-assistente-impressao">
                <Plus className="icon" fontSize="3.5em" />
                <span>{t("Praticidade")}</span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="destaques-assistente-impressao">
                <Plus className="icon" fontSize="3.5em" />
                <span>{t("Intuitivo")}</span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="destaques-assistente-impressao">
                <Plus className="icon" fontSize="3.5em" />
                <span>{t("Designs exclusivos")}</span>
              </div>
            </Grid>
          </Grid>

          <div className="accesse-container">
            <span className="text-center">{t("Acesse já:")}</span>
            <div className="button-container">
              <Link to="/APWeb" className="btn-link">
                <Button
                  variant="contained"
                  className="btn btn-contained-thertiary"
                  disableElevation
                >
                  {t("Software de impressão")}
                </Button>
              </Link>
            </div>
          </div>

          <div className="extra-info">
            <p>
              *
              {t(
                "Para acessar o Novo Software de Impressão Pimaco, você terá que fazer um novo cadastro"
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="page-content">
        <h2>{t("Tutorial")}</h2>

        <LiteYouTubeEmbed
          id="FgjNFNMl_zw"
          title="OUR CULTURE MANIFESTO"
          thumbnail="https://img.youtube.com/vi/FgjNFNMl_zw/maxresdefault.jpg"
        />

        <div className="assistente-impressao-page-content-button-tutorial">
          <div className="accesse-container">
            <div className="button-container">
              <a
                href="https://www.youtube.com/@pimaco.oficial/playlists"
                className="btn-link"
                download
              >
                <Button
                  variant="contained"
                  className="btn btn-contained-thertiary"
                  disableElevation
                >
                  {t("Mais tutoriais")}
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
