import { useCallback, useEffect, useState } from "react";
import {
  MirrorFeature,
  MirrorFeatures,
  objectFactory,
  useBoardContext,
  useKeysCombinationHandler,
} from "@beontag/tageditor";
import { useClipboardContext } from "./ClipboardContext";
import { Button } from "@mui/material";
import { Clipboard2Fill, SymmetryHorizontal } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const MirrorVertical = (props: {
  mirrorFeature: MirrorFeatures | undefined;
}) => {
  const { board } = useBoardContext();
  const { t } = useTranslation("editor");
  return (
    <Button
      className="btn"
      startIcon={<SymmetryHorizontal size={16} />}
      onClick={() => props.mirrorFeature?.mirror("vertical")}
      disabled={!board?.activeObjects.length}
    >
      {t("Espelhamento vertical")}
    </Button>
  );
};

export default MirrorVertical;
