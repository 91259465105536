import { useCallback, useEffect, useState } from "react";
import ContentBox from "../../../components/common/content-box";
import FaleConosco from "../../../components/contacts-forms/fale_conosco";
import TelaAgradecimento from "../../../components/contacts-forms/tela_agradecimento";
import TrabalheConosco from "../../../components/contacts-forms/trabalhe_conosco";

import header_image_fale_conosco from '../../../assets/fale_conosco.webp'
import header_image_trabalhe_conosco from '../../../assets/trabalhe_conosco.webp';
import { useTranslation } from "react-i18next";

function Contato() {

    const [activeComponent, setActiveComponent] = useState("fale-conosco");
    const [oldComponent, setOldComponent] = useState("");

    const modifyActiveComponent = useCallback(
        (newActiveComponent: any) => {
            setActiveComponent(newActiveComponent);
        },
        [setActiveComponent]
    );

    const { t, i18n, ready } = useTranslation('contato')

    useEffect(() => {
		window.scroll({
            // @ts-ignore
			// eslint-disable-next-line no-restricted-globals
			top: top,
			left: 0,
            behavior: 'smooth'	
		})
	}, [])

    return (
        <>
            {activeComponent === "fale-conosco" &&
                <FaleConosco
                    modifyActiveComponent={modifyActiveComponent}
                    setOldComponent={setOldComponent}
                    activeComponent={activeComponent}
                    image={header_image_fale_conosco}
                />
            }

            {activeComponent === "trabalhe-conosco" &&
                <TrabalheConosco
                    modifyActiveComponent={modifyActiveComponent}
                    setOldComponent={setOldComponent}
                    activeComponent={activeComponent}
                    image={header_image_trabalhe_conosco}
                />
            }

            {activeComponent === "submitted" &&
                <TelaAgradecimento
                    image={oldComponent == "trabalhe-conosco" ? header_image_trabalhe_conosco : header_image_fale_conosco}
                    title={oldComponent == "trabalhe-conosco" ? t("Trabalhe conosco") : t("Contato")}
                />
                }

            <ContentBox>
                <>
                    <h3>{t("Atendimento")}</h3>
                    <p>{t("Se preferir, ligue grátis para o nosso serviço de atendimento ao consumidor: ")}</p>
                    <h3>0800 440 0064</h3>
                    <p>{t("das 9h às 12h e das 14h às 17h")}</p>
                </>
            </ContentBox>

        </>
    )
}

export default Contato;