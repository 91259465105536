import { useTranslation } from "react-i18next"
import ContentHeader from "../../../../components/common/content-header";

import header_image from '../../../../assets/politica_privacidade.webp'
import { useEffect } from "react";

export default function PoliticaQualidade() {

    const { t } = useTranslation('politicaQualidade')

    useEffect(() => {
		window.scroll({
            // @ts-ignore
			// eslint-disable-next-line no-restricted-globals
			top: top,
			left: 0,
            behavior: 'smooth'	
		})
	}, [])

    return (
        <>
            <ContentHeader
                image={header_image}
                imageClass="politica-privacidade-image"
            >
                <h1 className="content-header-politica-privacidade">{t("Política de Gestão Integrada")}</h1>
            </ContentHeader>

            <div className="page-content empresa-content">

                <p className="page-paragraph">{t("A organização busca permanentemente a estabilidade dos processos e produtos, através de indicadores de desempenho, que norteiam nosso caminho e nos dão direção para o aperfeiçoamento dos nossos processos de melhoria contínua, qualidade, segurança, atendimento, responsabilidade ambiental, responsabilidade social, produtividade e custos, para isso é estratégia da organização manter e assegurar o cumprimento das seguintes diretrizes: ")}</p>

                <ul>
                    <li className="page-paragraph">
                        <p>{t("Promover melhoria contínua nos processos produtivos e de qualidade, mediante ao uso de indicadores de desempenho;")}</p>
                    </li>
                    <li className="page-paragraph">
                        <p>{t("Reduzir os riscos na realização das operações com ações voltadas a prevenção de acidentes, objetivando a preservação da saúde e da integridade física dos nossos colaboradores, de terceiros e visitantes;")}</p>
                    </li>
                    <li className="page-paragraph">
                        <p>{t("Satisfazer nossos clientes, bem como os clientes dos nossos clientes;")}</p>
                    </li>
                    <li className="page-paragraph">
                        <p>{t("Atender à legislação aplicável e outros requisitos pertinentes;")}</p>
                    </li>
                    <li className="page-paragraph">
                        <p>{t("Gerenciar os aspectos e impactos ambientais e reduzir os resíduos gerados, visando à proteção do meio ambiente;")}</p>
                    </li>
                    <li className="page-paragraph">
                        <p>{t("Promover ações sociais que fortaleçam nossa relação com a comunidade e partes interessadas;")}</p>
                    </li>
                    <li className="page-paragraph">
                        <p>{t("Gerenciar e reduzir os desperdícios dos processos, afim de garantir o resultado econômico financeiro da companhia.")}</p>
                    </li>
                </ul>

                <p className="page-paragraph">{t("Qualidade, ética e desenvolvimento sustentável, compromissos da Beontag.")}</p>

                <p className="page-paragraph">{t("Rev.: 05 Data: 01/09/2023")}</p>


                <h3>{t("ISO 9001 E ISO 14001")}</h3>

                <p className="page-paragraph">{t("A Beontag possui certificações ISO, que demonstram o compromisso com a qualidade de nossos produtos e a preocupação com a preservação e proteção do meio ambiente.")}</p>

                <p className="page-paragraph">{t("Contamos com a certificação ISO 9001, pelos programas de gestão de qualidade e melhoria contínua dos processos e a certificação ISO 14001, que define as ações para estabelecer um sistema de gestão ambiental efetivo. Ambas certificações validam a produção e comercialização dos nossos produtos, sendo estes papéis, filmes, etiquetas autoadesivas.")}</p>

            </div>
        </>
    )
}