import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import sTemplate from "../../../../services/templateService";
import { useEffect, useState } from "react";
import { useMessage } from "../../../../context/alertContext/context";

import { useEditorContext } from "../../../../context/editorContext/EditorContext";
import { ITema } from "../../../../interfaces/editor/Tema";
import { useBoardContext } from "@beontag/tageditor";
import { useTranslation } from "react-i18next";
import TemplateView from "./templateView";

const ModalSaveNewTemplate = (props: {
  open: boolean;
  handleCloseModal: Function;
  userCanCreateTemplate: boolean;
}) => {
  const { userCanCreateTemplate } = props;

  const { boardFile } = useBoardContext();

  const { showAlertApp } = useMessage();
  const { t } = useTranslation("editor");
  const { templates, templateEdicao } = useEditorContext();
  const temas: ITema[] = templates.temas.flatMap(
    (tema) => tema.categorias
  ) as ITema[];

  const [nomept, setNomept] = useState<string>(templateEdicao?.nomept || "");
  const [nomeen, setNomeen] = useState<string>(templateEdicao?.nomeen || "");
  const [nomees, setNomees] = useState<string>(templateEdicao?.nomees || "");
  useEffect(() => {
    setNomept(templateEdicao.nomept);
    setNomeen(templateEdicao.nomeen);
    setNomees(templateEdicao.nomees);
  }, [templateEdicao]);
  const [categoria, setCategoria] = useState<ITema>(
    templateEdicao?.idTema
      ? temas.find((x) => x.idTema == templateEdicao.idTema) || {
          idTema: 0,
          nome: "",
        }
      : { idTema: 0, nome: "" }
  );

  const saveAsTemplate = async () => {
    if (!userCanCreateTemplate) {
      showAlertApp(
        t("Ops, parece que você não tem acesso à essa funcionalidade!"),
        "warning",
        5000
      );
      props.handleCloseModal();
      return;
    }

    if (boardFile) {
      showAlertApp("Salvando...", "info");

      if (
        nomept == "" ||
        nomeen == "" ||
        nomees == "" ||
        categoria.idTema == 0
      ) {
        showAlertApp(
          t("Defina um nome e selecione uma categoria para este template"),
          "error",
          5000
        );
        return;
      }

      const content = boardFile.export();

      sTemplate
        .saveAsTemplate(categoria.idTema, content, nomept, nomeen, nomees)
        .then((resp) => {
          showAlertApp(t("Salvo com sucesso!"), "success", 5000);
          props.handleCloseModal();
        })
        .catch((err) => {
          if (err.response.status == 403) {
            showAlertApp(
              t(
                "Ops, você não tem permissão para usar esta função, contate um administrador de sistema"
              ),
              "error",
              5000
            );
          } else {
            showAlertApp(
              t(
                "Houve um problema ao salvar esta etiqueta como template, salve a sua edição no seu computador para não perder seu progresso"
              ),
              "error",
              5000
            );
          }
        });
    }
  };

  const updateTemplate = async () => {
    if (boardFile) {
      showAlertApp(t("Salvando..."), "info");

      if (
        nomept == "" ||
        nomeen == "" ||
        nomees == "" ||
        categoria.idTema == 0
      ) {
        showAlertApp(
          t("Defina um nome e selecione uma categoria para este template"),
          "error",
          5000
        );
        return;
      }

      const content = boardFile.export();

      sTemplate
        .updateTemplate({
          idTemplate: templateEdicao.idTemplate,
          nomept: nomept,
          nomeen: nomeen,
          nomees: nomees,
          idTema: categoria.idTema,
          json: content,
        })
        .then((resp) => {
          showAlertApp(t("Template salvo com sucesso!"), "success", 5000);
          props.handleCloseModal();
        })
        .catch((err) => {
          if (err.response.status == 403) {
            showAlertApp(
              t(
                "Ops, você não tem permissão para usar esta função, contate um administrador de sistema"
              ),
              "error",
              5000
            );
          } else {
            showAlertApp(
              t(
                "Houve um problema ao atualizar este template, salve a edição no seu computador para não perder seu progresso"
              ),
              "error",
              5000
            );
          }
        });
    }
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.handleCloseModal()}
        scroll="paper"
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle>
          <IconButton onClick={() => props.handleCloseModal()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <h1 className="editor-login-authentication-text">
            {t("Salvar template")}
          </h1>

          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="nomept"
                type="text"
                value={nomept}
                onChange={(e) => setNomept(e.target.value)}
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" disablePointerEvents>
                      <span className="form-label">{t("Nome PT:")}</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="nomeen"
                type="text"
                value={nomeen}
                onChange={(e) => setNomeen(e.target.value)}
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" disablePointerEvents>
                      <span className="form-label">{t("Nome EN:")}</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="nomees"
                type="text"
                value={nomees}
                onChange={(e) => setNomees(e.target.value)}
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" disablePointerEvents>
                      <span className="form-label">{t("Nome ES:")}</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="select-new-template-theme"
                options={temas}
                value={categoria}
                onChange={(event: any, value: ITema | null) => {
                  if (value != null) {
                    setCategoria(value);
                  }
                }}
                groupBy={(tema) =>
                  templates.temas.find(
                    (temaArrayItem) => temaArrayItem.idTema == tema.idTemaPai
                  )?.nome || tema.nome
                }
                getOptionLabel={(tema) => tema.nome}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    aria-label="Selecione a categoria..."
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start" disablePointerEvents>
                          <span className="form-label">{t("Categoria:")}</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {templateEdicao.idTemplate != 0 ? (
              <Grid item container xs={12} columnSpacing={2}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    className="btn btn-outlined-primary"
                    disableElevation
                    onClick={saveAsTemplate}
                  >
                    {t("Salvar como novo")}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    className="btn btn-contained-primary"
                    disableElevation
                    onClick={updateTemplate}
                  >
                    {t("Atualizar template")}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  className="btn btn-contained-primary"
                  disableElevation
                  onClick={saveAsTemplate}
                >
                  {t("Enviar")}
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalSaveNewTemplate;
