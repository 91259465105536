import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LoadingPlaceholder from "../../../../components/common/loadingPlaceholder";
import IProdutoCardPimaco from "../../../../interfaces/produto/produtoCardPimaco";
import { useTranslation } from "react-i18next";

export default function TabelaProdutosEditor(props: {
  isLoadingProdutos: boolean;
  produtos: IProdutoCardPimaco[];
  handleProductFiltering: Function;
  handleProdutoSelecionado: Function;
}) {
  const {
    isLoadingProdutos,
    produtos,
    handleProductFiltering,
    handleProdutoSelecionado,
  } = props;
  const { t } = useTranslation("editor");
  return (
    <Box>
      <TableContainer>
        <Table className="categoria-produtos" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("Código")}</TableCell>
              <TableCell align="center">{t("Tamanho")}</TableCell>
              <TableCell align="center">{t("Nome e Formato")}</TableCell>
              <TableCell align="center">{t("Imagem")}</TableCell>
              <TableCell align="center"> </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoadingProdutos &&
              [0, 1, 2, 3, 4].map((_, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      <LoadingPlaceholder>
                        <div className="loading-text" />
                      </LoadingPlaceholder>
                    </TableCell>
                    <TableCell align="center">
                      <LoadingPlaceholder>
                        <div className="loading-text" />
                      </LoadingPlaceholder>
                    </TableCell>
                    <TableCell align="center">
                      <LoadingPlaceholder>
                        <div className="loading-text long" />
                      </LoadingPlaceholder>
                    </TableCell>
                    <TableCell align="center">
                      <LoadingPlaceholder>
                        <div className="loading-image" />
                      </LoadingPlaceholder>
                    </TableCell>
                    <TableCell align="center">
                      <LoadingPlaceholder>
                        <div className="loading-button" />
                      </LoadingPlaceholder>
                    </TableCell>
                  </TableRow>
                );
              })}
            {produtos &&
              !isLoadingProdutos &&
              handleProductFiltering().map(
                (prod: IProdutoCardPimaco, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ fontWeight: "bold" }} align="center">
                        {" "}
                        {prod.codigo}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {prod.dimensao.toLowerCase()}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {prod.nome} - {prod.forma}{" "}
                      </TableCell>
                      <TableCell align="center">
                        <img
                          alt={prod.codigo}
                          src={prod.imagens[0]}
                          className="produto-imagem-etiquetas"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          className="btn btn-outlined-primary"
                          variant="outlined"
                          onClick={() => handleProdutoSelecionado(prod)}
                        >
                          {t("Personalizar")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {!produtos && !isLoadingProdutos && (
        <>
          <div className="editor-menu-categoria-selector-error">
            {t(
              "Não foi possível carregar as categorias, tente novamente mais tarde"
            )}
          </div>
        </>
      )}
    </Box>
  );
}
