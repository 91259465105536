import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import { ITechSpecsProduto } from '../../interfaces/produto/techSpecs';
import { useTranslation } from 'react-i18next';

export default function ProductTechSpecs(props: { dados: ITechSpecsProduto }) {

    const { dados } = props;

    const { t } = useTranslation('produtos')

    return (
        <div className='tech-specs-main-div'>
            <Toolbar className="table-toolbar">
                {t("Especificação técnica")}
            </Toolbar>
            <Table>

                <TableBody>
                    <TableRow>
                        <TableCell>
                            <b>{t("Cor")}</b>
                        </TableCell>
                        <TableCell>
                            {dados.cor}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <b>{t("Formato")}</b>
                        </TableCell>
                        <TableCell>
                            {dados.formato}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <b>{t("Tamanho do produto")}</b>
                        </TableCell>
                        <TableCell>
                            {dados.tamanho}
                        </TableCell>
                    </TableRow>
                    {dados.etiquetasPorFolha ?
                        <TableRow>
                            <TableCell>
                                <b>{t(dados.tituloQuantidadePorFolha)}</b>
                            </TableCell>
                            <TableCell>
                                {dados.etiquetasPorFolha}
                            </TableCell>
                        </TableRow>
                    : ''}
                    <TableRow>
                        <TableCell>
                            <b>{t(dados.tituloQuantidadeFolha)}</b>
                        </TableCell>
                        <TableCell>
                            {dados.folhas}
                        </TableCell>
                    </TableRow>
                    {dados.carreiras &&
                        <TableRow>
                            <TableCell>
                                <b>{t("Carreira(s)")}</b>
                            </TableCell>
                            <TableCell>
                                {dados.carreiras}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </div>
    )
}