import ProdutoCardInfo from './produtoCardInfo'

import IProdutoCardPimaco from '../../../interfaces/produto/produtoCardPimaco'

import { useTranslation } from 'react-i18next';

export default function ProdutoCard(props: { produto: IProdutoCardPimaco }) {

    const { t } = useTranslation('produtos')

    const { produto } = props;

    return (
        <>
        { produto ? 
            <>
            <div className="produto-card-image-description-container">
                <img src={produto.imagens[0] || undefined} loading='lazy'/>
                
                <div className="produto-card-description-container">
                    <div className="produto-card-description-text-container">
                        { !!produto.etiquetasPorFolha &&
                            <p>{t(produto.tituloQuantidadePorFolha)}: {produto.etiquetasPorFolha}</p>
                        }
                        
                        <p>{produto.folhas} ({produto.familia})</p>

                        { produto.carreiras && 
                            <p>{t('Carreiras')}: {produto.carreiras}</p>
                        }

                        <p>{produto.descricao}</p>
                        
                        <p>{t('Aplicação:')} {produto.aplicacao}</p>
                        
                    </div>

                    
                </div>
            </div>

            <ProdutoCardInfo
                name={produto.nome}
                format={produto.forma}
                code={produto.codigo}
                productId={produto.idProduto}
                sku={produto.sku}
            />
            </>
        : ''}
        </>
    )
}