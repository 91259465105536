import ContentHeader from "../../../../components/common/content-header";
import DownloadBox from "../../../../components/downloads/download-box";

import header_image from '../../../../assets/downloads_parametros_de_impressao.webp'

import { ParametroImpressaoIcon } from "../../../../components/common/icons";
import AprendaLerEtiquetasPimaco from "../../../../components/leitura-etiquetas/aprenda-ler-etiquetas";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";


export default function Parametros() {

    const { t } = useTranslation('downloads')

    useEffect(() => {
		window.scroll({
            // @ts-ignore
			// eslint-disable-next-line no-restricted-globals
			top: top,
			left: 0,
            behavior: 'smooth'	
		})
	}, [])

    return (
        <>
            <ContentHeader
                image={header_image}
                imageClass="content-header-image-parametros"
            >
                <div className="content-header-parametros">
                    <h1>{t("Parâmetros de impressão")}</h1>
                    <p className="page-paragraph">{t("Para facilitar a impressão das etiquetas, disponibilizamos as medidas dos nossos produtos.")}</p>
                </div>
            </ContentHeader>
            <div className="page-content parametros-container">


                <div className="downloads-paragraph">
                    <p className="page-paragraph">{t("Baixe de acordo com as configurações da sua máquina.")}</p>
                </div>

                <h2>{t("Cartões de Visita")}</h2>

                <div className="downloads-group">

                    <DownloadBox
                        code=""
                        titulo={t("CorelDRAW")}
                        downloadLink="/documents/parametros/cartoes_visita/params_cartao_visita_corel.doc"
                        downloadButtonText={t("Baixar")}
                        iconImage={<ParametroImpressaoIcon />}
                    />

                    <DownloadBox
                        code=""
                        titulo={t("Microsoft Word")}
                        downloadLink="/documents/parametros/cartoes_visita/params_cartao_visita_word.doc"
                        downloadButtonText={t("Baixar")}
                        iconImage={<ParametroImpressaoIcon />}
                    />

                </div>

                <h2>{t("Inkjet + Laser e Especiais ( Carta e A4 )")}</h2>

                <div className="downloads-group">

                    <DownloadBox
                        code=""
                        titulo={t("Microsoft Word 2007")}
                        downloadLink="/documents/parametros/inkjet_a4/params_inkjet_a4_especiais_word_2007.doc"
                        downloadButtonText={t("Baixar")}
                        iconImage={<ParametroImpressaoIcon />}
                    />
                    <DownloadBox
                        code=""
                        titulo={t("Microsoft Word 2000, XP / 2003")}
                        downloadLink="/documents/parametros/inkjet_a4/params_inkjet_a4_especiais_word.doc"
                        downloadButtonText={t("Baixar")}
                        iconImage={<ParametroImpressaoIcon />}
                    />
                    <DownloadBox
                        code=""
                        titulo={t("Open Office")}
                        downloadLink="/documents/parametros/inkjet_a4/params_inkjet_a4_especiais_open_office.doc"
                        downloadButtonText={t("Baixar")}
                        iconImage={<ParametroImpressaoIcon />}
                    />
                </div>

                <h2>{t("Inkjet + Laser - A5 Evolution")}</h2>

                <div className="downloads-group">

                    <DownloadBox
                        code=""
                        titulo={t("Microsoft Word 2007")}
                        downloadLink="/documents/parametros/inkjet_a5/params_inkjet_a5_word_2007.doc"
                        downloadButtonText={t("Baixar")}
                        iconImage={<ParametroImpressaoIcon />}
                    />
                    <DownloadBox
                        code=""
                        titulo={t("Microsoft Word")}
                        downloadLink="/documents/parametros/inkjet_a5/params_inkjet_a5_word.doc"
                        downloadButtonText={t("Baixar")}
                        iconImage={<ParametroImpressaoIcon />}
                    />
                    <DownloadBox
                        code=""
                        titulo={t("Open Office")}
                        downloadLink="/documents/parametros/inkjet_a5/params_inkjet_a5_open_office.doc"
                        downloadButtonText={t("Baixar")}
                        iconImage={<ParametroImpressaoIcon />}
                    />
                    <DownloadBox
                        code=""
                        titulo={t("CorelDRAW 6.0 - 12.0")}
                        downloadLink="/documents/parametros/inkjet_a5/params_inkjet_a5_corel.doc"
                        downloadButtonText={t("Baixar")}
                        iconImage={<ParametroImpressaoIcon />}
                    />
                </div>

                <h2>{t("Pimatab")}</h2>

                <div className="downloads-group">

                    <DownloadBox
                        code=""
                        titulo={t("Microsoft Word 2007")}
                        downloadLink="/documents/parametros/pimatab/params_pimatab_word_2007.doc"
                        downloadButtonText={t("Baixar")}
                        iconImage={<ParametroImpressaoIcon />}
                    />
                    <DownloadBox
                        code=""
                        titulo={t("Microsoft Word")}
                        downloadLink="/documents/parametros/pimatab/params_pimatab_word.doc"
                        downloadButtonText={t("Baixar")}
                        iconImage={<ParametroImpressaoIcon />}
                    />
                </div>

            </div>

            <AprendaLerEtiquetasPimaco />
        </>
    )
}