export const duvidas = [
	{
		tituloCategoria: "Dúvidas frequentes sobre produtos",
		duvidas: [
			{
				titulo: "1 - Onde compro os produtos Pimaco?",
				subtitulo: "Para os varejos, por favor, entrar em contato com a nossa Central de Atendimento ao Cliente.  Para os consumidores, os nossos produtos podem ser encontrados nas papelarias distribuídas pelo Brasil. Caso tenha dúvidas, por favor, entrar em contato via telefone: 0800 440 6400 ou pimaco@pimaco.com.br"
			}, {
				titulo: "2 - Comprei um produto que apresenta defeito. O que fazer?",
				subtitulo: "Nesse caso entre em contato conosco através do e-mail pimaco@pimaco.com.br e iremos abrir uma ficha de reclamação para análise detalhada do que ocorreu com o produto e, assim, fornecer uma resposta mais precisa a respeito."
			}, {
				titulo: "3 - Qual a melhor impressora para fazer impressão das etiquetas Pimaco?",
				subtitulo: "Informamos que a Pimaco possui etiquetas para impressoras a jato de tinta e a laser. Não indicamos marcas de impressoras pelo fato de ter muitas no mercado, deixamos a escolha dos usuários. É preciso verificar se a impressora escolhida puxa a gramatura das etiquetas, que é de 157 à 209 g/m2"
			}, {
				titulo: "4 - Posso remover as etiquetas Inkjet e Laser Pimaco e reutiliza-las?",
				subtitulo: "Informamos que as etiquetas Inkjet e Laser Pimaco não são indicadas para tipos de colagem em que haja interesse de remoção."
			}, {
				titulo: "5 - Posso molhar as etiquetas para congelador?",
				subtitulo: "A etiqueta para congelador é de papel e o material é resistente a baixas temperaturas, porém não é a prova d`agua."
			}, {
				titulo: "6 - Minha etiqueta está descolando o que fazer?",
				subtitulo: "Podemos abrir uma ficha de reclamação para que nos envie esse material para análise  para identificar o que  ocorreu com o produto e, assim, fornecer uma resposta mais precisa a respeito. Entre em contato com nossa central de atendimento por telefone 0800 440 6400 ou pimaco@pimaco.com.br"
			}, {
				titulo: "7 - As etiquetas multiuso possuem gabaritos ou podem ser impressas?",
				subtitulo: "Não. As Etiquetas Multiuso não são adequadas para utilização em impressoras. São indicadas para uso manual, e por esse motivo não foi desenvolvido um programa de impressão para as mesmas."
			}, {
				titulo: "8 - Onde localizo a assistência técnica para SLP Pimaco?",
				subtitulo: "A Pimaco não possui assistência técnica. Impressoras que estejam dentro do prazo de garantia e apresentem comprovadamente defeitos de fabricação, serão substituidas."
			}, {
				titulo: "9 - Qual a durabilidade da Smart Label Printer?",
				subtitulo: "É dependente das condições de uso. Um número razoável seria de 100km de papel o que representaria cerca de 1 milhão de etiquetas e um ambiente sem poeira e com temperatura e umidade controladas."
			}, {
				titulo: "10 - Como funciona o processo de impressão da Smart Label Printer?",
				subtitulo: "A Smart Label Printer opera no princípio chamado de impressao térmica direta. Um mecanismo de impressão que gera calor e marca diretamente o papel termo sensível. Nesse processo o único consumível é o papel. Não há cartuchos de tinta, toners ou fita."
			}, {
				titulo: "11 - Qual a gramatura da etiqueta Pimaco?",
				subtitulo: "A gramatura das etiquetas Pimaco são de  157 à 209 g/m2."
			}
		],
	}, {
		tituloCategoria: "Dúvidas frequentes sobre o Software de Impressão",
		duvidas: [
			{
				titulo: "1 - Consigo girar a etiqueta A5 no Software pimaco?",
				subtitulo: "Não, somente o texto. Neste caso, utiliza-se a opção 'Girar 90º para esquerda/ direita'."
			}, {
				titulo: "2 - Fiz todo procedimento e no final deu 'erro ao criar imagem de impressão'. o que fazer?",
				subtitulo: "É um erro de incompatibilidade com o sistema, no qual será preciso desinstalar o programa e instalar o Software Pimaco para Word."
			}, {
				titulo: "3 - Como abrir o Software pimaco para word?",
				subtitulo: "Para abrir o Software Pimaco para Word, basta acessar o Menu Iniciar de seu computador > Todos os Programas > AP2007/ AP2010 > Pimaco. Caso deseje abrir etiquetas para CD, basta selecionar a opção CDpply 'Carta' ou 'A4' de acordo com o modelo da etiqueta que possui."
			}, {
				titulo: "4 - Qual software baixar para elaborar etiqueta para cd/dvd?",
				subtitulo: "Qualquer software que seja compatível com seu Microsoft Word."
			}, {
				titulo: "5 - qual a versão do meu windows/sistema operacional?",
				subtitulo: "Acesse o painel de controle de seu computador e clique no menu 'Sistema'."
			}, {
				titulo: "6 - Os softwares são pagos?",
				subtitulo: "Não, todos os softwares disponíveis neste site são gratuitos."
			}, {
				titulo: "7 - Quais fatores que podem influenciar na impressão errada?",
				subtitulo: "• Tamanho do papel.\nNo momento da impressão é necessário verificar o tamanho do papel utilizado:\nNa janela de 'Imprimir', clique no botão 'Propriedades ou Propriedades da Impressão'\nSelecione a guia 'Papel';\nVerifique o tamanho do papel que aparece;\nModifique o tamanho do papel de acordo com a sua folha de etiquetas;(A5, A4 ou Carta, verificar na embalagem da etiqueta)\nClique em 'ok' para fechar as janelas e imprima seu arquivo.\n\n• Tipo de Impressão:\nNa janela de 'Imprimir', clique no botão 'Propriedades ou Propriedades da Impressão'\nVerifique o tipo da impressão ( Dependendo do modelo da impressora, a nomenclatura é diferente, pode ser encontrada como 'tipo da impressão', 'qualidade da impressão' etc. )\nNunca utilizar Rascunho, rascunho rápido, impressão rápida, impressão econômica ou similar, utilizar sempre qualidade Normal ou superior.\n\n• Configuração de Dimensionamento de conteúdo ( somente para utilização do Software para Word):\nWord 2007:\nClique no botão Office ( redondo acima a esquerda)\nClique em Avançado\nDesça a barra de rolagem até o típico 'Imprimir'\nDesmarque a opção 'Dimensionar conteúdo para tamanho de papel A4'\nWord 2010:\nClique em Arquivo\nClique em Avançado\nDesça a barra de rolagem até o tópico 'Imprimir'\nDesmarque a opção 'Dimensionar conteúdo para tamanho de papel A4'\n\n• Configuração / Especificações da Impressora:\nAs etiquetas PIMACO tem gramatura mÚdia de 170 g/m2, logo é necessário utilizar uma impressora que suporte este tipo de gramatura.\nAlguns modelos necessitam de um determinado procedimento para configurar para impressão de etiquetas, neste caso, é necessário contatar o suporte da fabricante da impressora."
			}, {
				titulo: "8 - Como salvar etiquetas no Software pimaco +2.2.0?",
				subtitulo: "1. Entre no Software Pimaco;\n2. Elabora o conteúdo na etiqueta;\n3. Após elaborado, clique em salvar trabalho;\n4. Selecione um compartimento no seu micro para salvar o trabalho."
			}, {
				titulo: "9 - Como abrir etiquetas salvar no Software pimaco +2.2.0?",
				subtitulo: "1. Entre no Software Pimaco;\n2. Clique em abrir trabalho salvo;\n3. A etiqueta salva ficará disponível para alterações."
			}
		]
	}
]