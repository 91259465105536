import { useTranslation } from "react-i18next";
import IProdutoCardPimaco from "../../../../interfaces/produto/produtoCardPimaco";

export default function DadosProdutoSelecionadoEditor(props: {
  produtoSelecionado: IProdutoCardPimaco | undefined;
}) {
  const { produtoSelecionado } = props;
  const { t } = useTranslation("editor");
  return (
    <aside className="editor-menu-prod-selecionado-info-container">
      <div className="editor-menu-prod-selecionado-content">
        <img src={produtoSelecionado?.imagens[0]} />
        <div className="editor-menu-prod-selecionado-data">
          <b>{t("Produto selecionado:")}</b>
          <p>{produtoSelecionado?.nome}</p>
          <p>
            <b>{produtoSelecionado?.codigo}</b>
          </p>
          <p>{produtoSelecionado?.forma}</p>
          <p>
            <b>{produtoSelecionado?.folhas}</b>
          </p>
        </div>
      </div>
    </aside>
  );
}
