
import RelatedProducts from '../../../components/produto/relatedProducts';
import ProductTechSpecs from '../../../components/produto/techSpecs';
import { IProdutoPimaco } from '../../../interfaces/produto/produto';
import { ITechSpecsProduto } from '../../../interfaces/produto/techSpecs';

import FacaMaisComEtiquetas from '../../../components/produto/faca-mais-com-etiquetas'

import sProduto from '../../../services/produtoService'

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';

function Produto() {

    const [produto, setProduto] = useState<IProdutoPimaco>();
    const { t, i18n, ready } = useTranslation('produtos')
    const { productId } = useParams();

    useEffect(() => {
        sProduto.obtemProduto(i18n.language, productId).then((resp: AxiosResponse) => {
            setProduto(resp.data)

        }).catch((err: AxiosError) => {
            console.error(err)
        })
    }, [productId, i18n.language])


    let dadosTecnicos: ITechSpecsProduto;

    if (produto) {
        dadosTecnicos = {
        cor: produto?.cor,
        formato: produto?.forma,
        tamanho: produto?.dimensao,
        etiquetasPorFolha: produto?.etiquetasPorFolha,
        folhas: produto?.folhas,
        carreiras: produto?.carreiras,
        tituloQuantidadePorFolha: produto?.tituloQuantidadePorFolha,
        tituloQuantidadeFolha: produto?.tituloQuantidadeFolha,
    }
    }
    

    useEffect(() => {
		window.scroll({
            // @ts-ignore
			// eslint-disable-next-line no-restricted-globals
			top: top,
			left: 0,
            behavior: 'smooth'
			
		})
	}, [])

    return (
        <>
            <section className='product-page-header-container'>
                <div className='product-page-header-content'>
                    <div className='product-page-image-container'>
                        <img alt="" src={produto?.imagens ? produto.imagens[0] : ''} />
                    </div>
                    <div className='product-page-text-container'>
                        <h1>{produto?.nome}</h1>
                        <h3>{produto?.tipo}</h3>
                    </div>
                </div>
            </section>
            <section className='page-content product-content'>
                <h2>{t("Detalhes do produto: ")}</h2>
                <p className="page-paragraph">{produto?.descricao}</p>
                
                {/* @ts-ignore */}
                {dadosTecnicos && <ProductTechSpecs dados={dadosTecnicos} />}
                
            </section>
            
            {produto && produto?.produtosRelacionados.length != 0 ?
                <RelatedProducts produtosRelacionados={produto.produtosRelacionados} />
            : <><br/> <br/> <br/></> }

            {produto && <FacaMaisComEtiquetas gabaritoUrl={produto?.gabaritoUrl} /> }
        </>
    );
}

export default Produto;

// export function ErrorBoundary({ error }) {
//     return (
//         <div className='page-error-container'>
//             <h1>Oops, essa página não existe!</h1>
//             {console.error(error)}
//         </div>
//     )
// }