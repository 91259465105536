import { useState } from "react";
import {
  useBoardContext,
  LayerFeature,
  LayerFeatures,
} from "@beontag/tageditor";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";
import { Layers as LayersIcon } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const Layers = () => {
  const { board } = useBoardContext();
  const [layerFeature, setLayerFeature] = useState<LayerFeatures>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation("editor");
  return (
    <LayerFeature featureUpdate={(f) => setLayerFeature(f)}>
      <Button
        className="btn"
        startIcon={<LayersIcon size={16} />}
        endIcon={<KeyboardArrowDown />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={!board?.activeObjects.length}
      >
        {t("Posição")}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          className: "menu-dropdown",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <MenuItem onClick={() => layerFeature?.up(false)}>
          <ListItemIcon>
            <KeyboardArrowUp fontSize="small" />
          </ListItemIcon>

          <ListItemText>{t("Avançar")} </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => layerFeature?.down(false)}>
          <ListItemIcon>
            <KeyboardArrowDown fontSize="small" />
          </ListItemIcon>

          <ListItemText> {t("Recuar")} </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => layerFeature?.up(true)}>
          <ListItemIcon>
            <KeyboardDoubleArrowUp fontSize="small" />
          </ListItemIcon>

          <ListItemText> {t("Trazer para frente")} </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => layerFeature?.down(true)}>
          <ListItemIcon>
            <KeyboardDoubleArrowDown fontSize="small" />
          </ListItemIcon>

          <ListItemText> {t("Enviar para trás")} </ListItemText>
        </MenuItem>
      </Menu>
    </LayerFeature>
  );
};

export default Layers;
