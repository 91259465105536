import { useBoardContext, PageCanvas, BoardPage } from "@beontag/tageditor";
import {
  Button,
  Grid,
  Popper,
  TextField,
  IconButton,
  Tooltip,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";

import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";

import {
  ArrowsAngleContract,
  InfoCircle,
  Trash,
  PlusCircle,
} from "react-bootstrap-icons";
import { useEditorContext } from "../../../../../context/editorContext/EditorContext";
import { useTranslation } from "react-i18next";

export default function EtiquetaEditorPages() {
  const { boardFile } = useBoardContext();
  const [showMenu, setShowMenu] = useState<boolean>(true);

  const [boardPage, setBoardPage] = useState<BoardPage>();
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const { produtoSelecionado, numberOfPages, setNumberOfPages } =
    useEditorContext();

  let currentPage = boardFile?.pageNumber || 1;

  const setEditMode = (mode: "all" | "one") => {
    if (boardFile) {
      boardFile.editMode = mode;
    }
  };

  const setPageNumber = (rawValue: string) => {
    if (boardFile) {
      const value = Number.parseInt(rawValue);

      if (isNaN(value)) {
        return;
      }

      if (value < 1) {
        currentPage = 1;
      }

      boardFile.pageNumber = value;
      currentPage = value;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowPreview(true);
    }, 200);
  }, []);

  useEffect(() => {
    if (boardPage) {
      boardPage.render();
    }
  }, [boardPage]);

  const { t } = useTranslation("editor");
  return (
    <>
      <Draggable cancel=".not-draggable" bounds="parent" handle=".handle">
        <Popper open className="property-bar-container etiqueta-editor-pages">
          <div className="property-bar-title handle">
            <span>{t("Qual etiqueta deseja editar?")}</span>
            <button
              title="show-hide-menu"
              onClick={() => setShowMenu(!showMenu)}
            >
              <ArrowsAngleContract size={20} className="not-draggable icon" />
            </button>
          </div>

          {showMenu ? (
            <div className="etiqueta-editor-pages-container">
              <Grid container>
                <Grid item xs={12}>
                  <div className="etiqueta-editor-pages-info-container">
                    <span>{produtoSelecionado?.codigo}</span>
                    <Tooltip
                      classes={{ tooltip: "produto-info-tooltip" }}
                      placement="bottom"
                      title={
                        <>
                          <Paper elevation={2}>
                            <aside className="editor-menu-prod-selecionado-info-container tooltip">
                              <div className="editor-menu-prod-selecionado-content">
                                <img
                                  src={produtoSelecionado?.imagens[0]}
                                  title="Imagem do produto"
                                />
                                <div className="editor-menu-prod-selecionado-data">
                                  <b>{t("Produto selecionado:")}</b>
                                  <p>{produtoSelecionado?.nome}</p>
                                  <p>
                                    <b>{produtoSelecionado?.codigo}</b>
                                  </p>
                                  <p>{produtoSelecionado?.forma}</p>
                                  <p>
                                    <b>{produtoSelecionado?.folhas}</b>
                                  </p>
                                </div>
                              </div>
                            </aside>
                          </Paper>
                        </>
                      }
                    >
                      <IconButton
                        onClick={() => undefined}
                        size="small"
                        className={`cursor-button`}
                      >
                        <InfoCircle />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid item md={6}>
                  <Button
                    className={`btn ${
                      boardFile?.editMode == "all"
                        ? "btn-contained-thertiary"
                        : "btn-outlined-primary"
                    }`}
                    variant={
                      boardFile?.editMode == "all" ? "contained" : "outlined"
                    }
                    onClick={() => setEditMode("all")}
                    disableElevation
                  >
                    {t("TODOS")}
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Button
                    className={`btn ${
                      boardFile?.editMode == "one"
                        ? "btn-contained-thertiary"
                        : "btn-outlined-primary"
                    }`}
                    variant={
                      boardFile?.editMode == "one" ? "contained" : "outlined"
                    }
                    onClick={() => setEditMode("one")}
                    disableElevation
                  >
                    {t("APENAS UMA")}
                  </Button>
                </Grid>
              </Grid>

              {showPreview && (
                <div className="etiqueta-editor-pages-preview-container">
                  <PageCanvas
                    featureUpdate={(f) => setBoardPage(f)}
                    showEdges
                    renderless={false}
                    readonly={false}
                  />
                </div>
              )}

              <div className="etiqueta-editor-pages-page-switcher-container">
                <button
                  title="Anterior"
                  onClick={() =>
                    boardFile?.pageNumber != 1
                      ? setPageNumber(
                          ((boardFile?.pageNumber ?? 2) - 1).toString()
                        )
                      : ""
                  }
                >
                  <ArrowBackIosNewOutlined
                    className={`etiqueta-paginator-arrow ${
                      boardFile?.pageNumber != numberOfPages
                        ? "active-button"
                        : ""
                    }`}
                  />
                </button>

                <div className="etiqueta-editor-pages-inputs">
                  <TextField
                    fullWidth
                    type="number"
                    InputProps={{
                      inputProps: { min: 1, step: 1, max: numberOfPages },
                    }}
                    value={currentPage}
                    onChange={(e) => setPageNumber(e.target.value)}
                    variant="filled"
                    className="not-draggable etiqueta-editor-pages-value"
                  />

                  <span>{t("de")}</span>

                  <span className="current-page">{numberOfPages}</span>
                </div>

                <button
                  title="Próximo"
                  onClick={() =>
                    boardFile?.pageNumber != numberOfPages
                      ? setPageNumber(
                          ((boardFile?.pageNumber ?? 1) + 1).toString()
                        )
                      : ""
                  }
                >
                  <ArrowForwardIosOutlined
                    className={`etiqueta-paginator-arrow ${
                      boardFile?.pageNumber != numberOfPages
                        ? "active-button"
                        : ""
                    }`}
                  />
                </button>
              </div>
              <div className="etiqueta-editor-pages-page-switcher-container">
                <div className="etiqueta-editor-pages-inputs add-remove-page-container">
                  <Button
                    variant="outlined"
                    className="btn btn-outlined-primary btn-add-page"
                    onClick={() => {
                      // @ts-ignore
                      setNumberOfPages((prev: number) => prev + 1);
                      if (boardFile) {
                        boardFile.pageNumber = boardFile.pageNumber + 1;
                      }
                    }}
                    title="Adicionar pagina"
                    startIcon={<PlusCircle size={15} />}
                  >
                    {t("Adicionar página")}
                  </Button>

                  <Button
                    className="btn btn-delete-page"
                    onClick={() => {
                      boardFile?.deletePage(boardFile.pageNumber);
                      // @ts-ignore
                      setNumberOfPages((prev: number) => {
                        if (prev != 1) {
                          if (boardFile?.pageNumber == prev) {
                            boardFile.pageNumber = boardFile.pageNumber - 1;
                          }
                          return prev - 1;
                        }
                        return prev;
                      });
                    }}
                    disableElevation
                    variant="contained"
                    color="error"
                    title="Remover pagina"
                    startIcon={<Trash size={15} />}
                  >
                    {t("Remover página")}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Popper>
      </Draggable>
    </>
  );
}
