import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useMessage } from "../../../../context/alertContext/context";
import { useEditorContext } from "../../../../context/editorContext/EditorContext";
import { IFormEditorFiltroProdutos } from "../../../../interfaces/editor/form-filtro-produtos";
import Open from "../tagEditor/actionBar/Open";
import { IProdutoEditor } from "../../../../interfaces/context/editorContext";
import IProdutoCardPimaco from "../../../../interfaces/produto/produtoCardPimaco";

const formas = [
  {
    value: "Circular",
    label: "Circular",
  },
  {
    value: "Retangular",
    label: "Retangular",
  },
];

interface IExtra {
  tagData: {
    sku: string;
  };
}

export default function FormFiltroEtiquetaSelection(props: {
  handleInputChange: Function;
  form: IFormEditorFiltroProdutos;
  setForm: Function;
  setActiveStep: Function;
  setProdutoSelecionado: Function;
  setOpenModalMinhasEtiquetas: Function;
}) {
  const { selecionarProduto, setEtiquetaUsuario, setEtiqueta, produtosEditor } =
    useEditorContext();

  const { showAlertApp } = useMessage();

  const onLoadEvent = (ev: ProgressEvent<FileReader>) => {
    const json = ev?.target?.result as string;
    if (json) {
      try {
        const data = JSON.parse(json);
        setEtiqueta(json);
        const produtoSelecionado = produtosEditor.find(
          (x) => x.sku == (data.extra! as IExtra).tagData.sku
        );
        props.setProdutoSelecionado((data.extra! as IExtra).tagData.sku);
        if (produtoSelecionado) {
          selecionarProduto(produtoSelecionado);
        }
        props.setActiveStep(3);
      } catch (err) {
        showAlertApp("Arquivo inválido", "error", 5000);
      }
    }
    setEtiquetaUsuario(undefined);
  };

  const { handleInputChange, form, setForm } = props;
  const { t } = useTranslation("common");
  const { t: tEditor } = useTranslation("editor");

  return (
    <Grid
      container
      columnSpacing={4}
      className="editor-menu-filters"
      key="editor-menu-filters"
    >
      <Grid item sm={3}>
        <InputLabel className="produto-filter-input-label">
          {tEditor("Nome ou Código do Produto:")}
        </InputLabel>

        <TextField
          fullWidth
          type="text"
          name="nomeCodigo"
          variant="filled"
          onChange={(e) => handleInputChange(e)}
          value={form.nomeCodigo}
        />
      </Grid>
      <Grid item sm={2}>
        <InputLabel className="produto-filter-input-label">
          {tEditor("Largura:")}
        </InputLabel>

        <TextField
          fullWidth
          type="text"
          name="largura"
          variant="filled"
          onChange={(e) => handleInputChange(e)}
          value={form.largura}
        />
      </Grid>
      <Grid item xs={6} sm={2}>
        <InputLabel className="produto-filter-input-label">
          {tEditor("Altura:")}
        </InputLabel>

        <TextField
          fullWidth
          type="text"
          name="altura"
          variant="filled"
          onChange={(e) => handleInputChange(e)}
          value={form.altura}
        />
      </Grid>
      <Grid item sm={2} xs={12}>
        <InputLabel className="produto-filter-input-label">
          {tEditor("Formato:")}
        </InputLabel>

        <FormControl fullWidth>
          <Select
            value={form.formato}
            name="formato"
            onChange={(e) => handleInputChange(e)}
            variant="filled"
            MenuProps={{
              className: "formato-produtos",
            }}
            endAdornment={
              <InputAdornment
                position="end"
                className="form-clear-selection"
                onClick={(e) => setForm({ ...form, formato: "" })}
              >
                <div>
                  <XLg size={20} />
                </div>
              </InputAdornment>
            }
          >
            {formas.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {t(option.label)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={2}>
        <InputLabel className="produto-filter-input-label">
          {tEditor("Etiq. por folha:")}
        </InputLabel>

        <TextField
          fullWidth
          type="number"
          name="quantidadePorFolha"
          variant="filled"
          onChange={(e) => handleInputChange(e)}
          value={form.quantidadePorFolha}
        />
      </Grid>
      <Grid item sm={1} sx={{ display: "flex", alignItems: "center" }}>
        <Open
          openOnHover={false}
          vertical={false}
          onLoadEvent={onLoadEvent}
          setOpenModalMinhasEtiquetas={props.setOpenModalMinhasEtiquetas}
        />
      </Grid>
    </Grid>
  );
}
