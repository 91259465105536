import { useCallback, useEffect } from "react";
import {
  objectFactory,
  useBoardContext,
  useKeysCombinationHandler,
} from "@beontag/tageditor";
import { useClipboardContext } from "./ClipboardContext";
import { Button } from "@mui/material";
import { Scissors as CutIcon } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export interface CutProps {
  cutDisplacement: number;
}

const Cut: React.FunctionComponent<CutProps> = (props) => {
  const { board } = useBoardContext();
  const { setClipboard } = useClipboardContext();
  const { t } = useTranslation("editor");
  const cut = useCallback(() => {
    const activeObjects = board?.activeObjects;
    const canCut =
      activeObjects?.length &&
      activeObjects.every((obj) => !objectFactory.Object.isEditing(obj));
    if (canCut) {
      objectFactory.Object.clone(activeObjects, board?.activeSelection).then(
        (clones) => {
          clones.forEach((c) =>
            c.set({
              left: (c.left || 0) + props.cutDisplacement,
              top: (c.top || 0) + props.cutDisplacement,
            })
          );

          setClipboard(clones);
          board?.remove(...activeObjects);
        }
      );
    }
  }, [props.cutDisplacement, board, setClipboard]);

  const CtrlXPressCount = useKeysCombinationHandler(["Control", "X"]);
  useEffect(() => {
    if (CtrlXPressCount) {
      cut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CtrlXPressCount]);

  return (
    <Button
      className="btn"
      startIcon={<CutIcon size={16} />}
      onClick={cut}
      disabled={!board?.activeObjects.length}
    >
      {t("Recortar")}
    </Button>
  );
};

export default Cut;
