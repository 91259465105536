import { useBoardContext, usePropertyContext } from "@beontag/tageditor";
import { Grid, IconButton, TextField } from "@mui/material";

import { ArrowsMove, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const Line: React.FunctionComponent = (props) => {
  const { properties, setProperty } = usePropertyContext<fabric.Line>();

  const handleOnChange = (key: string, rawValue: string) => {
    setProperty({ [key]: rawValue });
  };

  const { board } = useBoardContext();

  const closeWindow = () => {
    board?.setActiveObjects();
  };

  const { t } = useTranslation("editor");

  return (
    <>
      <div className="property-bar-title">
        {t("Propriedades do objeto")}
        <ArrowsMove size={20} className="icon left" />
        <IconButton
          onClick={() => closeWindow()}
          className="icon right not-draggable"
        >
          <XLg size={20} color="white" />
        </IconButton>
      </div>
      <Grid container className="tb-main-grid">
        <Grid item md={3}>
          <div className="property-bar-text-itens">
            <span>{t("Largura do traço")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { min: 0, step: 1 } }}
              value={properties.strokeWidth || ""}
              onChange={(e) =>
                handleOnChange("strokeWidth", e.currentTarget.value)
              }
              variant="filled"
            />
          </div>

          <div className="property-bar-text-itens">
            <span>{t("Cor do traço")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="color"
              value={properties.stroke?.toString() || "#000"}
              onChange={(e) => handleOnChange("stroke", e.currentTarget.value)}
              variant="filled"
            />
          </div>
        </Grid>

        <Grid item md={3}>
          <div className="property-bar-text-itens">
            <span>{t("Opacidade")}</span>
            <TextField
              fullWidth
              className="input-main-width"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 1, step: 1 } }}
              value={properties.opacity || 1}
              onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
              variant="filled"
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Line;
