import { useEffect, useRef, useState } from "react";
import IProdutoCardPimaco from "../../interfaces/produto/produtoCardPimaco";
import ProdutoCard from "./produto-card/produtoCard";

const ProdutoList = (props: {
	produtos: IProdutoCardPimaco[],
  }) => {
  
	return (
        <div className="product-list">
            {(props.produtos && props.produtos.length > 0) ? props.produtos.map((produto, index) => {
                return (
                    <ProdutoItem produto={produto} key={produto.sku} index={index} />
                )
            }): ''}
        </div>
		
	);
};

export default ProdutoList;

const ProdutoItem = (props: {
    produto: IProdutoCardPimaco,
    index: number
}) => {
    const domRef = useRef(null);
	
	const [isVisible, setVisible] = useState(false);
  
	useEffect(() => {

        if (!domRef.current) return

        if (props.index < 4) {
            setVisible(true)
            return;
        }

        const observer = new IntersectionObserver(entries => {
            // In your case there's only one element to observe:     
            if (entries[0].isIntersecting) {
            
            // Not possible to set it back to false like this:
            setVisible(true);
            
            // No need to keep observing:
            observer.unobserve(domRef.current as unknown as Element);
            }
        });
        
        observer.observe(domRef.current as unknown as Element);
        
        return () => {
            if (domRef && domRef.current) {
                observer.unobserve(domRef.current)
            } else {
                return undefined
            }
        };
	}, []);

    return (
        <div
            ref={ domRef }
            className={`produto-card-container ${isVisible ? 'visible' : ''}`}
            key={props.produto.sku}
        >
            <ProdutoCard
                produto={props.produto}	
            />
        </div>
    )
};
