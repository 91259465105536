
const Health = () => {

  return (
    <div>
      <p>
        <b>Backend URL:</b>&nbsp;<span>{process.env.REACT_APP_PROJECT_BACKEND_URL }</span>
      </p>
    </div>
  );
}

export default Health;