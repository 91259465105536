import { Button } from "@mui/material";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useTranslation } from "react-i18next";
import { ArrowButtonIcon } from "../../../../components/common/icons";
import {
  IFiltroItem,
  IFlatCategoria,
} from "../../../../interfaces/produto/categoria";
import IProdutoCardPimaco from "../../../../interfaces/produto/produtoCardPimaco";

export default function AccordionCategoriasProdutosEditor(props: {
  setProdutos: Function;
  categorias: IFlatCategoria[];
  handleChangeCategory: Function;
  activeFilter: string;
  produtosEditor: IProdutoCardPimaco[];
}) {
  const {
    setProdutos,
    categorias,
    handleChangeCategory,
    activeFilter,
    produtosEditor,
  } = props;

  const { t } = useTranslation("common");
  const { t: tEditor } = useTranslation("editor");

  return (
    <div className="editor-menu-categoria-selector-container">
      <Accordion allowZeroExpanded={true}>
        {categorias ? (
          categorias.map((subCategoria) => {
            return (
              <AccordionItem key={Math.random() * 1000}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span
                      onClick={() =>
                        handleChangeCategory(
                          subCategoria.alias,
                          subCategoria.nome
                        )
                      }
                      role="link"
                    >
                      {t(
                        subCategoria.nome != "undefined"
                          ? subCategoria.nome
                          : subCategoria.items[0].nome
                      )}
                    </span>
                    <ArrowButtonIcon onClick={() => undefined} />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    {subCategoria.items.map((item: IFiltroItem) => {
                      return (
                        <li
                          onClick={() => handleChangeCategory(item.alias)}
                          role="link"
                          className={activeFilter == item.alias ? "active" : ""}
                          key={item.alias}
                        >
                          {item.nome}
                        </li>
                      );
                    })}
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            );
          })
        ) : (
          <div className="editor-menu-categoria-selector-error">
            {tEditor(
              "Não foi possível carregar as categorias, tente novamente mais tarde"
            )}
          </div>
        )}
      </Accordion>

      <Button
        variant="outlined"
        className="btn btn-outlined-primary"
        onClick={() => setProdutos(produtosEditor)}
      >
        {tEditor("Limpar")}
      </Button>
    </div>
  );
}
