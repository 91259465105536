import { Button, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { InstagramLogo } from "../common/social-logos"

import { IInstagramResponse } from "../../interfaces/instagramData"
import instagramService from '../../services/instagramService';
import { useEffect, useState } from "react";

export let handle = { i18n: ["common", "homepage"] };

export default function DicasUsoProdutoPinterestPimaco() {

    const [instagramData, setInstagramData] = useState<IInstagramResponse>();

    useEffect(() => {
        instagramService.listProduto().then((resp) => {
            setInstagramData(resp.data);
          }).catch((err) => {
            console.error(err)
          })
    }, [])

	const { t } = useTranslation("homepage")

    return (
            
        <section className="dicas-uso-produtos-container">
            <h1>{t("Veja algumas dicas de como usar nossos produtos")}</h1>
            <p className="page-paragraph">{t("Diferentes usos para colocar tudo no seu lugar, tudo do seu jeito!")}</p>

            <div className="dicas-uso-produtos-feed-container">
                {instagramData && instagramData.data ?
                    <Grid container spacing={2}>
                        {instagramData.data.map((item, index) => {

                            const text = item.caption.length >= 150 ? `${item.caption.substring(0, 150)}...` : item.caption;
                            return (
                                <Grid item xs={12} sm={6} md={4} key={item.id}>
                                    <div className="dicas-uso-produtos-item">
                                        <a href={item.permalink} className="btn-link" target="_blank">
                                            <div className="dicas-uso-produtos-item-text-container">
                                                <div className="dicas-uso-produtos-item-logo-container">
                                                    <InstagramLogo />
                                                </div>
                                                <p>{text}</p>
                                            </div>
                                        </a>

                                        <img src={item.media_type == 'VIDEO' ? item.thumbnail_url : item.media_url} loading="lazy"/>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                : 
                        <div className="page-error-container">
                            <h3>{t('Houve um problema ao obter os posts do Instagram')}</h3>
                            <p>{t('Visite nosso Instagram')}</p>
                            <a
                                href="https://www.instagram.com/pimaco.oficial/"
                                target="_blank"
                                rel="noopener"
                                className="btn-link"
                            >
                                <Button
                                    className="btn btn-contained-thertiary"
                                    variant="contained"
                                    startIcon={<InstagramLogo />}
                                    disableElevation
                                >
                                    Instagram Pimaco
                                </Button>
                            </a>
                        </div>
                }
            </div>
        </section>
    )
}