import { Button } from "@mui/material";
import { useBoardContext } from "@beontag/tageditor";
import { ZoomOut as ZoomOutIcon } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export interface ZoomOutProps {
  decreaseStep: number;
}

const ZoomOut: React.FunctionComponent<ZoomOutProps> = (props) => {
  const { boardZoom } = useBoardContext();

  const zoomOut = () =>
    boardZoom?.set(round(boardZoom?.value - props.decreaseStep));

  const lowestValue = () => boardZoom?.value === boardZoom?.min;

  const round = (value: number) => {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  };

  const { t } = useTranslation("editor");
  return (
    <Button
      className="btn"
      startIcon={<ZoomOutIcon size={16} />}
      onClick={zoomOut}
      disabled={lowestValue()}
    >
      {t("Zoom Out")}
    </Button>
  );
};

export default ZoomOut;
