import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import "../../../styles/editor/_login.scss";
import iconePimaco from "../../../assets/logo-ETQVRSEX.webp";

import jwt_decode from "jwt-decode";

import sUsuario from "../../../services/usuarioService";
import { handleFormErrors } from "../../../helpers/formErrorHandler";

import { useMessage } from "../../../context/alertContext/context";
import { IUserData } from "../../../interfaces/editor/userData";
import { useUserContext } from "../../../context/userContext/userContext";
import ShowPasswordInputAdornment from "./showPasswordInputAdornment";
import { Trans, useTranslation } from "react-i18next";
import DownloadBox from "../../downloads/download-box";

const { dependencies } = require('../../../../package.json')

function Login(props: { modifyActiveComponent: Function }) {
  const [inputPasswordVisible, setInputPasswordVisible] =
    useState<boolean>(false);

  const { showAlertApp } = useMessage();

  const { login } = useUserContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t, i18n } = useTranslation("editor");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const appVersion = dependencies['@beontag/tageditor']

  const handleInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    if (handleFormErrors(formData, errors, setErrors)) {
      return;
    }

    setIsLoading(true);
    showAlertApp(t("Logando..."), "info");

    await sUsuario
      .login(formData)
      .then((resp) => {
        setIsLoading(false);

        const jwtPayload = jwt_decode<IUserData>(
          resp.headers["x-access-token"]
        );

        if (jwtPayload.atualizar_senha == "True") {
          showAlertApp(
            t("Por questões de segurança por favor atualize sua senha"),
            "warning",
            5000
          );
          props.modifyActiveComponent("criar-nova-senha");
        } else {
          showAlertApp(t("Logado com sucesso!"), "success", 5000);
          login(jwtPayload);
        }
      })

      .catch((err) => {
        setIsLoading(false);

        if (!err.response) {
          showAlertApp(
            t("Não foi possível se conectar tente novamente mais tarde"),
            "error",
            5000
          );
          return;
        }

        if (err.response.status == 401 || err.response.status == 403) {
          showAlertApp(t("Dados inválidos tente novamente"), "error", 5000);
          setFormData({ ...formData, password: "" });
        }
      });
  };

  return (
    <div className="editor-login" key="editor-login">
      <div className="editor-login-container">
        <div className="editor-login-authentication-image">
          <img
            src={iconePimaco}
            alt=""
            className="editor-login-authentication-image-img"
          />

        </div>

        <p className="page-paragraph">
          <Trans t={t} i18nKey="accessSoftware">
            <p>
              Acesse o <strong>software de impressão</strong> PIMACO com seu
              e-mail e senha. Caso ainda não tenha realizado o cadastro,
              <span
                role="button"
                className="autenticacao-texto"
                onClick={() => props.modifyActiveComponent("cadastro-software")}
              >
                clique aqui
              </span>
              para se cadastrar e ter acesso.
            </p>
          </Trans>
        </p>

        <Grid container spacing={2} className="editor-login-authentication">
          <Grid item xs={12}>
            <h1 className="editor-login-authentication-text">
              {t("Autenticação")}
            </h1>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="email"
              value={formData.email}
              type="email"
              name="email"
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key == "Enter") handleLogin();
              }}
              aria-label="Email:"
              variant="filled"
              error={errors.email}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    disablePointerEvents
                    className="editor-login-authentication-fields-adornment"
                  >
                    <span className="form-label">{t("E-mail:")}</span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="password"
              type={inputPasswordVisible ? "text" : "password"}
              value={formData.password}
              name="password"
              aria-label="Senha:"
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key == "Enter") handleLogin();
              }}
              onPaste={(e) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  password: e.clipboardData.getData("Text").trim(),
                });
              }}
              variant="filled"
              error={errors.password}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    disablePointerEvents
                    className="editor-login-authentication-fields-adornment"
                  >
                    <span className="form-label">{t("Senha:")}</span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <ShowPasswordInputAdornment
                    inputVisible={inputPasswordVisible}
                    setInputVisible={setInputPasswordVisible}
                  />
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              className="form-contato-submit-button btn btn-contained-primary"
              disableElevation
              onClick={handleLogin}
            >
              {t("Entrar")}
            </Button>

            <br />
            <br />

            <p
              role="button"
              className="editor-login-paragraphs"
              onKeyDown={() => props.modifyActiveComponent("cadastro-software")}
              onClick={() => props.modifyActiveComponent("cadastro-software")}
            >
              {t("Cadastrar")}
            </p>

            <p
              role="button"
              className="editor-login-paragraphs"
              onKeyDown={() => props.modifyActiveComponent("esqueci-senha")}
              onClick={() => props.modifyActiveComponent("esqueci-senha")}
            >
              {t("Esqueci minha senha")}
            </p>
            <a
              href={`/documents/parametros/Eula/Termos e Condições Gerais de Uso de Software Pimaco_${i18n.language}.pdf`}
              className="editor-login-paragraphs"
              download
            >
              {t("Download do contrato de licença")}
            </a>


            <br/>
            <br/>
            <p style={{ fontSize: 10 }}>
              Editor version: {appVersion.substring(1)}
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Login;
