import { Button, Grid } from '@mui/material'
import { useRef, useState } from 'react'
import StepInfoBox from './stepInfoBox'

import { IStep } from '../../interfaces/assistenteStep'

export default function AssistentePimaco(props: {steps: IStep }) {

    const [currentStep, setCurrentStep] = useState<IStep>(props.steps);
    const [selectedSteps, setSelectedSteps] = useState<IStep[]>([]);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSelect = (step:IStep) => {

        step.parent = currentStep;

        if (!selectedSteps.find(x => x == step)) {
            setSelectedSteps([...selectedSteps, step])
        }

        if (step.nextStep.length != 0) {
            setCurrentStep(step)
        }

        if (step.finalStep) {
            setTimeout(() => {             
                formRef.current?.submit()  
            }, 200)
            
        }       
    }

    const handleClickStepBox = (step: IStep) => {

        const index = selectedSteps.findIndex(x => x == step);

        if (index != -1) {
            setSelectedSteps(selectedSteps.slice(0, index))
            setCurrentStep(step.parent || step)
        }
    }

    return (
        <div className='assistant-container'>
            <h2 className='step-number'>{selectedSteps.length +1}º PASSO</h2>
            <h2 className="step-text">{currentStep.nextStep[0].stepDescriptionText}</h2>
            <>
                <div className="assistant-main-button-container">
                    <form
                        method='get'
                        action='/pesquisa'
                        ref={formRef}
                    >
                        <Grid
                            container
                            className='assistant-grid-container'
                            spacing={2}
                        >
                            {currentStep.nextStep.map((step) => {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        md={ currentStep.nextStep.length > 5 ?
                                            Math.round(24/currentStep.nextStep.length) :
                                            Math.round(12/currentStep.nextStep.length)
                                        }
                                        key={step.value || Math.random() * 1000}
                                    >
                                        <Button
                                            type={step.finalStep && step.nextStep.length == 0
                                                ? 'button': undefined}
                                        >
                                            <div className='assistant-grid-item' onClick={() => handleSelect(step)}>
                                                <p>{step.text}</p>
                                            </div>
                                        </Button>
                                    </Grid>
                                )
                            })}
                        </Grid>

                        {selectedSteps.map((step) => {
                            if (step.value) {
                                return (
                                    <input type='hidden' name={step.name} value={step.value} key={step.stepId}/>
                                )
                            }
                        })}

                    </form>

                    {selectedSteps.map((step, index) => {

                        return (
                            <StepInfoBox
                                step={step}
                                stepNumber={index+1}
                                text={step.selectedText}
                                currentStep={false}
                                key={step.value}
                                onClick={handleClickStepBox}
                            />
                        )
                    })}

                    <StepInfoBox
                        stepNumber={selectedSteps.length+1}
                        text={""}
                        currentStep={true}
                    />

                </div>

            </>

        </div>
    )
}