import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingPlaceholder from "../../../components/common/loadingPlaceholder";

import sEtiquetasUsuario from "../../../services/etiquetasUsuarioService";
import { useEffect, useState } from "react";
import { IEtiquetaUsuario } from "../../../interfaces/editor/etiquetaUsuario";
import { useMessage } from "../../../context/alertContext/context";

import { Pencil, Trash3 } from "react-bootstrap-icons";
import { useEditorContext } from "../../../context/editorContext/EditorContext";
import { useTranslation } from "react-i18next";

const ModalMinhasEtiquetas = (props: {
  open: boolean;
  handleCloseModal: Function;
  setActiveStep: Function;
  setProdutoSelecionado: Function;
}) => {
  const { showAlertApp } = useMessage();
  const { t } = useTranslation("editor");
  const { selecionarProduto, setEtiquetaUsuario } = useEditorContext();

  const [etiquetasUsuario, setEtiquetasUsuario] = useState<IEtiquetaUsuario[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.open) {
      setIsLoading(true);

      sEtiquetasUsuario
        .listEtiquetasUsuario()
        .then((resp) => {
          setEtiquetasUsuario(resp.data);
          setIsLoading(false);
        })
        .catch((err) => {
          showAlertApp(
            t("Erro ao obter suas etiquetas, tente novamente mais tarde"),
            "error",
            5000
          );
        });
    }
  }, [props.open]);

  const handleDelete = (idEtiquetaUsuario: number | undefined) => {
    if (idEtiquetaUsuario) {
      sEtiquetasUsuario
        .deleteEtiqueta(idEtiquetaUsuario)
        .then((resp) => {
          showAlertApp(t("Etiqueta apagada com sucesso"), "info", 5000);
          setEtiquetasUsuario(
            etiquetasUsuario.filter(
              (x) => x.idUsuarioEtiqueta !== idEtiquetaUsuario
            )
          );
        })
        .catch((err) => {
          showAlertApp(
            t(
              "Ocorreu um problema ao apagar esta etiqueta, tente novamente mais tarde"
            ),
            "error",
            5000
          );
        });
    }
  };

  const handleSelectEtiqueta = (etiqueta: IEtiquetaUsuario) => {
    // @ts-ignore
    selecionarProduto(etiqueta.produto);
    setEtiquetaUsuario(etiqueta);
    // @ts-ignore
    props.setProdutoSelecionado(etiqueta.produto.sku);
    props.setActiveStep(3);
    props.handleCloseModal();
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.handleCloseModal()}
        scroll="paper"
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle>
          <IconButton onClick={() => props.handleCloseModal()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <h1 className="editor-login-authentication-text">
            {t("Minhas etiquetas")}
          </h1>
          <TableContainer>
            <Table className="categoria-produtos" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center" width={"5%"}>
                    #
                  </TableCell>
                  <TableCell align="center">{t("Nome da etiqueta")}</TableCell>
                  <TableCell align="center" width={"25%"}>
                    {t("Data de modificação")}
                  </TableCell>
                  <TableCell align="center" width={"10%"}>
                    {t("Ações")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {isLoading &&
                  [0, 1, 2, 3, 4].map((_, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell sx={{ fontWeight: "bold" }} align="center">
                          <LoadingPlaceholder>
                            <div className="loading-text short" />
                          </LoadingPlaceholder>
                        </TableCell>
                        <TableCell align="center">
                          <LoadingPlaceholder>
                            <div className="loading-text extra-long" />
                          </LoadingPlaceholder>
                        </TableCell>
                        <TableCell align="center">
                          <LoadingPlaceholder>
                            <div className="loading-text" />
                          </LoadingPlaceholder>
                        </TableCell>
                        <TableCell align="center">
                          <LoadingPlaceholder>
                            <div className="loading-button actions" />
                          </LoadingPlaceholder>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {etiquetasUsuario &&
                  !isLoading &&
                  etiquetasUsuario.length > 0 &&
                  etiquetasUsuario.map((etiqueta, index) => {
                    const data = new Date(etiqueta.dataCadastro ?? "");
                    const dataString = data.toLocaleString();
                    return (
                      <TableRow key={index}>
                        <TableCell sx={{ fontWeight: "bold" }} align="center">
                          {" "}
                          {etiqueta.idUsuarioEtiqueta}{" "}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {etiqueta.descricao}{" "}
                        </TableCell>
                        <TableCell align="center"> {dataString} </TableCell>
                        <TableCell align="center">
                          <Stack
                            direction="row"
                            className="property-bar-text-options-container"
                          >
                            <IconButton
                              aria-label="Editar"
                              onClick={() => handleSelectEtiqueta(etiqueta)}
                              className={`property-bar-text-options-button`}
                            >
                              <Pencil />
                            </IconButton>
                            <IconButton
                              aria-label="Excluir"
                              onClick={() =>
                                handleDelete(etiqueta.idUsuarioEtiqueta)
                              }
                              className={`property-bar-text-options-button`}
                            >
                              <Trash3 />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {etiquetasUsuario && !isLoading && etiquetasUsuario.length == 0 ? (
            <div className="minhas-etiquetas-message">
              <p>{t("Você não possui nenhuma etiqueta salva na nuvem")}</p>
            </div>
          ) : (
            ""
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalMinhasEtiquetas;
