import { IStep } from "../../../interfaces/assistenteStep";

function builder(step:IStep, nextSteps?: IStep[], isFinalStep?: boolean) {

    const stepClone = { ...step };

    if (nextSteps && nextSteps.length != 0) {
        stepClone.nextStep = nextSteps;
    }
    if (isFinalStep) {
        stepClone.finalStep = isFinalStep;
    }

    return stepClone;
}

export const Impressao:IStep = {
    stepId: 1,
    text: 'Para imprimir',
    stepDescriptionText: 'Como será usada a etiqueta: ',
    selectedText: 'Etiquetas para imprimir',
    parent: undefined,
    nextStep: [],
    finalStep: false,
    name: 'Objetivo',
    value: 'ParaImprimir',
    builder: builder,
}

export const Escrever:IStep = {
    stepId: 2,
    text: 'Para escrever',
    stepDescriptionText: 'Como será usada a etiqueta: ',
    selectedText: 'Etiquetas para escrever',
    parent: undefined,
    nextStep: [],
    finalStep: false,
    name: 'Objetivo',
    value: 'ParaEscrever',
    builder: builder,
}

export const ProntoUso:IStep = {
    stepId: 3,
    text: 'Pronto para uso',
    stepDescriptionText: 'Como será usada a etiqueta: ',
    selectedText: 'Etiquetas prontas para uso',
    parent: undefined,
    nextStep: [],
    finalStep: false,
    name: 'Objetivo',
    value: 'ProntaUso',
    builder: builder,
}

export const NA:IStep = {
    stepId: 99,
    text: 'N/A',
    stepDescriptionText: 'Como será usada a etiqueta: ',
    selectedText: 'Opção não se aplica',
    parent: undefined,
    nextStep: [],
    finalStep: true,
    name: 'Objetivo',
    value: 'NaoAplicado',
}

export const NAImpressao:IStep = {
    stepId: 100,
    text: 'Impressora',
    stepDescriptionText: 'Como será usada a etiqueta: ',
    selectedText: 'Opção não se aplica',
    parent: undefined,
    nextStep: [],
    finalStep: true,
    name: 'Objetivo',
    value: 'NaoAplicado',
}